import {
  ANDROID,
  IOS,
  UNKNOWN,
  MakeMyTrip,
  HOST,
  GoIbibo,
  SOURCE,
  COOKIES,
  MMT_SUB_DOMAIN,
  GI_SUB_DOMAIN,
  CUSTOM_EVENTS,
  ACCOUNT_TYPE,
} from "../constants";
import { getUserProfileType } from "../methods/bridgeMethods";
//@ts-ignore
import { setCookie, getCookie, removeCookie } from "./cookieUtils";
//@ts-ignore
import { getUserInfoPIIClient } from "MMT-UI/getPIIData/client";

declare global {
  interface Window {
    app_bridge: any;
    webkit: any;
    opera: any;
    MSStream: any;
  }
}

export enum DEVICE_MODEL_TYPE {
  APP = "App",
  WEB = "Web",
}

export const isOnServer = () => {
  if (typeof window === "undefined") {
    return true;
  }
  return false;
};

export const isApp = () => {
  if (isOnServer()) {
    return false;
  }
  return window?.app_bridge || window?.webkit?.messageHandlers?.login
    ? true
    : false;
};

export const getPartner = () => {
  if (isOnServer()) {
    return null;
  }
  if (getHostName() === HOST.MAKEMYTRIP) {
    return MakeMyTrip;
  } else if (getHostName() === HOST.GOIBIBO) {
    return GoIbibo;
  }
  let url = window.location.pathname;
  url = url.replace(/\/+$/, "");
  const urlParts = url.split("/");
  const partnersIndex = urlParts.indexOf("partners");
  if (partnersIndex !== -1 && partnersIndex + 1 < urlParts.length) {
    return urlParts[partnersIndex + 1];
  } else {
    return null;
  }
};

export const deleteSpecificCookie = (
  specificParamKey: any,
  options = { path: "/" }
) => {
  removeCookie(specificParamKey, options);
};

export const getUserDeviceOS: any = () => {
  if (isOnServer()) {
    return null;
  }
  const userAgent = navigator?.userAgent || navigator?.vendor || window?.opera;

  if (/android/i?.test(userAgent)) {
    return ANDROID;
  }

  if (/iPad|iPhone|iPod/?.test(userAgent) && !window?.MSStream) {
    return IOS;
  }

  return UNKNOWN;
};

export const getMmtAndroidVersion: any = () => {
  if (isOnServer()) {
    return null;
  }
  const version = window?.app_bridge?.getAppVersion();
  let mmtAndroidAppVersion = {};
  if (version) {
    try {
      mmtAndroidAppVersion = JSON.parse(decodeURIComponent(version));
    } catch (e) {
      console.error(`Error parsing app version. Got version: ${version}`, e);
    }
  }
  return mmtAndroidAppVersion;
};

export const getGIAndroidVersion = () => {
  if (isOnServer()) {
    return null;
  }
  const version = window?.app_bridge?.getAppVersion?.();
  if (version?.length) {
    return version;
  } else {
    return "";
  }
};

export const getGIIOSVersion = () => {
  if (isOnServer()) {
    return null;
  }
  const version = window?.app_bridge?.getAppVersion?.();
  return version?.length ? version : "";
};

export const getInstalledVersion = (partner: any) => {
  return partner === MakeMyTrip
    ? getUserDeviceOS() === ANDROID
      ? getMmtAndroidVersion()?.v_name
      : ""
    : // FOR GI
    getUserDeviceOS() === ANDROID
    ? getGIAndroidVersion()
    : getGIIOSVersion();
};

//TODO: Mention the required versions.
const reqVersions: any = {
  iOS: {
    gi: "1",
    mmt: "2",
  },
  android: {
    gi: "3",
    mmt: "4",
  },
};

function compareVersionsInclusive(
  reqVersion: string,
  currVersion: string
): number {
  const parts1 = reqVersion.split(".").map(Number);
  const parts2 = currVersion.split(".").map(Number);

  const maxLength = Math.max(parts1.length, parts2.length);

  for (let i = 0; i < maxLength; i++) {
    const num1 = i < parts1.length ? parts1[i] : 0;
    const num2 = i < parts2.length ? parts2[i] : 0;

    if (num1 > num2) {
      return 1;
    } else if (num1 < num2) {
      return 0;
    }
  }

  return 1;
}

export const versionCheck = (os: any, partner: any) => {
  const currVersion = getInstalledVersion(partner);
  const reqVersion = reqVersions[os][partner];
  const isVersionPassed = compareVersionsInclusive(reqVersion, currVersion);
  if (isVersionPassed === 1) {
    return true;
  }
  return false;
};

export function getHostName() {
  if (isOnServer()) {
    return null;
  }
  return window.location.hostname;
}

export const isPartnerHost = (partner: string) => {
  const hostname = getHostName() || "";
  return hostname.includes(partner);
};

export const getCookieExpiryTime = (days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  return date.toUTCString();
};

export const persistSpecificParamInCookie = (
  specificParamKey: string,
  specificParamValue: string,
  options: any = { path: "/" }
) => {
  setCookie(specificParamKey, specificParamValue, true, options);
};

export const isAndroidAuthBridgePresent = (): boolean => {
  if (!window || !window.app_bridge) {
    return false;
  }

  return Boolean(
    window.app_bridge.logoutUser &&
      window.app_bridge.login &&
      window.app_bridge.getShortLivToken
  );
};

export const isMobile = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a ? a.substr(0, 4) : null
      )
    )
      check = true;
  })(
    // @ts-ignore
    !isOnServer()
      ? //@ts-ignore
        navigator.userAgent || navigator.vendor || window?.opera
      : null
  );
  return check;
};

export const getMmtLoginCustomEvent = (
  data: any = {
    activeModal: "",
  }
) => {
  const defaultData = {
    detail: data,
  };
  return new CustomEvent(CUSTOM_EVENTS.MMT_LOGIN_CUSTOM_EVENT, defaultData);
};

export const getMmtAgentLoginCustomEvent = (
  data: any = {
    activeModal: "",
  }
) => {
  const defaultData = {
    detail: data,
  };
  return new CustomEvent(
    CUSTOM_EVENTS.MMT_AGENT_LOGIN_CUSTOM_EVENT,
    defaultData
  );
};

export const getEmailLoginErrorCustomEvent = (
  data: any = {
    showModal: false,
  }
) => {
  const defaultData = {
    detail: data,
  };
  return new CustomEvent(CUSTOM_EVENTS.EMAIL_LOGIN_ERROR_EVENT, defaultData);
};

export const getAppUpdateCustomEvent = (
  data: any = {
    showModal: false,
  }
) => {
  const defaultData = {
    detail: data,
  };
  return new CustomEvent(CUSTOM_EVENTS.APP_UPDATE_MODAL_EVENT, defaultData);
};

export const getLoginErrorCustomEvent = (
  data: any = {
    showModal: false,
  }
) => {
  const defaultData = {
    detail: data,
  };
  return new CustomEvent(CUSTOM_EVENTS.LOGIN_ERROR_EVENT, defaultData);
};

export const removeTmMmmtAuthCookie = (shouldRemovePartnerAuth = true) => {
  if (isPartnerHost(SOURCE.FOREX_EXPERT)) {
    removeCookie(COOKIES.FOREX_AGENT_AUTH_KEY, {
      path: "/",
      domain: MMT_SUB_DOMAIN,
    });
    removeCookie(COOKIES.FOREX_AGENT_AUTH_KEY, {
      path: "/",
      domain: GI_SUB_DOMAIN,
    });
    return;
  }
  if (isPartnerHost(SOURCE.MAKEMYTRIP)) {
    removeCookie(COOKIES.TM_AUTH_KEY, {
      path: "/",
      domain: MMT_SUB_DOMAIN,
    });
    removeCookie(COOKIES.TM_MMT_AUTH_KEY, {
      path: "/",
      domain: MMT_SUB_DOMAIN,
    });
    if (shouldRemovePartnerAuth) {
      removeCookie(COOKIES.MMT_AUTH_KEY, {
        path: "/",
        domain: MMT_SUB_DOMAIN,
      });
      removeCookie(COOKIES.MMT_AUTH_KEY, {
        path: "/",
        domain: `tripmoney${MMT_SUB_DOMAIN}`,
      });
    }
  } else if (isPartnerHost(SOURCE.GOIBIBO)) {
    persistSpecificParamInCookie(COOKIES.TM_AUTH_KEY, "", {
      path: "/",
      domain: GI_SUB_DOMAIN,
    });
    removeCookie(COOKIES.TM_GI_AUTH_KEY, {
      path: "/",
      domain: GI_SUB_DOMAIN,
    });
    if (shouldRemovePartnerAuth) {
      removeCookie(COOKIES.GI_AUTH_KEY, {
        path: "/",
        domain: GI_SUB_DOMAIN,
      });
      removeCookie(COOKIES.GI_AUTH_KEY, {
        path: "/",
        domain: `tripmoney${GI_SUB_DOMAIN}`,
      });
    }
  }
};

export enum DEVICE_TYPE {
  DESKTOP = "desktop",
  MOBILE = "mobile",
  TABLET = "tablet",
}

export function getDeviceType(): DEVICE_TYPE {
  const userAgent =
    typeof window !== "undefined" ? window?.navigator?.userAgent || "" : "";
  const mobileRegex = new RegExp(/Mobile|Android|BlackBerry/);
  const tabletRegex = new RegExp(
    /iPad|(Android(?!.*Mobile))|Tablet|PlayBook|Kindle|GT-P1000|SCH-I800|sholes|Xoom|Libero|\b4G\b/
  );
  if (userAgent.match(mobileRegex)) {
    return DEVICE_TYPE.MOBILE;
  } else if (userAgent.match(tabletRegex)) {
    return DEVICE_TYPE.TABLET;
  }
  return DEVICE_TYPE.DESKTOP;
}

export const fetchutmParamsFromCookie = () => {
  const utm_medium = getCookie("utm_medium", false, { path: "/" });
  const utm_source = getCookie("utm_source", false, { path: "/" });
  const utm_campaign = getCookie("utm_campaign", false, { path: "/" });
  const utm_lender = getCookie("utm_lender", false, { path: "/" });
  const utm_login = getCookie("utm_login", false, { path: "/" });
  const utm_content = getCookie("utm_content", false, { path: "/" });
  const utm_flow = getCookie("utm_flow", false, { path: "/" });
  return {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_lender,
    utm_login,
    utm_content,
    utm_flow,
  };
};

export interface TmAuthDataType {
  showEmailPopup: boolean;
  loginToken: {
    success: boolean;
    error: {
      code: string | null;
      description: string;
    };
  };
  appSocialToken: {
    success: boolean;
    error: {
      code: string | null;
      description: string;
    };
  };
}

export const getTmAuthDefaultObj = () => {
  return {
    showEmailPopup: false,
    loginToken: {
      success: false,
      error: {
        code: null,
        description: "",
      },
    },
    appSocialToken: {
      success: false,
      error: {
        code: null,
        description: "",
      },
    },
  };
};

export const getAppSocialLoginQuery = [
  [
    {
      name: "extendedUser",
      keys: [
        "uuid",
        "status",
        "userType",
        "profileType",
        "affiliateId",
        "corporateData",
      ],
    },
  ],
  [
    {
      name: "extendedUser",
    },
    {
      name: "personalDetails",
    },
    {
      name: "name",
      keys: ["title", "firstName", "middleName", "lastName"],
    },
  ],
  [
    {
      name: "loginInfoList",
      keys: [
        "loginId",
        "loginType",
        "primary",
        "status",
        "countryCode",
        "verified",
        "verifiedAt",
      ],
    },
  ],
  [
    {
      name: "extendedUser",
    },
    {
      name: "miscFields",
      keys: ["partnerUUID"],
    },
  ],
];

export const getDeviceModelType = (): DEVICE_MODEL_TYPE | null => {
  if (isOnServer()) return null;
  // @ts-ignore
  if (window.mmt_android_bridge || window.app_bridge) {
    return DEVICE_MODEL_TYPE.APP;
  }
  return DEVICE_MODEL_TYPE.WEB;
};

export function disableEncrytionInWeb() {
  return (
    getDeviceModelType() === DEVICE_MODEL_TYPE.WEB &&
    [HOST.MAKEMYTRIP, HOST.TRIPMONEY_MAKEMYTRIP].includes(getHostName() || "")
  );
}

export function getUtilOptions(options: any) {
  return disableEncrytionInWeb() ? { ...options, isPiiData: false } : options;
}

export const getAccountType = async () => {
  let profileTypeRes: any = null;
  try {
    if (isApp() && getUserDeviceOS() === IOS) {
      profileTypeRes = await getUserProfileType();
    } else {
      profileTypeRes = await getUserInfoPIIClient();
    }
  } catch (error) {
    console.log("Error", error);
  }

  if (profileTypeRes?.profileType === ACCOUNT_TYPE.BUSINESS) {
    return ACCOUNT_TYPE.MYBIZ;
  }
  return getPartner() === MakeMyTrip ? "MMT" : "GI";
};
