import React from "react";
import { FlexComp, ImageTag, ParaTag, SpanTag } from "@tm/static";
import { CashBackAvailStyle } from "./CashBackAvailableComp.style";
import { theme } from "@tm/utility/global";
import { CASHBACK_STATUS, CouponStatusType } from "@tm/libs/types";

interface CashBackProp {
  descTxt?: string;
  cashBackIcon?: string;
  title?: string;
  isBlackTitle?: boolean;
  status?: CouponStatusType;
  couponCashback?: number | null | undefined;
}

const CashBackAvailableComp = ({
  descTxt,
  cashBackIcon,
  title,
  isBlackTitle,
  status,
  couponCashback,
}: CashBackProp) => {
  if (!couponCashback) return null;
  return (
    <CashBackAvailStyle
      style={
        status === CASHBACK_STATUS.CREDITED ||
        status === CASHBACK_STATUS.CANCELLED
          ? { background: "transparent", padding: "0px" }
          : undefined
      }
    >
      <FlexComp gap="2px">
        {cashBackIcon && (
          <ImageTag width="16px" height="16px" src={cashBackIcon}></ImageTag>
        )}
        <SpanTag
          fontSize="xs"
          fontWeight="black"
          color={isBlackTitle ? theme.blackText : theme.greenText}
        >
          {title}
        </SpanTag>
      </FlexComp>
      {descTxt && (
        <ParaTag
          fontSize="xs"
          color={theme.greyText6}
          dangerousText={descTxt}
        ></ParaTag>
      )}
    </CashBackAvailStyle>
  );
};

export default CashBackAvailableComp;
