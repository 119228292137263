import { forwardRef } from "react";
import { ImageTagStyle } from "./ImageTag.style";

interface ImageTagProps {
  src: string;
  width?: string;
  margin?: string;
  height?: string;
  showCursorPointer?: boolean;
  alt?: string;
  borderRadius?: string;
}

const ImageTag = forwardRef<HTMLImageElement, ImageTagProps>(
  (
    {
      src,
      width,
      alt = "image-tag",
      margin,
      height,
      showCursorPointer = false,
      borderRadius,
    },
    ref
  ) => {
    return (
      <ImageTagStyle
        ref={ref}
        src={src}
        width={width}
        margin={margin}
        height={height}
        showCursorPointer={showCursorPointer}
        alt={alt}
        borderRadius={borderRadius}
      />
    );
  }
);

export default ImageTag;
