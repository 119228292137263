import { Fragment, useState } from "react";
import { theme } from "../../css/Global";

import {
  LandingFaqStyle,
  LandingFaqList,
  LandingFaqTitle,
  LandingFaqQues,
  LandingInnerPointsCtr,
} from "./LandingFaq.style";
import { InnerContentWrap } from "../../CommonStyle/CommonStyles.style";
import {
  aboveAndBelowTextListItemProps,
  landingFaqDataProps,
} from "../../mockData";
import { useDevice } from "../../../hooks/useDevice";
import { useGlobalConfig } from "../../../hooks/useGlobalConfig";
import {
  PageNameMapping,
  SectionNameMapping,
  ElementNameMapping,
  PDT_EVENTS,
} from "../../../constants";
import { getElementUniqueIds, getEventDetails } from "../../../utils";
import ParaTag from "../ui-atoms/TextStyles/ParaTag";
import AnchorTag from "../ui-atoms/TextStyles/AnchorTag";
import { useListingPdtLogger } from "../../../hooks/usePdtLogger";
import { trackListingClickEvents } from "../../../analytics";
import useListingPdtLoggerV2 from "../../../hooks/useListingPdtLoggerV2";

interface FaqType {
  customTitle?: string;
  customFaq?: string;
  faq: landingFaqDataProps[];
  margin?: string | number;
  id?: string;
  padding?: string;
  faqAnswerLinkTextOnClick?: (item: aboveAndBelowTextListItemProps) => void;
}

const LandingFaq = ({
  customTitle,
  customFaq,
  faq,
  margin,
  id,
  padding,
  faqAnswerLinkTextOnClick,
}: FaqType) => {
  const [exp, setExp] = useState(-1);
  const device = useDevice();
  const { sendPdtFaqClickEvent } = useListingPdtLogger();
  const { sendCTAData } = useListingPdtLoggerV2();
  const { partner } = useGlobalConfig();

  const handleClick = (index: any, item: landingFaqDataProps) => {
    exp != index ? setExp(index) : setExp(-1);
    sendPdtFaqClickEvent(item.ques);
    const eventDetail = getEventDetails({
      event_name: PDT_EVENTS.FAQ_CTA,
      event_value: item.ques,
    });
    sendCTAData({ eventDetail });

    trackListingClickEvents({
      actionType: `faq_${index + 1}_clicked`,
      partner,
    });
  };

  const textWithLinkRenderer = (textList: aboveAndBelowTextListItemProps[]) => {
    return textList.map((item) => {
      if (item.linkText) {
        return (
          <Fragment>
            {" "}
            <AnchorTag
              fontSize={device === "lg" ? "md" : "sm"}
              handleClick={
                faqAnswerLinkTextOnClick
                  ? (e: React.MouseEvent<HTMLAnchorElement>) => {
                      e.stopPropagation();
                      faqAnswerLinkTextOnClick(item);
                    }
                  : () => {}
              }
              hrefProp={item.href}
            >
              {item.linkText}
            </AnchorTag>{" "}
          </Fragment>
        );
      }

      return <span dangerouslySetInnerHTML={{ __html: item.text! }}></span>;
    });
  };

  return (
    <LandingFaqStyle
      id={id}
      margin={margin}
      customFaq={customFaq}
      padding={padding}
    >
      <InnerContentWrap>
        {customTitle && (
          <LandingFaqTitle
            id={getElementUniqueIds(
              PageNameMapping.LISTING_PAGE,
              SectionNameMapping.FAQs,
              ElementNameMapping.COMP_HEADING
            )}
            marginLeft={device === "sm" ? "16px" : ""}
            marginBottom="16px"
          >
            {customTitle}
          </LandingFaqTitle>
        )}
        {faq && (
          <LandingFaqList customFaq={customFaq}>
            {faq.map((item, index: any) => {
              return (
                <li
                  id={getElementUniqueIds(
                    PageNameMapping.LISTING_PAGE,
                    SectionNameMapping.FAQs.concat(index),
                    ElementNameMapping.DOWN_ARROW
                  )}
                  className={`${exp == index ? "open_forex" : "close_forex"}`}
                  key={index}
                  onClick={() => handleClick(index, item)}
                >
                  <LandingFaqQues customFaq={customFaq}>
                    {item.ques}
                  </LandingFaqQues>
                  {true && (
                    <div
                      className={`pointCtr ${
                        exp === index ? "open_forex" : ""
                      }`}
                    >
                      {item.answer &&
                        item.answer.map((item) => {
                          return (
                            <div key={item.id}>
                              {item.aboveTextList && (
                                <ParaTag
                                  color={theme.blackText3}
                                  margin="0px 0px 16px"
                                  fontSize={device === "lg" ? "md" : "sm"}
                                >
                                  {textWithLinkRenderer(item.aboveTextList)}
                                </ParaTag>
                              )}
                              {item.pointsList && (
                                <LandingInnerPointsCtr>
                                  {item.pointsList.map((ele) => {
                                    return (
                                      <div key={ele.id}>
                                        {ele.label && (
                                          <ParaTag
                                            dangerousText={ele.label}
                                            color={theme.blackText3}
                                            fontSize={
                                              device === "lg" ? "md" : "sm"
                                            }
                                          />
                                        )}
                                        {ele.info && (
                                          <ParaTag
                                            dangerousText={ele.info}
                                            color={theme.blackText3}
                                            fontSize={
                                              device === "lg" ? "md" : "sm"
                                            }
                                          />
                                        )}
                                      </div>
                                    );
                                  })}
                                </LandingInnerPointsCtr>
                              )}
                              {item.belowTextList && (
                                <ParaTag
                                  margin="0px 0px 24px"
                                  fontSize={device === "lg" ? "md" : "sm"}
                                  color={theme.blackText3}
                                >
                                  {textWithLinkRenderer(item.belowTextList)}
                                </ParaTag>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  )}
                </li>
              );
            })}
          </LandingFaqList>
        )}
      </InnerContentWrap>
    </LandingFaqStyle>
  );
};

export default LandingFaq;
