export enum COMPONENT_TYPE {
  HEADER = "header",
  HEADER_MOBILE = "header-mobile",
  HERO = "hero",
  HERO_MOBILE = "hero-mobile",
  TRIPMONEY_LOGO = "tripmoney-logo",
  FAQS_HEADER_LINK = "anchor-#faqs",
  TITLE = "title",
  LIVE_EVENT_MAP = "live-event-map",
  FOREIGN_CURRENCY_FOREX = "foreign-currency-forex",
  FOREIGN_CURRENCY_FOREX_MOBILE = "foreign-currency-forex-mobile",
  FOREX_CARD_SECTION = "forex-card-section",
  FOREX_CARD_SECTION_MOBILE = "forex-card-section-mobile",
  INSURANCE_SECTION = "insurance-section",
  INSURANCE_SECTION_MOBILE = "insurance-section-mobile",
  TRAVEL_LOAN_MOBILE_SECTION = "travel-loan-section-mobile",
  TRANSER_MONEY = "transfer-money",
  TRANSFER_MONEY_MOBILE = "transfer-money-mobile",
  PRIVACY_SECTION = "privacy-section",
  PRIVACY_SECTION_MOBILE = "privacy-section-mobile",
  TESTIMONIALS_SECTION = "testimonials-section",
  TESTIMONIALS_MOBILE = "testimonials-section-mobile",
  FAQS_SECTION = "faqs-section",
  FAQS_SECTION_MOBILE = "faqs-section-mobile",
  FOOTER = "footer",
  FOOTER_MOBILE = "footer-mobile",
  ERROR_PAGE = "error-page",

  ABOUT_US = "about-us",
  ABOUT_US_MOBILE = "about-us-mobile",
}

export enum DEVICE_TYPE {
  DESKTOP = "desktop",
  MOBILE = "mobile",
  TABLET = "tablet",
}

export enum DEVICE_MODEL_TYPE {
  APP = "App",
  WEB = "Web",
}

export const HOST = {
  TRIPMONEY: "www.tripmoney.com",
  MAKEMYTRIP: "www.makemytrip.com",
  GOIBIBO: "www.goibibo.com",
  LOCALHOST: "localhost",
  TRIPMONEY_MAKEMYTRIP: "tripmoney.makemytrip.com",
};
