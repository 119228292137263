import styled from "styled-components";

type ImageProps = {
  width?: number | string;
  margin?: number | string;
  showCursorPointer?: boolean;
  borderRadius?: string;
};

export const ImageTagStyle = styled.img<ImageProps>`
  display: inline-block;
  margin: ${(props: any) => props.margin};
  width: ${(props: any) => props.width};
  border-radius: ${(props: any) =>
    props?.borderRadius ? props.borderRadius : "unset"};
  flex-shrink: 0;
  ${(props) => props.showCursorPointer && ` cursor: pointer;`}
`;
