export { cityCSSPosition } from "./cityCSSPosition";
export { LINKS } from "./links";

// export const BASE_URL = process.env["NODE_ENV"] === "development" ? "http://127.0.0.1:3003" : "https://www.tripmoney.com";
export const BASE_URL =
  process.env["NX_ENV"] === "local" ? "http://localhost:4400/api" : "";
export const LISTING_API_PATH = `${BASE_URL}/card-management/api/v1/card/query/listing`;

export const BREAKPOINT = 766;

export const API_NAME = {
  MAP_DATA: "map_data",
  PINCODES: "pincodes",
  PAGES: "pages",
};

export const API = {
  GET_PINCODES: "/api/pincodeData",
  GET_MAP_DATA: "/api/mapdata",
};

export const foreignCities = [
  {
    city: "dubai",
    country: "United Arab Emirates",
  },
  {
    city: "sydney",
    country: "Australia",
  },
  {
    city: "sharjah",
    country: "United Arab Emirates",
  },
  {
    city: "abu dhabi",
    country: "United Arab Emirates",
  },
  {
    city: "nong prue sub",
    country: "Thailand",
  },
  {
    city: "ho chi minh city",
    country: "Viet Nam",
  },
  {
    city: "phuket",
    country: "Thailand",
  },
  {
    city: "badung",
    country: "Indonesia",
  },
  {
    city: "amsterdam",
    country: "Netherlands",
  },
  {
    city: "denpasar",
    country: "Indonesia",
  },
  {
    city: "da nang",
    country: "Viet Nam",
  },
  {
    city: "chonburi",
    country: "Thailand",
  },
  {
    city: "krabi",
    country: "Thailand",
  },
  {
    city: "gianyar",
    country: "Indonesia",
  },
  {
    city: "singapore",
    country: "Republic of Singapore",
  },
  {
    city: "sepang",
    country: "Malaysia",
  },
  {
    city: "ha noi",
    country: "Viet Nam",
  },
  {
    city: "nong prue",
    country: "Thailand",
  },
  {
    city: "ao nang",
    country: "Thailand",
  },
  {
    city: "borough of spelthorne",
    country: "England",
  },
  {
    city: "doha",
    country: "Qatar",
  },
  {
    city: "frankfurt",
    country: "Germany",
  },
  {
    city: "hoan kiem",
    country: "Viet Nam",
  },
  {
    city: "bonn",
    country: "Germany",
  },
  {
    city: "lombok utara",
    country: "dammam",
  },
  {
    city: "samut prakan province",
    country: "Thailand",
  },
  {
    city: "berlin",
    country: "Germany",
  },
  {
    city: "cairo",
    country: "Egypt",
  },
  {
    city: "muscat",
    country: "Oman",
  },
  {
    city: "kiên giang",
    country: "Viet Nam",
  },
  {
    city: "gampaha",
    country: "Sri Lanka",
  },
  {
    city: "muharraq",
    country: "Bahrain",
  },
  {
    city: "bali",
    country: "Indonesia",
  },
  {
    city: "jeddah",
    country: "United Arab Emirates",
  },
  {
    city: "farwaniya",
    country: "Kuwait",
  },
  {
    city: "malé",
    country: "Maldives",
  },
  {
    city: "hội an",
    country: "Viet Nam",
  },
  {
    city: "patong kathu",
    country: "Thailand",
  },
];
export const cities: any = {
  coimbatore: [726, 1059],
  surat: [635, 824],
  indore: [702, 784],
  meerut: [742, 631],
  jhajjar: [718, 635],
  jetpur: [583, 808],
  bhubaneswar: [929, 843],
  "gautam buddha nagar": [738, 643],
  kancheepuram: [790, 1020],
  guduvancheri: [790, 1012],
  imphal: [1107, 733],
  alwar: [718, 663],
  "madhubani district": [929, 694],
  himatnagar: [635, 761],
  chengalpattu: [794, 1020],
  dehradun: [750, 592],
  chakkarpur: [782, 682],
  agartala: [1048, 757],
  bathinda: [679, 596],
  darbhanga: [925, 698],
  jammu: [679, 529],
  malappuram: [702, 1055],
  kolhapur: [663, 925],
  "sri ganganagar": [655, 604],
  jagtial: [770, 878],
  mukandpurct: [702, 573],
  kota: [702, 722],
  jodhpur: [635, 698],
  "samut prakan province": [1262, 996],
  berlin: [-710, -82],
  cairo: [-290, 565],
  muscat: [306, 761],
  visakhapatnam: [861, 902],
  "kiên giang": [1361, 1086],
  gampaha: [930, 1350],
  muharraq: [131, 698],
  bali: [1591, 1490],
  jeddah: [-123, 812],
  darjeeling: [984, 678],
  farwaniya: [71, 620],
  malé: [655, 1216],
  turkey: [-206, 361],
  islands: [1556, 788],
  ahmednagar: [675, 871],
  "hội an": [1437, 827],
  thiruvananthapuram: [722, 1110],
  adilabad: [758, 855],
  bareilly: [782, 643],
  cuttack: [929, 835],
  medak: [754, 894],
  nagaur: [651, 675],
  sikar: [687, 663],
  sirsa: [683, 612],
  haridwar: [754, 600],
  kottayam: [718, 1090],
  medchal: [762, 906],
  bhavnagar: [619, 804],
  muzaffarnagar: [742, 616],
  nadia: [988, 765],
  navi: [635, 871],
  pali: [647, 710],
  thanjavur: [778, 1063],
  bharuch: [635, 808],
  bikaner: [643, 655],
  karimnagar: [774, 886],
  "new delhi": [733, 632],
  gurugram: [733, 632],
  noida: [733, 632],
  ghaziabad: [733, 632],
  lucknow: [816, 677],
  mumbai: [636, 865],
  bengaluru: [741, 1006],
  hyderabad: [761, 904],
  chennai: [800, 1003],
  kolkata: [981, 781],
  ahmedabad: [629, 771],
  pune: [656, 877],
  cochin: [713, 1077],
  goa: [660, 951],
  jaipur: [701, 675],
  guwahati: [1055, 694],
  chandigarh: [723, 580],
  patna: [912, 708],
  trivandrum: [726, 1106],
  varanasi: [859, 714],
  leh: [742, 490],
  allahabad: [837, 709],
  kochi: [714, 1078],
  dubai: [234, 717],
  sharjah: [234, 717],
  sydney: [2000, 1905],
  "abu dhabi": [234, 717],
  bhopal: [734, 768],
  vijaywada: [805, 929],
  ranchi: [912, 768],
  nagpur: [773, 819],
  haldwani: [781, 623],
  kozhikode: [698, 1050],
  "north 24 parganas": [992, 784],
  salem: [750, 1039],
  raipur: [829, 815],
  faridabad: [734, 643],
  udaipur: [650, 737],
  "dakshina kannada": [686, 1015],
  "kamrup metropolitan": [1063, 698],
  kelambakkam: [793, 1015],
  madurai: [746, 1078],
  quilon: [718, 1102],
  vadodara: [635, 792],
  thane: [635, 867],
  guntur: [802, 933],
  "nong prue sub": [1266, 1016],
  jabalpur: [790, 769],
  kannur: [690, 1035],
  "pimpri chinchwad": [655, 878],
  ramachandrapuram: [837, 922],
  bijnor: [754, 620],
  munger: [940, 718],
  dhampur: [762, 620],
  thiruvallur: [790, 1008],
  bangkok: [1254, 992],
  "ho chi minh city": [1397, 1059],
  phuket: [1210, 1125],
  badung: [1583, 1494],
  amsterdam: [-500, -75],
  denpasar: [1587, 1498],
  "da nang": [1429, 941],
  chonburi: [1270, 1000],
  krabi: [1222, 1118],
  gianyar: [1587, 1494],
  singapore: [1329, 1275],
  sepang: [1282, 1243],
  "ha noi": [1381, 820],
  "nong prue": [1262, 996],
  "patong kathu": [1206, 1125],
  "ao nang": [1218, 1122],
  "borough of spelthorne": [-600, -43],
  doha: [151, 722],
  frankfurt: [-617, 4],
  badgam: [675, 494],
  firozpur: [675, 573],
  tirupati: [786, 996],
  avadi: [794, 1004],
  chittoor: [798, 1008],
  amritsar: [679, 557],
  vijayawada: [810, 929],
  gorakhpur: [869, 682],
  jalandhar: [694, 565],
  dibrugarh: [1131, 663],
  gajuwaka: [865, 898],
  "port blair": [1079, 1043],
  rudrapur: [782, 627],
  warangal: [782, 894],
  barddhaman: [972, 769],
  panchkula: [722, 584],
  tiruchirappalli: [766, 1043],
  dimapur: [1107, 702],
  "hoan kiem": [1377, 824],
  jakarta: [1401, 1439],
  bonn: [-603, -16],
  rajkot: [587, 792],
  renon: [1587, 1498],
  "lombok utara": [1611, 1490],
  dammam: [119, 690],
  vellore: [770, 1008],
  kollam: [718, 1102],
  palakkad: [718, 1059],
  thrissur: [710, 1063],
  khorda: [917, 843],
  nashik: [651, 847],
  howrah: [976, 784],
  kanchipuram: [786, 1016],
  aurangabad: [690, 851],
  agra: [746, 671],
  hooghly: [976, 780],
  tiruvallur: [790, 1004],
  nellore: [794, 976],
  rangareddy: [758, 918],
  patiala: [714, 592],
  kanpur: [802, 690],
  ernakulam: [710, 1078],
  gautam: [730, 639],
  krishna: [-4829, -2565],
  ludhiana: [734, 639],
  kurnool: [750, 945],
  nalgonda: [778, 918],
  ambala: [722, 592],
  cuddapah: [770, 980],
  hisar: [698, 624],
  valsad: [635, 831],
  mohali: [718, 580],
  anand: [635, 784],
  jalgaon: [698, 824],
  kurukshetra: [722, 604],
  muzaffarpur: [921, 694],
  ajmer: [675, 694],
  mysore: [718, 1027],
  alappuzha: [710, 1090],
  dakshina: [687, 1016],
  karnal: [726, 608],
  panipat: [726, 616],
  prakasam: [794, 949],
  bardhaman: [972, 773],
  saharanpur: [738, 600],
  central: [-4829, -2565],
};

export const PageNameMapping = {
  LISTING_PAGE: "listingPage",
};

export const SectionNameMapping = {
  HEADER: "header",
  FOOTER: "footer",
};

export const ElementNameMapping = {
  FAQ: "faq",
  ABOUT_US: "aboutUs",
  TM_LOGO: "tmLogo",
  TWITTER_Link: "twitterLink",
  LINKEDIN_Link: "linkedInLink",
  FB_Link: "facebookLink",
};

export const getElementUniqueIds = (
  page: string,
  section: string,
  element: string = ""
) => {
  return [page, section, element].filter(Boolean).join("_");
};

export const getCamelCaseStr = (text: string) => {
  const ans = text.toLowerCase();
  return ans
    .split(" ")
    .reduce((s, c) => s + (c.charAt(0).toUpperCase() + c.slice(1)));
};

export const UTM_PARAMS_KEY = {
  UTM_SOURCE: "utm_source",
  UTM_MEDIUM: "utm_medium",
  UTM_CAMPAIGN: "utm_campaign",
  UTM_CONTENT: "utm_content",
  UTM_LOGIN: "utm_login",
  UTM_LENDER: "utm_lender",
  UTM_FLOW: "utm_flow",
};

export enum DATE_FORMATS {
  "DDD" = "ddd",
  "YY" = "yy",
  "YYYY" = "yyyy",
  "D MMM" = "d MMM",
  "DD/MM/YYYY" = "dd/MM/yyyy",
  "DD MMM YY" = "dd MMM'yy",
  "dd MMM, yyyy" = "dd MMM, yyyy",
  "YYYY-MM-DD" = "yyyy-MM-dd",
}
