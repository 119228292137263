import { fetchApiForexListing } from "../../api/fetchApi";
import {
  deepLink,
  ERROR_CODE as ERROR_CODE_CONST,
  ERROR_MSGS,
  HOST,
  PDT_EVENTS,
} from "../../constants";
import {
  getSessionExpiredRedirectUrl,
  redirectInterceptHref,
} from "../../static/utils/redirectionUtils";
import {
  errorCardCtaSignalState,
  fetchingCardCtaSignalState,
  loadingCardCtaSignalState,
} from "../../store/action";
import { ListingReducerType } from "../../store/forexListingReducer";
import { ERROR_CODE, ProductType } from "../../types";
import {
  getCardCtaSignalStateUrl,
  getEventDetails,
  getHostName,
  getRequestIdHeaders,
} from "../../utils";
import { loginInPartner } from "../../utils/authUtils";
import { useGlobalConfig } from "../useGlobalConfig";
import { useGlobalDispatch } from "../useGlobalDispatch";
import { useGlobalSelector } from "../useGlobalSelector";
import useListingPdtLoggerV2 from "../useListingPdtLoggerV2";
import { useListingPdtLogger } from "../usePdtLogger";

const useFetchCardCtaSignalState = () => {
  const dispatch = useGlobalDispatch();
  const {
    isLoadingCardCtaSignalState,
    isErrorCardCtaSignalState,
    isSuccessCardCtaSignalState,
  } = useGlobalSelector((state: ListingReducerType) => state || {});
  const { partner } = useGlobalConfig();
  const { sendPdtListingError, sendPdtLoginPopOpen } = useListingPdtLogger();
  const { sendPdtListingErrorV2 } = useListingPdtLoggerV2();

  const { sendCTAData } = useListingPdtLoggerV2();

  const sendPdtLoginPopupOpenV2 = () => {
    const eventDetail = getEventDetails({
      event_name: PDT_EVENTS.LOGIN_POP_UP_OPEN,
    });
    sendCTAData({ eventDetail });
  };

  const fetchCardCtaSignalState = async (
    card_type: ProductType,
    reqId: string
  ) => {
    dispatch(loadingCardCtaSignalState());
    const ctaStateUrl = getCardCtaSignalStateUrl({
      partner,
      card_type,
    });
    try {
      let headers = {};
      if (getHostName() !== HOST.MAKEMYTRIP) {
        headers = {
          ...getRequestIdHeaders(reqId),
        };
      }
      const response =
        (await fetchApiForexListing(ctaStateUrl, {
          method: "GET",
          headers: {
            ...getRequestIdHeaders(reqId),
          },
        })) || {};

      const { success, data, error } = response;
      if (success) {
        dispatch(
          fetchingCardCtaSignalState(data, {
            card_type,
          })
        );
        return response;
      } else {
        const { code }: { code: ERROR_CODE; description: string } = error || {};
        if (code === "LOGIN_REQUIRED") {
          dispatch(errorCardCtaSignalState(error));
          await loginInPartner(sendPdtLoginPopOpen, sendPdtLoginPopupOpenV2);
        } else {
          dispatch(
            errorCardCtaSignalState(error, {
              card_type,
            })
          );
        }
        sendPdtListingError(error, ERROR_MSGS.CARD_CTA_API_ERROR);
        sendPdtListingErrorV2(error, ERROR_MSGS.CARD_CTA_API_ERROR);
        return error;
      }
    } catch (e: any) {
      const error = {
        success: false,
        apiUrl: ctaStateUrl,
        code: e?.message?.includes(ERROR_MSGS.FETCH_ERROR)
          ? ERROR_CODE_CONST.NETWORK
          : "",
        description: e?.message?.includes(ERROR_MSGS.FETCH_ERROR)
          ? ERROR_MSGS.FETCH_ERROR
          : e?.message,
      };
      dispatch(errorCardCtaSignalState(error));
      sendPdtListingError(error);
      sendPdtListingErrorV2(error);
      return error;
    }
  };

  return {
    isLoadingCardCtaSignalState,
    isSuccessCardCtaSignalState,
    isErrorCardCtaSignalState,
    fetchCardCtaSignalState,
  };
};

export default useFetchCardCtaSignalState;
