import { getHostName, getPartnerFromUrl } from ".";
import { removeSessionStorageItem } from "./clientStorageUtils";
import {
  CARD_TYPE,
  SESSION_STORAGE_KEYS,
  deepLink,
  SIGNAL_STATE,
  CN_UPLOAD_DOC_REDIRECTION,
  PERSUASION_TYPE,
} from "../constants";
import { redirectInterceptHref } from "../static/utils";
import {
  landingCardShowcaseDataProps,
  landingForexOfferingCardDataProps,
} from "../static/mockData";
import { DeviceType } from "../hooks/useRedirectionUrls";

export interface IRedirectUrlOption {
  otuLandingPartnerUrl?: string;
  otuForexPartnerUrl?: string;
  tmBaseUrl: string;
  partner?: string | null;
  device: DeviceType | null;
  mwebtmBaseUrl: string;
}

export const signalStateRedirectionForCurrency = (
  tmBaseUrl: string,
  mwebtmBaseUrl: string,
  signalState: any,
  data: any = {},
  otuForexPartnerUrl: string | undefined
) => {
  const url = `${tmBaseUrl}/bmf/currency`;
  const partner = getPartnerFromUrl();
  const redirectBaseUrl = tmBaseUrl + "?";
  let queryParam = "";
  switch (signalState) {
    case SIGNAL_STATE.TRAVELLER_DETAILS:
      redirectInterceptHref(
        `${url}/onboarding/traveller-details?${
          data?.order_id ? "dp_orderId=" + data?.order_id : ""
        }&source=listing`
      );
      break;
    case SIGNAL_STATE.ADD_PRODUCTS:
      redirectInterceptHref(
        `${url}/onboarding/load-currency?${
          data?.order_id ? "dp_orderId=" + data?.order_id : ""
        }`
      );
      break;
    case SIGNAL_STATE.ORDER_UNDER_PROCESSING:
      redirectInterceptHref(
        `${url}/onboarding/traveller-details?${
          data?.order_id ? "dp_orderId=" + data?.order_id : ""
        }&source=listing`
      );
      break;
    case SIGNAL_STATE.DOCUMENT_PENDING:
      redirectInterceptHref(
        `${url}/post-sales/upload-documents/${data?.order_id}`
      );
      break;
    case SIGNAL_STATE.START_PAYMENT:
      if (data?.signal_data?.checkout_url) {
        redirectInterceptHref(`${data.signal_data.checkout_url}`);
      } else {
        redirectInterceptHref(`/partners/${partner}/forex/listing`);
      }
      break;
    case SIGNAL_STATE.VIEW_ORDERS:
      redirectInterceptHref(`${url}/post-sales/orders-listing`);
      break;
    default:
      redirectInterceptHref(`${url}/onboarding/load-currency?source=listing`);
      break;
  }
};

export function productTilesRedirectionsV2(
  item: landingCardShowcaseDataProps,
  { otuForexPartnerUrl, tmBaseUrl, device, mwebtmBaseUrl }: IRedirectUrlOption
) {
  let queryParam = "";
  const redirectBaseUrl = tmBaseUrl + "?";
  // device === DEVICE_MODEL.WEB
  //     ? `${otuForexPartnerUrl}&`
  //     : `${tmBaseUrl}/common?`;
  let nextSignalState;
  const data = {
    ...item.cta?.user_state_data,
    feature_flag: item.feature_flag,
  };
  switch (item.id) {
    case CARD_TYPE.MULTI_CURRENCY_CARD:
      // eslint-disable-next-line no-case-declarations
      nextSignalState = item?.cta?.user_state_data?.next_signal_state;
      signalStateRedirection(
        tmBaseUrl,
        mwebtmBaseUrl,
        nextSignalState,
        data,
        otuForexPartnerUrl
      );
      break;

    case CARD_TYPE.CURRENCY:
      nextSignalState = item?.cta?.user_state_data?.next_signal_state;
      signalStateRedirectionForCurrency(
        tmBaseUrl,
        mwebtmBaseUrl,
        nextSignalState,
        data,
        otuForexPartnerUrl
      );
      break;

    case CARD_TYPE.INR_CARD:
      queryParam = `deeplink=${deepLink.INR_CARD}`;
      redirectInterceptHref(`${redirectBaseUrl}${queryParam}`);
      break;

    default:
      throw new Error(`Invalid Card Id: ${item.id} [productTilesRedirections]`);
  }
}

export function redirectUploadDocsV2(
  item: landingCardShowcaseDataProps,
  { otuForexPartnerUrl, tmBaseUrl, mwebtmBaseUrl, device }: IRedirectUrlOption
) {
  removeSessionStorageItem(SESSION_STORAGE_KEYS.REQUEST_ID);
  const host = getHostName();
  const cnPostSaleBaseUrl = "/bmf/currency/post-sales";
  // device === DEVICE_MODEL.WEB
  //     ? `${otuForexPartnerUrl}&`
  //     : `${tmBaseUrl}/common?`;
  switch (item.id) {
    case CARD_TYPE.MULTI_CURRENCY_CARD:
      // if (host === HOST.MAKEMYTRIP) {
      //     redirectInterceptHref(
      //         `${otuForexPartnerUrl}&deeplink=${
      //             deepLink.RELOAD_UPLOAD_DOCS
      //         }${item?.order_id ? '&dp_orderId=' + item?.order_id : ''}`
      //     );
      // } else {
      //     redirectInterceptHref(
      //         `${mwebtmBaseUrl}/uploadDocs?deeplink=${
      //             deepLink.RELOAD_UPLOAD_DOCS
      //         }${item?.order_id ? '&dp_orderId=' + item?.order_id : ''}`
      //     );
      // }
      redirectInterceptHref(
        `${mwebtmBaseUrl}/dashboard?deeplink=${deepLink.RELOAD_UPLOAD_DOCS}`
      );
      break;
    case CARD_TYPE.CURRENCY:
      if (
        item?.docsPending?.redirect_to ===
        CN_UPLOAD_DOC_REDIRECTION.CN_UPLOAD_DOC
      ) {
        redirectInterceptHref(
          `${tmBaseUrl}${cnPostSaleBaseUrl}/upload-documents/${item?.docsPending?.order_id}`
        );
      } else if (
        item?.docsPending?.redirect_to ===
        CN_UPLOAD_DOC_REDIRECTION.CN_ORDER_LISTING
      ) {
        redirectInterceptHref(
          `${tmBaseUrl}${cnPostSaleBaseUrl}/orders-listing`
        );
      }
      break;

    // Note: For INR CARD, Upload docs persuasion won't come.
    // case CARD_TYPE.INR_CARD:
    //     redirectInterceptHref('');
    //     break;
    default:
      throw new Error(`Invalid Card Id: ${item.id} [redirectUploadDocs]`);
  }
}

export function redirectKnowMoreCardLinksV2(
  item: landingForexOfferingCardDataProps,
  { otuForexPartnerUrl, tmBaseUrl, device, mwebtmBaseUrl }: IRedirectUrlOption
) {
  removeSessionStorageItem(SESSION_STORAGE_KEYS.REQUEST_ID);
  let queryParam = "";
  const redirectBaseUrl = tmBaseUrl + "?";
  // device === DEVICE_MODEL.WEB
  //     ? `${otuForexPartnerUrl}&`
  //     : `${tmBaseUrl}/common?`;
  const host = getHostName();
  let nextSignalState;
  switch (item.id) {
    case CARD_TYPE.MULTI_CURRENCY_CARD:
      // if (host === HOST.MAKEMYTRIP) {
      //     queryParam = `deeplink=${deepLink.BMF_LANDING_PAGE}&source=listing`;
      //     redirectInterceptHref(`${otuForexPartnerUrl}&${queryParam}`);
      // } else {
      //     redirectInterceptHref(
      //         `${tmBaseUrl}/bmf/onboarding/landing?source=listing`
      //     );
      // }
      redirectInterceptHref(
        `${tmBaseUrl}/bmf/onboarding/landing?source=listing`
      );
      break;

    case CARD_TYPE.CURRENCY:
      nextSignalState = item?.cta?.user_state_data?.next_signal_state;
      signalStateRedirectionForCurrency(
        tmBaseUrl,
        mwebtmBaseUrl,
        nextSignalState,
        item?.cta?.user_state_data,
        otuForexPartnerUrl
      );
      break;

    case CARD_TYPE.INR_CARD:
      queryParam = `deeplink=${deepLink.INR_CARD}`;
      redirectInterceptHref(`${redirectBaseUrl}${queryParam}`);
      break;

    default:
      throw new Error(`Invalid Card Id: ${item.id} [productTilesRedirections]`);
  }
}

export function exploreForexCurrencyRedirectionV2(
  item: any,
  { otuForexPartnerUrl, tmBaseUrl, device, mwebtmBaseUrl }: IRedirectUrlOption
) {
  const nextSignalState = item?.cta?.user_state_data?.next_signal_state;
  signalStateRedirectionForCurrency(
    tmBaseUrl,
    mwebtmBaseUrl,
    nextSignalState,
    item?.cta?.user_state_data,
    otuForexPartnerUrl
  );
}

export function quickLoadMoneyRedirectionsV2(
  item: landingCardShowcaseDataProps,
  { tmBaseUrl, otuForexPartnerUrl, mwebtmBaseUrl, device }: IRedirectUrlOption
) {
  let queryParam = "";
  const redirectBaseUrl = tmBaseUrl + "?";
  // device === DEVICE_MODEL.WEB
  //     ? `${otuForexPartnerUrl}&`
  //     : `${tmBaseUrl}/common?`;
  const host = getHostName();
  switch (item.id) {
    case CARD_TYPE.MULTI_CURRENCY_CARD:
      // if (host === HOST.MAKEMYTRIP) {
      //     redirectInterceptHref(
      //         `${otuForexPartnerUrl}&deeplink=${
      //             deepLink.RELOAD_LOAD_CURRENCY
      //         }${item?.order_id ? '&dp_orderId=' + item?.order_id : ''}`
      //     );
      // } else {
      //     redirectInterceptHref(
      //         `${mwebtmBaseUrl}/loadCurrency?deeplink=${
      //             deepLink.RELOAD_LOAD_CURRENCY
      //         }${item?.order_id ? '&dp_orderId=' + item?.order_id : ''}`
      //     );
      // }
      if (item.feature_flag?.solve_reload) {
        redirectInterceptHref(
          `${tmBaseUrl}/bmf/card/post-sales/new-trip?deeplink=${deepLink.RELOAD_LOAD_CURRENCY}`
        );
      } else {
        redirectInterceptHref(
          `${mwebtmBaseUrl}/loadCurrency?deeplink=${deepLink.RELOAD_LOAD_CURRENCY}`
        );
      }
      break;

    // Quick load money option is not available.
    // case CARD_TYPE.CURRENCY:
    //     queryParam = `deeplink=${deepLink.CURRENCY_NOTES}`;
    //     redirectInterceptHref(`${redirectBaseUrl}${queryParam}`);
    //     break;

    case CARD_TYPE.INR_CARD:
      queryParam = `deeplink=load-money`;
      redirectInterceptHref(`${redirectBaseUrl}${queryParam}`);
      break;
    default:
      throw new Error(`Invalid Card Id: ${item.id} [quickLoadRedirections]`);
  }
}

export function completeOrderRedirectionsV2(
  item: landingCardShowcaseDataProps,
  { otuForexPartnerUrl, tmBaseUrl, mwebtmBaseUrl, device }: IRedirectUrlOption
) {
  let queryParam = "";
  const redirectBaseUrl = tmBaseUrl + "?";
  // device === DEVICE_MODEL.WEB
  //     ? `${otuForexPartnerUrl}&`
  //     : `${tmBaseUrl}/common?`;
  let nextSignalState;
  const data = {
    ...item.cta?.user_state_data,
    feature_flag: item.feature_flag,
  };
  switch (item.id) {
    case CARD_TYPE.MULTI_CURRENCY_CARD:
      // eslint-disable-next-line no-case-declarations
      nextSignalState = item?.cta?.user_state_data?.next_signal_state;
      signalStateRedirection(
        tmBaseUrl,
        mwebtmBaseUrl,
        nextSignalState,
        data,
        otuForexPartnerUrl
      );
      break;
    case CARD_TYPE.CURRENCY:
      nextSignalState = item?.cta?.user_state_data?.next_signal_state;
      signalStateRedirectionForCurrency(
        tmBaseUrl,
        mwebtmBaseUrl,
        nextSignalState,
        item?.cta?.user_state_data,
        otuForexPartnerUrl
      );
      break;

    // Note: For INR CARD, drop off persuasion won't come.
    // case CARD_TYPE.INR_CARD:
    //     redirectInterceptHref('');
    //     break;
    default:
      throw new Error(
        `Invalid Card Id: ${item.id} [completeOrderRedirections]`
      );
  }
}

export function trackCardRedirectionsV2(
  item: landingCardShowcaseDataProps,
  { mwebtmBaseUrl, otuForexPartnerUrl, partner, device }: IRedirectUrlOption
) {
  removeSessionStorageItem(SESSION_STORAGE_KEYS.REQUEST_ID);
  const host = getHostName();
  let queryParam = "";
  switch (item.id) {
    case CARD_TYPE.MULTI_CURRENCY_CARD:
      queryParam = `deeplink=${deepLink.RELOAD_TRACK_ORDER}${
        item?.order_id ? "&dp_orderId=" + item?.order_id : ""
      }`;

      // if (host === HOST.MAKEMYTRIP) {
      //     redirectInterceptHref(`${otuForexPartnerUrl}&${queryParam}`);
      // } else {
      //     redirectInterceptHref(
      //         `${mwebtmBaseUrl}/dashboard?${queryParam}`
      //     );
      // }
      redirectInterceptHref(`${mwebtmBaseUrl}/dashboard?${queryParam}`);
      break;

    /*
        Note: For INR CARD & CURRENCY notes, track card persuasion won't come.
        case CARD_TYPE.CURRENCY:
            redirectInterceptHref(
                ''
            );
            break;

        case CARD_TYPE.INR_CARD:
            redirectInterceptHref('');
            break;
        */
    default:
      throw new Error(`Invalid Card Id: ${item.id} [trackCardRedirections]`);
  }
}

export function landingPageRedirectV2({
  otuLandingPartnerUrl,
  tmBaseUrl,
  device,
}: IRedirectUrlOption) {
  redirectInterceptHref(`${otuLandingPartnerUrl}`);
}

export const signalStateRedirection = (
  tmBaseUrl: string,
  mwebtmBaseUrl: string,
  signalState: any,
  data: any = {},
  otuForexPartnerUrl: string | undefined
) => {
  const url = `${tmBaseUrl}/bmf/onboarding`;
  const host = getHostName();
  let queryParam = "";
  switch (signalState) {
    case SIGNAL_STATE.TRAVELLER_DETAILS:
      // if (host === HOST.MAKEMYTRIP) {
      //     redirectInterceptHref(
      //         `${otuForexPartnerUrl}&deeplink=${
      //             deepLink.BMF_TRAVELLER_DETAILS
      //         }${data?.order_id ? '&dp_orderId=' + data?.order_id : ''}`
      //     );
      // } else {
      //     redirectInterceptHref(
      //         `${url}/traveller-details?deeplink=${
      //             deepLink.BMF_TRAVELLER_DETAILS
      //         }${data?.order_id ? '&dp_orderId=' + data?.order_id : ''}`
      //     );
      // }
      redirectInterceptHref(
        `${url}/traveller-details?deeplink=${deepLink.BMF_TRAVELLER_DETAILS}${
          data?.order_id ? "&dp_orderId=" + data?.order_id : ""
        }&source=listing`
      );
      break;
    case SIGNAL_STATE.DOCUMENT_PENDING:
      redirectInterceptHref(
        `${mwebtmBaseUrl}/uploadDocs?dp_orderId=${data.orderId}`
      );
      break;
    case SIGNAL_STATE.ORDER_UNDER_PROCESSING:
    case SIGNAL_STATE.ADD_PRODUCTS:
      // if (host === HOST.MAKEMYTRIP) {
      //     redirectInterceptHref(
      //         `${otuForexPartnerUrl}&deeplink=${
      //             deepLink.BMF_ADD_PRODUCTS
      //         }${data?.order_id ? '&dp_orderId=' + data?.order_id : ''}`
      //     );
      // } else {
      //     redirectInterceptHref(
      //         `${url}/load-currency?deeplink=${
      //             deepLink.BMF_ADD_PRODUCTS
      //         }${data?.order_id ? '&dp_orderId=' + data?.order_id : ''}`
      //     );
      // }
      redirectInterceptHref(
        `${url}/load-currency?deeplink=${deepLink.BMF_ADD_PRODUCTS}${
          data?.order_id ? "&dp_orderId=" + data?.order_id : ""
        }`
      );
      break;
    case SIGNAL_STATE.START_PAYMENT:
      if (data?.signal_data?.checkout_url) {
        redirectInterceptHref(`${data.signal_data.checkout_url}`);
      } else {
        // if (host === HOST.MAKEMYTRIP) {
        //     redirectInterceptHref(
        //         `${otuForexPartnerUrl}&deeplink=${deepLink.BMF_LANDING_PAGE}&source=listing`
        //     );
        // } else {
        //     redirectInterceptHref(`${url}/landing?source=listing`);
        // }
        redirectInterceptHref(`${url}/landing?source=listing`);
      }
      break;

    case SIGNAL_STATE.BMF_CARD_LANDING:
      // if (host === HOST.MAKEMYTRIP) {
      //     redirectInterceptHref(
      //         `${otuForexPartnerUrl}&deeplink=${deepLink.BMF_LANDING_PAGE}&source=listing`
      //     );
      // } else {
      //     redirectInterceptHref(`${url}/landing?source=listing`);
      // }
      redirectInterceptHref(`${url}/landing?source=listing`);
      break;

    case SIGNAL_STATE.BMF_PAYMENT_FAILED:
    case SIGNAL_STATE.BMF_PAYMENT_CONFIRMED:
    case SIGNAL_STATE.BMF_PAYMENT_BEING_CONFIRMED:
    case SIGNAL_STATE.BMF_DASHBOARD:
      // if (host === HOST.MAKEMYTRIP) {
      //     queryParam = `deeplink=${deepLink.RELOAD_DASHBOARD}`;
      //     redirectInterceptHref(`${otuForexPartnerUrl}&${queryParam}`);
      // } else {
      //     redirectInterceptHref(`${mwebtmBaseUrl}/dashboard`);
      // }
      if (data.feature_flag?.solve_reload) {
        redirectInterceptHref(`${mwebtmBaseUrl}/dashboard/new`);
      } else {
        redirectInterceptHref(`${mwebtmBaseUrl}/dashboard`);
      }
      break;

    case SIGNAL_STATE.BMF_ORDER_SUBMITTED:
      // if (host === HOST.MAKEMYTRIP) {
      //     redirectInterceptHref(
      //         `${otuForexPartnerUrl}&deeplink=${deepLink.BMF_ORDER_SUBMITTED}`
      //     );
      // } else {
      //     redirectInterceptHref(
      //         `${mwebtmBaseUrl}/documentSubmit?deeplink=${deepLink.BMF_ORDER_SUBMITTED}`
      //     );
      // }
      redirectInterceptHref(
        `${mwebtmBaseUrl}/documentSubmit?deeplink=${deepLink.BMF_ORDER_SUBMITTED}`
      );
      break;

    default:
      redirectInterceptHref(`${url}/traveller-details?source=listing`);
      break;
  }
};

export const currentTripReloadRedirection = (
  item: landingCardShowcaseDataProps,
  { otuForexPartnerUrl, tmBaseUrl, device, mwebtmBaseUrl }: IRedirectUrlOption
) => {
  switch (item.id) {
    case CARD_TYPE.MULTI_CURRENCY_CARD:
      if (item.feature_flag?.solve_reload) {
        redirectInterceptHref(
          `${tmBaseUrl}/bmf/card/post-sales/current-trip?deeplink=${deepLink.RELOAD_LOAD_CURRENCY}`
        );
      } else {
        redirectInterceptHref(
          `${mwebtmBaseUrl}/loadCurrency?deeplink=${deepLink.RELOAD_LOAD_CURRENCY}`
        );
      }
      break;
    default:
      console.error("Current trip reload is supported only for card product");
  }
};

export const viewDashboardRedirectionsV2 = (
  item: landingCardShowcaseDataProps,
  { otuForexPartnerUrl, tmBaseUrl, device, mwebtmBaseUrl }: IRedirectUrlOption
) => {
  let queryParam = "";
  const redirectBaseUrl = tmBaseUrl + "?";
  // device === DEVICE_MODEL.WEB
  //     ? `${otuForexPartnerUrl}&`
  //     : `${tmBaseUrl}/common?`;
  const host = getHostName();
  switch (item.id) {
    case CARD_TYPE.MULTI_CURRENCY_CARD:
      // if (host === HOST.MAKEMYTRIP) {
      //     queryParam = `deeplink=${deepLink.RELOAD_DASHBOARD}`;
      //     redirectInterceptHref(`${otuForexPartnerUrl}&${queryParam}`);
      // } else {
      //     redirectInterceptHref(`${mwebtmBaseUrl}/dashboard`);
      // }
      if (item.feature_flag?.solve_reload) {
        redirectInterceptHref(`${mwebtmBaseUrl}/dashboard/new`);
      } else {
        redirectInterceptHref(`${mwebtmBaseUrl}/dashboard`);
      }
      break;

    case CARD_TYPE.INR_CARD:
      queryParam = `deeplink=${deepLink.INR_CARD}`;
      redirectInterceptHref(`${redirectBaseUrl}${queryParam}`);
      break;

    // For currency mini dashboard won't come
    // case CARD_TYPE.CURRENCY:
    //     queryParam = `deeplink=${deepLink.CURRENCY_NOTES}`;
    //     redirectInterceptHref(`${redirectBaseUrl}${queryParam}`);
    //     break;

    default:
      throw new Error(
        `Invalid Card Id: ${item.id} [viewDashboardRedirectionsV2]`
      );
  }
};

export const viewOrdersRedirectionsV2 = (
  item: landingCardShowcaseDataProps,
  { otuForexPartnerUrl, tmBaseUrl, device, mwebtmBaseUrl }: IRedirectUrlOption
) => {
  switch (item.id) {
    case CARD_TYPE.CURRENCY:
      redirectInterceptHref(
        `${tmBaseUrl}/bmf/currency/post-sales/orders-listing`
      );
      break;
    default:
      throw new Error(`Invalid Card Id: ${item.id} [viewOrdersRedirectionsV2]`);
  }
};
