import { getPartner } from "../utils/authUtils";
import { getHostName } from "../utils";

export const CURRENCY_RATE_REFRESH_TIME = 60000;
export const WAITING_TIME_LOC_SUCCESS_SCREEN = 2500;
export const WAITING_TIME_LOCATION_FETCING = 1000;

// export const BASE_URL = process.env["NODE_ENV"] === "development" ? "http://127.0.0.1:3003" : "https://www.tripmoney.com";
export const BASE_URL =
  process.env["NX_ENV"] === "local" ? "http://localhost:9000/api" : "";

export const DEVICE = "device";

export const PDT_BASE_URL = "ext/api/v1";

export const CLIENT_BACKEND_BASE_URL = "forex-client-backend/cb";

export const LISTING_API_PATH =
  process.env["NX_ENV"] === "local"
    ? "/card-management/ext/api/v1/{partner}/landing/query?page=product_listing"
    : `/${CLIENT_BACKEND_BASE_URL}/card-management/ext/api/v1/{partner}/listing/query?page=product_listing`;

export const GET_CONFIG_API_PATH =
  process.env["NX_ENV"] === "local"
    ? "/card-management/api/v1/get-config"
    : `/${CLIENT_BACKEND_BASE_URL}/card-management/api/v1/get-config`;

export const CARD_CTA_FETCH_API_PATH =
  process.env["NX_ENV"] === "local"
    ? "/card-management/ext/api/v1/{partner}/landing/cta?product={card_type}"
    : `/${CLIENT_BACKEND_BASE_URL}/card-management/ext/api/v1/{partner}/listing/cta?product={card_type}`;

export const CROSS_SELL_API_PATH =
  process.env["NX_ENV"] === "local"
    ? "/card-management/ext/api/v1/{partner}/landing/cross-sell"
    : `/${CLIENT_BACKEND_BASE_URL}/multi-currency/pre-sales/ext/api/v1/{partner}/product/cross-sell`;

export enum USER_TYPE {
  EXISTING_CUSTOMER = "existing_customer",
  NEW_CUSTOMER = "new_customer",
}

export enum FOREX_RATES_FILER_MODE {
  TOP = "TOP",
  SELECT = "SELECT",
}

export const MakeMyTrip = "mmt";
export const Goibibo = "gi";

export enum PERSUASION_TYPE {
  DELIVERY_TRACKING = "delivery_tracking",
  BACKEND = "backend",
  DROP_OFF = "dropoff",
  MINI_DASHBOARD = "mini_dashboard",
  UPLOAD_DOCUMENTS = "upload_docs",
  VIEW_ORDERS = "view_orders",
  START_ORDER = "start_order",
  UPCOMING_TRIPS = "upcoming_trips",
  UNDELIVERABLE = "undeliverable",
  ACTIVATION_IN_PROGRESS = "activation_in_progress",
  CROSS_SELL = "cross_sell",
  FREE_CARD = "free_card",
  RELOAD_TRIP = "reload_discovery",
}

export const CARD_TYPE = {
  INR_CARD: "inr_card",
  MULTI_CURRENCY_CARD: "multi_cx_card",
  CURRENCY: "currency",
};

export enum PARTNER {
  MMT = "mmt",
  GI = "gi",
  TRIPMONEY = "tm",
}

export const SESSION_STORAGE_KEYS = {
  USER_LOCATION_SESSION_KEY: "user_location",
  REQUEST_ID: "request_id",
  IS_FALLBACK_CITY_MODE: "is_fallback_city_mode",
  ITINERARY_ID: "itinerary_id",
  TICKET_ID: "ticket_id",
};

export enum LOCAL_STORAGE_KEYS {
  CURRENCY_ORDER_COACHMARK_KEY = "currency_order_coachmark",
}

export enum SCREEN {
  LISTING_PAGE = "LISTING_PAGE",
  EDIT_LOCATION = "EDIT_LOCATION",
}

export const HOST = {
  TRIPMONEY: "www.tripmoney.com",
  MAKEMYTRIP: "www.makemytrip.com",
  GOIBIBO: "www.goibibo.com",
  LOCALHOST: "localhost",
  TRIPMONEY_MAKEMYTRIP: "tripmoney.makemytrip.com",
  TRIPMONEY_GOIBIBO: "tripmoney.goibibo.com",
};

export const SOURCE = {
  TRIPMONEY: "tripmoney",
  MAKEMYTRIP: "makemytrip",
  GOIBIBO: "goibibo",
  LOCALHOST: "localhost",
  FOREX_EXPERT: "forexexpert",
};

export const PageNameMapping = {
  LISTING_PAGE: "listingPage",
};

export const SectionNameMapping = {
  HEADER: "header",
  DELIVERY: "delivery",
  EDIT_ADDRESS: "editAddress",
  FAQs: "faqs",
  CURR_CARD: "forexCurrencyCard",
  LANDING_CARDS: "landingCardFeature",
  TRUST_US: "trustUs",
  OFFERINGS: "knowMoreOfferings",
};

export const ElementNameMapping = {
  BACK_BUTTON: "backBtn",
  DOWN_ARROW: "downArrow",
  CLOSE_BUTTON: "closeButton",
  PINCODE: "pincode",
  DETECT_LOC: "detectLocation",
  CONTINUE_BTN: "continueBtn",
  KNOW_MORE_BTN: "knowMoreOfferingsBtn",
  EXPLORE_MORE_BTN: "exploreMoreBtn",
  SIDE_ARROW: "sideArrow",
  QUICK_LOAD: "quickLoadBtn",
  COMPLETE_ORDER: "completeOrderBtn",
  TRACK_BTN: "trackBtn",
  UPLOAD_NOW: "uploadNowBtn",
  ANOTHER_LOC_BTN: "anotherLocationBtn",
  LOGO_CONTAINER: "logoContainer",
  DELIVERY_LABEL: "deliveryLabel",
  DELIVERY_LOC: "deliveryLocation",
  DELIVERY_ETA: "deliveryEta",
  HEADING: "mainHeading",
  PERSUASION_TILE: "persuasionTile",
  COMP_HEADING: "heading",
  RIGHT_BUTTON: "rightButton",
  RELOAD_TRIP: "reloadTripBtn",
  CLUB_OFFER: "clubOfferWrapper",
  VIEW_PRICES: "viewPrices",
  PRICE_TICKER: "priceTicker",
};

export enum deepLink {
  MULTI_CURRENCY_CARD = "bmf-card",
  CURRENCY_NOTES = "bmf-currencies",
  INR_CARD = "inr-card",
  RELOAD_TRACK_ORDER = "reload_track_order",
  RELOAD_UPLOAD_DOCS = "reload_upload_docs",
  RELOAD_ORDER_ID = "dp_orderId",
  RELOAD_LOAD_CURRENCY = "reload_load_currency",
  RELOAD_DASHBOARD = "reload_dashboard",
  BMF_TRAVELLER_DETAILS = "bmf_traveller_details",
  BMF_ADD_PRODUCTS = "bmf_load_currency",
  BMF_PAYMENT_CONFIRMED = "bmf_payment_confirmed",
  BMF_PAYMENT_FAILED = "bmf_payment_failed",
  BMF_ORDER_SUBMITTED = "bmf_order_submitted",
  BMF_PAYMENT_BEING_CONFIRMED = "bmf_payment_being_confirmed",
  BMF_START_PAYMENT = "bmf_start_payment",
  BMF_ORDER_UNDER_PROCESSING = "bmf_order_under_processing",
  BMF_LANDING_PAGE = "bmf_onboarding_landing",
  BMF_RELOAD_DOCUMENT_SUBMIT = "reload_document_submit",
}

export enum MINI_DASHBOARD_ACTION {
  LOAD_CURRENCY = "load_currency",
  QUICK_LOAD = "quick_load",
}

export const MINI_DASHBOARD_BUTTON_LABEL: { [key: string]: string } = {
  [MINI_DASHBOARD_ACTION.LOAD_CURRENCY]: "LOAD CURRENCY",
  [MINI_DASHBOARD_ACTION.QUICK_LOAD]: "QUICK LOAD",
};

export enum ERROR_CODE {
  DISPLAY_ERROR = "DISPLAY_ERROR",
  LOGIN_REQUIRED = "LOGIN_REQUIRED",
  NETWORK = "NETWORK",
}

export const SIGNAL_STATE = {
  BMF_CARD_LANDING: "landing",
  BMF_RELOAD_DOCUMENT_PENDING: "documents",
  BMF_DASHBOARD: "dashboard",
  BMF_PAYMENT_CONFIRMED: "payment_confirmed",
  BMF_PAYMENT_BEING_CONFIRMED: "payment_being_confirmed",
  BMF_ORDER_SUBMITTED: "order_submitted",
  BMF_PAYMENT_FAILED: "payment_failed",
  BMF_ASYNC_PROCESS_PENDING: "async_process_pending",
  TRAVELLER_DETAILS: "traveller_details",
  ADD_PRODUCTS: "add_products",
  PRODUCTS_ADDED: "products_added",
  ORDER_UNDER_PROCESSING: "order_under_processing",
  ADD_PRODUCTS_AWAITED: "order_under_processing",
  ADD_PRODUCTS_FAILED: "add_products_failed",
  TRAVELLER_DETAILS_ADDED: "traveller_details_added",
  TRAVELLER_DETAILS_FAILED: "traveler_details_failed",
  TRAVELLER_DETAILS_AWAITED: "traveler_details_awaited",
  PAYMENT_BEING_CONFIRMED: "payment_being_confirmed",
  PAYMENT_FAILED: "payment_failed",
  PAYMENT_CONFIRMED: "payment_confirmed",
  START_PAYMENT: "start_payment",
  PAYMENT_DONE: "payment_done",
  BMF_PAYMENT_NOT_POSSIBLE: "payment_not_possible",
  DOCUMENT_PENDING: "document_pending",
  VIEW_ORDERS: "view_orders",
};

export const USER_STATE = {
  DASHBOARD: "DASHBOARD",
  ORDER_IN_PROGRESS: "ORDER_IN_PROGRESS",
};

export const VERSIONS = {
  V1: 1,
  V2: 2,
  V3: 3,
};

export const DEFAULT_MX_FLOW_VERSION = VERSIONS.V2;

export const DEFAULT_CN_FLOW_VERSION = VERSIONS.V1;

export const DEFAULT_FLOW = VERSIONS.V2;

export const DEVICE_MODEL = {
  APP: "App",
  WEB: "Web",
};

export enum DEVICE_MODEL_TYPE {
  APP = "App",
  WEB = "Web",
}

export enum PLATFORM {
  PWA = "pwa",
  DESKTOP = "desktop",
  APP = "app",
}

export const COOKIES = {
  MMT_AUTH_KEY: "mmt-auth",
  TM_AUTH_KEY: "x-tm-auth",
  MMT_LOGIN_REDIRECT_KEY: "loginActionDetails",
  TM_MMT_AUTH_KEY: "tm-mmt-auth",
  GI_AUTH_KEY: "OAUTH-GOIBIBO",
  TM_GI_AUTH_KEY: "tm-gi-auth",
  INR_SESSION_COOKIE: "is-initial-landing",
  MMT_DEVICE_ID: "dvid",
  GI_DEVICE_ID: "__gi_vid",
  MMT_FOREX_COOKIE: "mmt_forex",
  CURRENCY_DEEPLOGIN_ALLOWED: "currency_deeplogin_allowed",
  PDT_JOURNEY_ID: "x-jo-id",
  CROSS_SELL_FLOW: "cross_sell_flow",
  CROSS_SELL_NUDGE_SHOWN: "cross_sell_nudge_shown",
  FOREX_AGENT_AUTH_KEY:
    getPartner() === MakeMyTrip
      ? "mmt-auth-afftmfxmmtforexexp"
      : "mmt-auth-afftmfxgiforexexp",
};

export const DEEPLINK = "deeplink";

export enum DELIVERY_DETAILS_FILTER_MODE {
  AUTO = "AUTO",
  FORCE = "FORCE",
  CITY_FALLBACK = "CITY_FALLBACK",
}

export const SHOW_DATA_ON_RIGHT_SIDE_IN_FOREX_CARD_LIMIT = 2;

export const MMT_FONT_FAMILY = {
  headingFontFamily: "Lato",
  bodyFontFamily: "Lato",
};

export const GOIBIBO_FONT_FAMILY = {
  headingFontFamily: "Raleway",
  bodyFontFamily: "Open Sans",
};

export const TRIPMONEY_FONT_FAMILY = {
  headingFontFamily: "Raleway",
  bodyFontFamily: "Open Sans",
};
export const ERROR_MSGS = {
  UNABLE_TO_FETCH_LOC: "We are Sorry! Unable to fetch location at the moment",
  FAILED_TO_FETCH_LOC:
    "We failed to fetch your location. Please try again in some time.",
  GEOLOCATION_NOT_SUPPORTED: "Geolocation not supported",
  CARD_CTA_API_ERROR: "error fetching card cta api",
  FOREX_RATES_API_ERROR: "error fetching forex rates api",
  FOREX_RATES_SUCCESS_ERROR: "fetched forex rates api with success as false",
  FOREX_RATES_API_REQUEST_ERROR: "error receiving forex rates api response",
  FOREX_RATES_NOT_FOUND: "fetched empty forex rates data",
  FOREX_RATES_RESPONSE_NULL: "forex rates api returning null object",
  PRODUCT_LISTING_API_ERROR: "error in fetching product listing api",
  PRODUCT_LIST_ERROR: "error in fetching products list from listing api",
  LISTING_API_FAIL: "product listing api failed",
  LOCATION_DENIED: "Location denied",
  LOCATION_FETCH_ERROR: "location fetch error",
  INVALID_PINCODE: "Invalid Pin code. Please enter a valid Pin code.",
  PINCODE_NOT_SERVICEABLE:
    "This Pincode is not serviceable at the moment. Please enter another pincode.",
  FETCH_ERROR: "Failed to fetch",
};

export const PDT_EVENTS = {
  QUICK_LOAD_CTA: "listing_quick_load_clicked",
  TRACK_CARD_CTA: "listing_track_card_clicked",
  UPLOAD_DOCS_CTA: "listing_upload_docs_clicked",
  LOC_DETECT_EVENT: "listing_location_detection_event",
  START_ORDER_CTA: "listing_start_order_clicked",
  PAGE_LOAD_EVENT: "listing_page_load_event",
  LOCATION_BAR_CTA: "listing_location_bar_clicked",
  UPCOMING_TRIP: "listing_upcoming_trip",
  LOC_BOTTOM_SHEET_VIEWED: "listing_locationBottomSheet_viewed",
  DETECT_LOC_CTA: "listing_locationBottomSheet_detectLocation_clicked",
  SUBMIT_PINCODE_CTA: "listing_locationBottomSheet_submitPincode_clicked",
  RATE_TICKER_LOAD: "listing_rate_ticker_load_event",
  PRODUCT_TILE_CTA: "listing_productTile_clicked",
  KNOW_MORE_CTA: "listing_knowMoreCard_clicked",
  FAQ_CTA: "listing_faq_clicked",
  FAQ_TAB_CTA: "listing_faq_tab_clicked",
  COMPLETE_ORDER_CTA: "listing_completeOrder_clicked",
  UNAVAILABLE_LOC: "listing_unavailable_location_clicked",
  ANOTHER_LOC: "unavailable_loc",
  VIEW_DASHBOARD: "listing_view_dashboard_clicked",
  RELOAD_CURRENT_TRIP: "listing_current_trip_reload_clicked",
  JWT_TOKEN_API_CALLER: "JWTTokenApiCaller",
  APP_SOCIAL_API_CALLED: "appSocialApiCalled",
  VIEW_ORDERS: "listing_currency_view_orders_clicked",
  MARKETING_BANNER_CTA: "listingPgMktgBannerClicked",
  MARKETING_BANNER_LOAD: "listingPgMktgBannerLoaded",

  // DEEP LOGIN PDT.
  LISTING_PAGE_LOGIN_CLICK: "listing_pg_login_click",
  LOGIN_POP_UP_OPEN: "login_pop_up_open",
  EMAIL_LOGIN_POP_UP_OPEN: "email_login_pop_up_open",
  EMAIL_LOGIN_POP_UP_OK_CLICK: "email_login_pop_up_ok_click",
  UPDATE_APP_POP_UP_OPEN: "update_app_pop_up_open",
  UPDATE_APP_POP_UP_OK_CLICK: "update_app_pop_up_ok_click",
  LOGIN_ERROR_POP_UP_OPEN: "login_error_pop_up_open",
  LOGIN_ERROR_POP_UP_OK_CLICK: "login_error_pop_up_ok_click",

  //Fallback City
  FALLBACK_CITY_TRIGGERED: "fallback_city_triggered",
  FALLBACK_CITY_SUBMITTED: "fallback_city_submitted",

  // Listing Coupon Shown
  LISTING_COUPON_SHOWN: "listing_coupon_shown",
  LISTING_VIEW_COUPONS_CLICK: "listing_view_coupons_click",

  // Coachmark PDT
  COACHMARK_VIEWED: "listing_coachmark_viewed",
  COACKMARK_BUTTON_CLICK: "listing_coachmark_button_click",

  //cross sell
  CROSS_SELL_NUDGE_SHOWN: "listing_cross_sell_nudge_shown",
  CROSS_SELL_NUDGE_CLICKED: "listing_cross_sell_nudge_clicked",
};

export const LSITING_PDT_EVENTS_V2 = {
  listing_quick_load_clicked: "listing_quick_load_cta-clicked",
  listing_track_card_clicked: "listing_track_card_cta-clicked",
  listing_upload_docs_clicked: "listing_upload_docs_cta-clicked",
  listing_location_detection_event: "listing_location_detection-loaded",
  listing_start_order_clicked: "listing_start_order_cta-clicked",
  listing_page_load_event: "listing_page-loaded",
  listing_location_bar_clicked: "listing_location_bar_cta-clicked",
  listing_upcoming_trip: "listing_upcoming_trip-viewed",
  listing_locationBottomSheet_viewed: "listing_location_bottom_sheet-viewed",
  listing_locationBottomSheet_detectLocation_clicked:
    "listing_location_bottom_sheet_detect_location_cta-clicked",
  listing_locationBottomSheet_submitPincode_clicked:
    "listing_location_bottom_sheet_submit_pincode_cta-clicked",
  listing_rate_ticker_load_event: "listing_rate_ticker-loaded",
  listing_productTile_clicked: "listing_product_tile_cta-clicked",
  listing_knowMoreCard_clicked: "listing_know_more_card_cta-clicked",
  listing_faq_clicked: "listing_faq-clicked",
  listing_faq_tab_clicked: "listing_faq_tab-clicked",
  listing_completeOrder_clicked: "listing_complete_order_cta-clicked",
  listing_unavailable_location_clicked:
    "listing_unavailable_location_cta-clicked",
  unavailable_loc: "listing_unavailable_location-viewed",
  listing_view_dashboard_clicked: "listing_view_dashboard_cta-clicked",
  JWTTokenApiCaller: "jwt_token_api-called",
  appSocialApiCalled: "app_social_api-called",
  listing_currency_view_orders_clicked:
    "listing_currency_view_orders_cta-clicked",
  listingPgMktgBannerClicked: "listing_pg_mktg_banner-clicked",
  listingPgMktgBannerLoaded: "listing_pg_mktg_banner-loaded",
  listing_pg_login_click: "listing_pg_login_cta-clicked",
  login_pop_up_open: "listing_login_pop_up-opened",
  email_login_pop_up_open: "email_login_pop_up-opened",
  email_login_pop_up_ok_click: "email_login_pop_up_ok_cta-clicked",
  update_app_pop_up_open: "update_app_pop_up-opened",
  update_app_pop_up_ok_click: "update_app_pop_up_ok_cta-clicked",
  login_error_pop_up_open: "login_error_pop_up-opened",
  login_error_pop_up_ok_click: "login_error_pop_up_ok_cta-clicked",
  fallback_city_triggered: "fallback_city-triggered",
  fallback_city_submitted: "fallback_city-submitted",
  listing_coupon_shown: "listing_coupon-viewed",
  listing_view_coupons_click: "listing_view_coupons_cta-clicked",
  listing_coachmark_viewed: "listing_coachmark-viewed",
  listing_coachmark_button_click: "listing_coachmark_cta-clicked",
};

export enum FAQ_CLICK_HERE_LINK_ID {
  WHAT_IS_MX_CARD_CLICK_HERE = "what_is_mx_card_click_here",
  HOW_TO_GET_CARD_CLICK_HERE = "how_to_get_card_click_here",
  ORDER_CURRENCY_CLICK_HERE = "order_currency_click_here",
}
export const DESKTOP = "Desktop";
export const MOBILE = "Mobile";

export const APP_SOCIAL_URL = (partner: any) => {
  const hostname = getHostName()?.includes(SOURCE.MAKEMYTRIP)
    ? "https://mapi.makemytrip.com"
    : "https://mapi.goibibo.com";
  return `${hostname}/ext/TripMoney/Forex/appSocialLogin`;
};

export const GET_SLT_TOKEN_URL = (partner: any) => {
  const hostname = getHostName()?.includes(SOURCE.MAKEMYTRIP)
    ? "https://mapi.makemytrip.com"
    : "https://mapi.goibibo.com";
  return `${hostname}/ext/web/pwa/jwt/loginToken`;
};

export const USER_SERVICE_TOKEN =
  process.env["NODE_ENV"] === "production"
    ? "h4nhc9jcgpAGIjp"
    : "h4nhc9jcgpAGIjp";
// : 'cmVzdDoxMjM0NTY='; //QA credentials use it when user service pointed to QA

export const USER_SERVICE_TM_AUTH =
  process.env["NODE_ENV"] === "production"
    ? "554DIGG7SWt1EHO"
    : "554DIGG7SWt1EHO";
// : 'tXXXkb1UwVZn3n6'; //QA credentials use it when user service pointed to QA

export const TM_AUTH_EXPIRY_DAYS = 89;

export const MMT_LOGIN_URL = "//www.makemytrip.com/pwa/hlp/v3/login";

export const MMT_SUB_DOMAIN = ".makemytrip.com";

export const GI_SUB_DOMAIN = ".goibibo.com";

export const DEVICE_OS = {
  ANDROID: "android",
  IOS: "ios",
  UNKNOWN: "unknown",
};

export const getListingUrl = () =>
  `${window?.location?.protocol}//${
    window?.location?.hostname
  }/partners/${getPartner()}/forex/listing`;

export const getUserIdentifier = (auth: string, deviceId: string) =>
  `{"ipAddress":"ipAddress","imie":"imie","appVersion":"appVersion","os":"DESKTOP","osVersion":"osVersion","timeZone":"timeZone","type":"mmt-auth","profileType":"","Authorization":"${USER_SERVICE_TOKEN}","deviceId":"${deviceId}","cookie":"${auth}","value":"${auth}"}`;

export const getUserIdentifierForSocialLogin = (
  auth: string,
  deviceId: string
) =>
  `{"type" :"auth","value":"","deviceId":"${deviceId}","os":"android","osVersion":"6.0.0","appVersion":"5.4.1","imie":"132342354","ipAddress":"127.0.0.1","timeZone":"+5.30 GMT"}`;
export const DISCOUNT_BANNER_TEXT =
  "Get upto <b>INR 1000 cashback</b>* on <b>Thai Baht</b> orders over THB 20,000. Valid from 13th to 15th Nov only.";

export const DISCOUNT_BANNER_REDIRECTION_MMT_URL =
  "https://www.makemytrip.com/promos/forex-cashback.html?utm_source=cashback&utm_medium=mlp";

export const DISCOUNT_BANNER_REDIRECTION_GI_URL =
  "https://www.goibibo.com/offers/tripmoney-forex";

export const MMT_FOREX_URL = "https://www.makemytrip.com/forex";

export const IS_INITOTU_SESSION_KEY = "is_initotu";

export const MMT_URL = "https://www.makemytrip.com";

export const UTM_PARAMS_KEY = {
  UTM_SOURCE: "utm_source",
  UTM_MEDIUM: "utm_medium",
  UTM_CAMPAIGN: "utm_campaign",
  UTM_CONTENT: "utm_content",
  UTM_LOGIN: "utm_login",
  UTM_LENDER: "utm_lender",
  UTM_FLOW: "utm_flow",
};

export const UTM_SOURCE_VALUE = {
  BOTTOM_BAR: "bottombar",
};

export const SUB_DOMAIN = {
  MAKEMYTRIP: ".makemytrip.com",
  GOIBIBO: ".goibibo.com",
  TRIPMONEY: ".tripmoney.com",
  LOCALHOST: "localhost",
};

export const PROFILE_URL = {
  MMT_PWA: "https://www.makemytrip.com/pwa/hlp/v3/accountDetails/",
  MMT_DT: "https://www.makemytrip.com/my-profile/",
  GI: "https://www.goibibo.com/profile/",
};

export const CARD_TO_ACTION_MAPPING: Record<string, string> = {
  "Multicurrency Card": "multicurrency_card_clicked",
  "Foreign Currency Notes": "currency_notes_clicked",
};
export const CARD_TO_ACTION_RESUME_MAPPING: Record<string, string> = {
  "Multicurrency Card": "multicurrency_card_resume_clicked",
  "Foreign Currency Notes": "currency_notes_resume_clicked",
};
export const CARD_TO_ACTION_QUICKLOAD_MAPPING: Record<string, string> = {
  "Multicurrency Card": "multicurrency_quick_load_button_clicked",
  "Global Cash Card": "inr_quick_load_button_clicked",
};

export const getSessionPiiDataOpts = { isPiiData: true, shouldParseJson: true };
export const setSessionPiiDataOpts = {
  isPiiData: true,
  shouldStringifyJson: true,
};

export const MMT_LISTING_PAGE_REQ_BODY = {
  query: {
    mandatory: {
      forex_rates: {
        filter: {
          mode: FOREX_RATES_FILER_MODE.TOP,
        },
      },
      products: {
        inr_card: {
          persuasions: true,
          delivery_details: false,
        },
        multi_cx_card: {
          persuasions: true,
          delivery_details: false,
          cta: true,
        },
        currency: {
          cta: true,
          persuasions: true,
          delivery_details: false,
        },
      },
      persona: true,
      offer_persuasions: true,
      recommended_currencies_ticker: true,
    },
  },
  data: {},
};

export const REDIRECTION_FLOW = {
  OLD: VERSIONS.V1,
  NEW: VERSIONS.V2,
};

export const POKUS_EXP_VERSION = {
  MMT: {
    OLD: 7214,
    NEW: 7215,
  },
  GI: {
    OLD: 1901,
    NEW: 1902,
  },
};

export const CN_UPLOAD_DOC_REDIRECTION = {
  CN_ORDER_LISTING: "history",
  CN_UPLOAD_DOC: "specific_order",
};

export const BANNER_TYPE = {
  CAMPAIGN: "campaign",
  MARKETING: "marketing",
};

export const DEVICE_ID_BRIDGE_STATE = {
  AVAILABLE: "AVAILABLE",
  NOT_AVAILABLE: "NOT_AVAILABLE",
  NON_FUNCTIONAL: "NON_FUNCTIONAL",
};

export const LOB_FUNNEL_TYPE = {
  BUY: "Buy",
  POSTSALE: "PostSale",
  LISTING: "Listing",
};

export const LOB_CATEGORY = {
  PC: "PC",
  CN: "CN",
};

export const LOB = {
  FOREX: "forex",
};

export const shortenHeader: any = {
  lob: "lob",
  lob_category: "lc",
  lob_funnel: "lf",
  page_name: "pn",
  prev_page_name: "ppn",
  funnel_step: "fs",
  pg_time_interval: "pti",
  navigation: "nav",
  utm_source: "us",
  utm_medium: "um",
  utm_campaign: "uc",
  cmp: "cmp",
  requestId: "rq-id",
};
export enum DATE_FORMATS {
  "DDD" = "ddd",
  "YY" = "yy",
  "YYYY" = "yyyy",
  "D MMM" = "d MMM",
  "DD/MM/YYYY" = "dd/MM/yyyy",
  "DD MMM YY" = "dd MMM'yy",
  "dd MMM, yyyy" = "dd MMM, yyyy",
  "YYYY-MM-DD" = "yyyy-MM-dd",
}

export enum PROGRAM_DETAILS_ENUM {
  "FREE_CARD" = "free_card_flow",
}

export const excludePersuasionList = ["free_card"];

export const IMAGE_URLS = {
  ZERO_PAYMENT_FREE_CARD_IMAGE:
    "https://tripmoneycmsimgak.mmtcdn.com/img/free_Card_36c13af5e6.png",
};
