import { useDevice } from "libs/ui-widgets/src/lib/hooks/useDevice";
import { cardCtaProps } from "../../../mockData";
import { RightButtonContainer } from "../LandingCardFeature.style";
import AnchorTag from "../../ui-atoms/TextStyles/AnchorTag";
import ImageTag from "../../ui-atoms/ImageTag/ImageTag";
import { ArrowIcon } from "../../../icons/ArrowIcon";
import FlexComp from "../../ui-atoms/FlexComp/FlexComp";
import LandingButton from "../../ui-atoms/LandingButton/LandingButton";
import SpanTag from "../../ui-atoms/TextStyles/SpanTag";

type CardCtaPersuasionProps = {
  leftBtnClickHandler?: (e: any) => void;
  rightBtnClickHandler?: (e: any) => void;
  cardData: cardCtaProps;
  id?: string;
  arrowIconSrc?: string;
  bestRateApplicableTxt?: string;
};

export default function CardCtaPersuasion({
  rightBtnClickHandler,
  leftBtnClickHandler,
  cardData,
  id,
  arrowIconSrc,
  bestRateApplicableTxt,
}: CardCtaPersuasionProps) {
  const device = useDevice();

  const arrowIcon = arrowIconSrc ? (
    <ImageTag src={arrowIconSrc} />
  ) : (
    <ArrowIcon />
  );

  if (device === "lg") {
    return (
      <RightButtonContainer
        onClick={(e: any) => {
          e.stopPropagation();
          rightBtnClickHandler && rightBtnClickHandler(e);
        }}
      >
        <AnchorTag id={id} width="fit-content" fontSize="sm" fontWeight="bold">
          {cardData?.linkBtnInMobile?.label}
        </AnchorTag>

        {arrowIcon}
      </RightButtonContainer>
    );
  }

  return (
    <FlexComp
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      padding="16px 0px 0px"
    >
      {!!cardData?.addText && (
        <AnchorTag
          id={"ListingPage_load_currency_btn"}
          width="fit-content"
          fontSize={"xs"}
          fontWeight="bold"
          margin={"0px"}
          handleClick={(e: any) =>
            leftBtnClickHandler && leftBtnClickHandler(e)
          }
        >
          {cardData.addText}
        </AnchorTag>
      )}
      {device === "sm" && bestRateApplicableTxt && (
        <FlexComp
          bgColor="linear-gradient(90deg, #ECEAF8 0%, rgba(255, 255, 255, 0) 93.75%)"
          padding="4px"
          margin="0 0 0 -16px"
        >
          <SpanTag color="#3A27B0" fontSize="xs">
            {bestRateApplicableTxt}
          </SpanTag>
        </FlexComp>
      )}

      {cardData?.isPrimaryButton && !!cardData?.linkBtnInMobile ? (
        <LandingButton
          customTitle={cardData?.linkBtnInMobile?.label}
          margin="0px 0px 0px auto"
          onClick={(e?: any) => {
            e.stopPropagation();
            rightBtnClickHandler && rightBtnClickHandler(e);
          }}
          fontSize="12px"
          customHeight="32px"
          id={id}
        />
      ) : (
        !!cardData?.linkBtnInMobile && (
          <AnchorTag
            id={id}
            width="fit-content"
            fontSize={"xs"}
            fontWeight="bold"
            margin={"0px 0 0 auto"}
            handleClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              e.stopPropagation();
              rightBtnClickHandler && rightBtnClickHandler(e);
            }}
          >
            {cardData?.linkBtnInMobile?.label}
          </AnchorTag>
        )
      )}
    </FlexComp>
  );
}
