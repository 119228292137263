import { createGlobalStyle } from "styled-components";

export const CurrencyConverterPageStyle = createGlobalStyle`

html,
body {
  background: #f6f6f6;
  padding: 0;
  margin: 0;
  color: #4a4a4a;
  font-size: 14px;
  font-family: lato;
  font-weight: 400;
}
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  list-style: none;
  padding: 0;
}
a {
  color: #008cff;
  text-decoration: none;
  cursor: pointer;
}
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}
img {
  max-width: 100%;
}


/* Global */
.mt10{
    margin-top: 10px;
}
.mt20{
    margin-top: 20px;
}
.h2{
    font-size: 36px;
    line-height: 44px;
    font-weight: 900;
    color: #000;
}
.h3{
    font-size: 24px;
    line-height: 40px;
    font-weight: 900;
    color: #000;
}
.description{
    font-size: 18px;
    line-height: 24px;
    color: #000;
}
.description span{
    font-weight: 900;
}

header{
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 18px 0;
}
.headerInner{
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header__right{
    display: flex;
    align-items: center;
    margin-left: auto;
}
.topNav{
    display: flex;
}
.topNav li{
    margin-right: 30px;
}
.topNav li a{
    color: #fff;
}
.loginBtn{
    display: inline-flex;
    border-radius: 30px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 700;
    color: #008cff;
    outline: 0;
    border: 0;
    cursor: pointer;
    font-family: 'Lato';
}
/* Top Section */
.heroSection{
    background: #000;
}
.heroSection__inner{
    max-width: 1200px;
    margin: 0 auto;
    // background: url('../assets/hero-bg.png') top right no-repeat;
    background-size: 557px 155px;
    padding: 40px 0 80px 0;
}
.breadCrumb{
    display: flex;
}
.breadCrumb li{
    font-size: 14px;
    font-weight: 700;
}
.breadCrumb li::after{
    content: "";
    display: inline-block;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    padding: 2px;
    transform: rotate(-45deg);
    margin: 0 10px;
}
.breadCrumb li:last-child:after{
    display: none;
}
.breadCrumb li a{
    color: #fff;
}
.breadCrumb li:last-child a{
    pointer-events: none;
}
.heroContent{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 75px;
}
.heroContent__left{
    width: 460px;
    flex-shrink: 0;
}
.heroContent__left h1{
    font-size: 45px;
    line-height: 52px;
    font-weight: 900;
    color: #fff;
}
.heroContent__left .h1 {
    font-size: 45px;
    line-height: 52px;
    font-weight: 900;
    color: #fff;
}

h1.inline {
    display: inline;
}
.heroContent__left p{
    font-size: 22px;
    line-height: 30px;
    font-weight: 700;
    color: #fff;
    margin-top: 5px;
}

.hero_cashback {
    margin-top: 35px;
    padding: 10px 24px;
    background-image: linear-gradient(to bottom, #f2fffc, #dbfff4);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    width: 100%;
}

.hero_cashback img {
    height: 44px;
    width: 44px;
}

.hero_cashback_text p {
    font-size: 20px;
    color: #000;
    font-weight: 400;
}

.heroContent_container {
 max-width: 390px;
}

.exchange{
    background-color: #3d3d3d;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 22px;
    color: #85e5be;
    display: inline-flex;
    margin-top: 25px;
}
.exchange span{
    font-weight: 900;
}
.heroContent__right{
    width: 560px;
    flex-shrink: 0;
    background-color: #fff;
    padding: 25px;
    border-radius: 12px;
}
.heroContent__title{
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 30px;
    font-weight: 400;
    color: #000;
}
.heroContent__option{
    display: flex;
}
.heroContent__option .radioBox:first-child{
    margin-right: 40px;
}
.optionText{
    font-size: 12px;
    font-weight: 700;
    color: #757575;
    text-transform: uppercase;
}
.radioBox [type="radio"]:checked + label.optionText{
    color: #008cff;
}
.inputContainer{
    display: flex;
    margin-top: 20px;
}
.inputContainer__col{
    flex: 1;
}
.inputContainer__separator{
    flex-shrink: 0;
    margin: 0 13px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.form-group {
    position: relative;
}
.form-group input {
    width: 100%;
    padding: 18px 12px 10px 12px;
    border: 1px solid #e2e2e2;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 700;
    font-family: 'Lato';
    color: #000000;
    background-color: #f6f6f6;
    transition: border-color 0.3s ease;
}
.form-group label {
    position: absolute;
    left: 12px;
    top: 17px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: #757575;
    transition: top 0.3s ease, font-size 0.3s ease, color 0.3s ease;
}
.form-group input:focus {
    outline: none;
}
.form-group input:focus + label,
.form-group input:not(:placeholder-shown) + label {
    top: 5px;
}
.btnSubmit{
    background-color: #280e0e;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 900;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Lato';
    outline: 0;
    border: 0;
    cursor: pointer;
    margin-top: 30px;
    width: 100%;
}


.floatingSelect {
    position: relative;
    margin-top: 25px;
}
.floatingSelect::after {
    content: "";
    border: solid #008cff;
    border-width: 0 2px 2px 0;
    padding: 3px;
    position: absolute;
    right: 15px;
    top: 17px;
    transform: rotate(45deg);
    pointer-events: none;
}

.floatingSelect__label {
    color: #757575;
    font-size: 12px;
    font-weight: 700;
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 16px;
    text-transform: uppercase;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.floatingSlctBox {
    font-size: 12px;
    display: block;
    font-weight: 700;
    font-family: 'Lato';
    width: 100%;
    height: 48px;
    padding: 14px 12px 0 12px;
    border-radius: 2px;
    background-color: #f6f6f6;
    border: 1px solid #e2e2e2;
    outline: 0;
    box-sizing: border-box;
}
.floatingSlctBox:focus {
    outline: none;
}
.floatingSlctBox:focus ~ .floatingSelect__label {
    top: 5px;
}

select.floatingSlctBox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

select.floatingSlctBox::-ms-expand {
    display: none;
}

.floatingSlctBox:not([value=""]):valid ~ .floatingSelect__label {
    top: 5px;
}

.floatingSlctBox[value=""]:focus ~ .floatingSelect__label {
    top: 5px;
}

.pageContainer{
    max-width: 1200px;
    margin: 56px auto;
}
.getForex{
    padding: 56px 24px 44px;
    border-radius: 10px;
    background-color: #fff;
}
.getForex__title{
    margin-bottom: 40px;
}
.getForex__title .big{
    font-size: 18px;
    color: #000;
    font-weight: 700;
    margin-right: 10px;
}
.getForex__title .small{
    font-size: 18px;
    color: #4a4a4a;
    font-weight: 400;
}
.getForex__title .medium{
    font-size: 18px;
    color: #2b825e;
    font-weight: 900;
}
.getForex__list{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}
.getForex__listItem{
    border: solid 1px #e2e2e2;
    padding: 10px 13px;
    border-radius: 4px;
    display: flex;
    cursor: pointer;
}
.getForex__listItemImg{
    width: 140px;
    flex-shrink: 0;
    margin-right: 10px;
}
.getForex__listItemDesc{
    flex: 1;
}
.getForex__listItemDesc h3.title{
    font-size: 18px;
    font-weight: 900;
    color: #000;
    display: flex;
    align-items: center;
}
.getForex__listItemDesc p.title{
    font-size: 18px;
    font-weight: 900;
    color: #000;
    display: flex;
    align-items: center;
}
.getForex__listItemDesc h3.title .arrow{
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 10px;
    position: relative;
}
.getForex__listItemDesc h3.title .arrow::after{
    content: "";
    display: inline-block;
    border: solid #008cff;
    border-width: 0 2px 2px 0;
    padding: 3px;
    transform: rotate(-45deg);
    position: absolute;
    right: 2px;
    top: 4px;
}
.getForex__listItemDesc h3.title .arrow::before{
    content: "";
    display: inline-block;
    width: 10px;
    height: 2px;
    background-color: #008cff;
    position: absolute;
    right: 3px;
    top: 7px;
}
.getForex__listItemDesc p.desc{
    font-size: 14px;
    line-height: 22px;
    margin-top: 10px;
}
.getForex__listItemDesc p.desc span{
    position: relative;
    padding: 0 5px 0 10px;
}
.getForex__listItemDesc p.desc span::after{
    width: 4px;
    height: 4px;
    background-color: #2b825e;
    content: "";
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 8px;
}
.getForex__listItemDesc p.desc span:first-child::after{
    display: none;
}
.getForex__listItemDesc p.desc span:first-child{
    padding: 0 5px 0 0;
}
.deliveryBy{
    font-size: 12px;
    font-weight: 700;
    color: #2b825e;
    margin-top: 8px;
    display: none;
    align-items: center;
}
.deliveryBy img{ width: 8px; flex-shrink: 0; margin-right: 5px;}
.pageContent{
    margin: 35px auto;
}
.offer_banner{
    margin-bottom: 36px;
    cursor: pointer;
}
.offer_banner .offer_bannerDt {
    width: 100%;
    height: 253px;
    object-fit: contain;
}
.offer_banner .offer_bannerMobile {
    width: 100%;
    height: 340px;
    object-fit: contain;
    display: none;
}
.planTrip{
    margin-bottom: 28px;
}
.planTrip p{
    font-size: 18px;
    line-height: 24px;
    color: #000;
}
.planTrip p span{
    font-weight: 900;
}
.separator{
    height: 1px;
    background-color: #979797;
    margin: 50px 0;
}
.convertCurrency{
    padding: 48px 60px;
    border-radius: 8px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.22);
    background-color: #fff;
    margin-top: 30px;
}
.ccList__item{
    border-bottom: 1px #c7c7c7 dashed;
    padding: 36px 25px;
}
.ccList__item:first-child{
    padding-top: 0;
}
.ccList__item:last-child{
    padding-bottom: 0;
    border-bottom: 0;
}
.ccList__item h4.heading{
    font-size: 24px;
    line-height: 26px;
    color: #2f2f2f;
    font-weight: 900;
    position: relative;
}
.ccList__item p.heading{
    font-size: 24px;
    line-height: 26px;
    color: #2f2f2f;
    font-weight: 900;
    position: relative;
}
.ccList__item p.heading::before{
    content: "";
    width: 7px;
    height: 19px;
    border-radius: 4px;
    background-image: linear-gradient(to bottom, #b68f38, #e5cf8b);
    position: absolute;
    left: -25px;
    top: 4px;
}
.ccList__item div.desc{
    font-size: 18px;
    line-height: 24px;
    color: #000;
    margin-top: 13px;
}
.ccList__item p.desc span{
    font-weight: 900;
}
.benifitsList{
    margin-top: 20px;
}
.benifitsList li{
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
    color: #000;
    padding-left: 25px;
    position: relative;
}
.benifitsList li span{
    font-weight: 900;
}
.benifitsList li::before{
    content: "";
    width: 7px;
    height: 15px;
    border-radius: 4px;
    background-image: linear-gradient(to bottom, #b68f38, #e5cf8b);
    position: absolute;
    left: 0;
    top: 5px;
}

.forexServices{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
    margin-top: 20px;
}
.forexServices__item{
    border-radius: 8px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.22);
    background-color: #fff;
    padding: 25px;
}
.forexServices__itemHdr{
    display: flex;
    margin-bottom: 20px;
}
.forexServices__itemImg{
    flex-shrink: 0;
    margin-right: 15px;
}
.forexServices__itemTitle{
    font-size: 28px;
    line-height: 32px;
    max-width: 130px;
    color: #000;
    font-weight: 900;
}

.conversionRate{
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 67px;
}
.conversionTbl{
    border-collapse: collapse;
    width: 100%;
}
.conversionTbl th{
    padding: 20px 40px;
    background-color: #000000;
    font-size: 18px;
    font-weight: 900;
    color: #fff;
    text-align: left;
    border: 1px #979797 solid;
}
.conversionTbl td{
    padding: 20px 40px;
    font-size: 18px;
    color: #000;
    border: 1px #979797 solid;
    background-color: #fff;
}

.conversionTbl td.stateBg{
    background-color: #f6f6f6;
}
.states{
    margin-top: 47px;
}
.capSulesList{
    display: grid;
    margin-top: 30px;
    gap: 15px;
}
.capSulesList.grid5{
    grid-template-columns: repeat(5, 1fr);
}
.capSulesList.grid4{
    grid-template-columns: repeat(4, 1fr);
}
.capSulesList li{
    padding: 19px;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.22);
    background-color: #fff;
    font-size: 16px;
    font-weight: 700;
    color: #005cbc;
    text-align: center;
}
.capSulesList li:nth-child(n+5){
    display: none;
}
.capSulesList.showAll li:nth-child(n+5){
    display: block;
}

.viewMore{
    margin-top: 30px;
}
.viewMore__cta{
    font-size: 16px;
    font-weight: 700;
    color: #005cbc;
}

.separator {
    height: 1px;
    background-color: #979797;
    margin: 30px 0;
}

.faqList{
    margin-top: 50px;
}
.answer{
    display:none;
}
.toggleAnswer .answer{
    display:block;
}
.faqList li h4.question{
    font-size: 24px;
    line-height: 26px;
    font-weight: 900;
    color: #000;
    padding: 30px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    position: relative;
}
.faqList li h4.question::after{
    content: "";
    border: solid #2f2f2f;
    display: inline-block;
    border-width: 0 3px 3px 0;
    padding: 6px;
    transform: rotate(45deg);
    position: absolute;
    right: 40px;
    top: 32px;
}
.faqList li div.answer{
    font-size: 18px;
    line-height: 24px;
    color: #000;
    padding: 0 0 0 30px;
    margin-bottom: 30px;
}
.faqList li div.answer span{
    font-weight: 900;
}
.faqList li:hover h4.question{
    border-radius: 6px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.22);
    background-color: #fff;
}
.faqList li.active h4.question::after{
    transform: rotate(-135deg);
    top: 42px;
}

.currencyList{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    margin-top: 30px;
}
.currencyList li a{
    display: flex;
    border-radius: 6px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.22);
    background-color: #fff;
    padding: 16px 15px;
    align-items: center;
}
.currency__icon{
    width: 65px;
    flex-shrink: 0;
    margin-right: 14px;
}
.currency__text{
    font-size: 16px;
    font-weight: 700;
    color: #005cbc;
    text-decoration: underline;
}
footer{
    background-color: #000;
    padding: 50px 0 30px 0;
}
.footer__cont{
    max-width: 1200px;
    margin: 0 auto;
}
.footer__hdr{
    padding: 3px 0;
    background-color: #4f4f4f;
    font-size: 14px;
    font-weight: 900;
    color: #fff;
    text-align: center;
    margin-bottom: 40px;
}

.footernavCont{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 40px;
}
.footerNavTitle{
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 10px;
}
.footerNavList li{
    margin-bottom: 8px;
}
.footerNavList li a{
    color: #d8d8d8;
    font-size: 12px;
}
.footerBottom{
    border-top: 1px #707070 solid;
    margin-top: 40px;
    padding: 20px 0 0 0;
    text-align: center;
}

.container {
    max-width: 1100px;
    margin: 0 auto;
    font-size: 18px;
    line-height: 22px;
  }
.reviewedCurrencies {
    background-color: #e0e0e0;
    padding: 46px 0;
  }

  .reviewedCurrencies .container {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
  }

  .footerLink{
    padding:6px 0;
  }

  .footerLinkCol{
        margin-left: 26px;
  }

  footer .linkTitle {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 20px;
  }

  footer .container {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    color: #fff;
    font-size: 16px;
  }
  
  .footerLinkCol:first-child{
    margin-left: 0;
  }

  .reviewedCurrencies h3{
    font-size:28px;
    margin-bottom: 16px;
  }

/*-- Radio Box --*/
.radioBox {
    display: inline-flex;
    position: relative;
  }
  .radioBox [type="radio"]:checked,
  .radioBox [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  .radioBox [type="radio"]:checked + label,
  .radioBox [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-flex;
  }
  .radioBox [type="radio"]:checked + label:before,
  .radioBox [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #9b9b9b;
    border-radius: 100%;
    background: #fff;
  }
  .radioBox [type="radio"]:checked + label:before {
    border: 1px solid #008cff;
  }
  .radioBox [type="radio"]:checked + label:after,
  .radioBox [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 12px;
    height: 12px;
    background: #008cff;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .radioBox [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  .radioBox [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }


  /* New Styles - header.html */
 .heroSection__inner.heroPadding {
    padding: 0 16px 200px 16px;
  }

  .heroSection .heroSection_info {
    padding: 38px 0 0;
  }

  .heroPadding h2{
    font-size: 45px;
    line-height: 52px;
    font-weight: 900;
    color: #fff;
  }

  .heroPadding p{
    font-size: 22px;
    line-height: 52px;
    font-weight: 600;
    line-height: 30px;
    color: #fff;
    margin-top: 10px;
  }

  .pageContainer.pageContainer_margin {
    margin: 0 auto;
    margin-top: -170px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    .heroPadding h2{
        font-size: 24px;
        line-height: 28px;
    }
    .heroPadding p{
        font-size: 16px;
        line-height: 20px;
    }
  }


  /* Mobile devices */
@media (min-width: 320px) and (max-width: 767px) {
    body{ background-color: #ffffff;}
    .header__right{ display: none;}
    .headerInner{ justify-content: center; align-items: center;}
    .heroSection__inner{ padding: 0 12px 30px 12px; background-size: 320px 167px;}
    .heroContent{ flex-direction: column; margin-top: 0;}
    .heroContent__left{ width: 100%; display: flex; flex-direction: column;}
    .heroContent__right{ width: 100%; padding: 20px 16px 30px 16px;}
    .heroContent__left h1{ font-size: 28px; line-height: 32px; text-align: center;}
    .heroContent__left .h1{ font-size: 28px; line-height: 32px; text-align: center;}
    .heroContent__left p{ font-size: 14px; line-height: 18px; text-align: center;}
    .exchange{ font-size: 15px; margin-left: auto; margin-right: auto; margin-bottom: 25px; padding: 15px 25px;}
    .hero_cashback {
        margin-bottom: 25px;
        margin-top: unset;
        margin-left: auto; margin-right: auto;
        width: 100%;
    }

    .hero_cashback img {
        height: 30px;
        width: 30px;
    }

    .hero_cashback_text p {
        text-align: left;
        font-size: 16px;
        color: #000;
        font-weight: 400;
    }
    .offer_banner{
        margin-bottom: 30px;
    }
    .offer_banner .offer_bannerDt {
        display: none;
    }
    .offer_banner .offer_bannerMobile {
        display: block;
    }    
    .h2{ font-size: 30px; line-height: 34px;}
    .h3{ font-size: 20px; line-height: 24px;}
    .pageContainer{ padding: 0 12px; margin: 30px auto;}
    .getForex{ box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.16); border: solid 1px #e8e8e8; padding: 25px 12px; border-radius: 12px;}
    .getForex__title{ display: none;}
    .getForex__list{ grid-template-columns: 1fr;}
    .getForex__listItem{ border: 0; padding: 0; border-radius: 0;}
    .getForex__listItem:first-child{ border-bottom: 1px #f2f2f2 solid; padding-bottom: 15px;}
    .getForex__listItemImg{ width: 64px;}
    .getForex__listItemImg img{ width: 64px; height: unset;}
    .getForex__listItemDesc p.desc{ margin-top: 5px; font-size: 12px; line-height: 18px;}
    .getForex__listItemDesc p.desc span::after{ top: 6px;}
    .getForex__listItemDesc p.title .arrow{ margin-left: auto;}
    .deliveryBy{ display: flex;}
    .planTrip p{ font-size: 16px; line-height: 24px;}
    .description{ font-size: 16px; line-height: 24px;}
    .separator{ margin: 30px 0;}
    .convertCurrency{ padding: 30px 20px;}
    .ccList__item p.heading{ font-size: 18px; line-height: 22px;}
    .ccList__item p.desc{ font-size: 16px; line-height: 24px;}
    .ccList__item{ padding: 16px 20px;}
    .benifitsList li{ font-size: 16px;}
    .forexServices{ display: flex; gap: unset; flex-wrap: nowrap; overflow-x: auto; padding: 6px 12px; margin: 20px -12px 0 -12px;}
    .forexServices::-webkit-scrollbar{ display: none;}
    .forexServices__item{ width: 258px; flex-shrink: 0; margin-right: 12px; padding: 20px 20px 20px 29px;}
    .forexServices__itemHdr{ flex-direction: column; margin-bottom: 10px;}
    .forexServices__itemImg{ margin-right: 0;}
    .forexServices__itemImg img{ width: 102px; height: auto;}
    .forexServices__itemTitle{ max-width: unset; font-size: 18px; line-height: 22px; margin-top: 10px;}
    .conversionRate{ grid-template-columns: 1fr; gap: 26px; margin-top: 30px;}
    .conversionTbl th{ padding: 14px 25px; }
    .conversionTbl td{ padding: 14px 25px; font-size: 16px;}
    .states{ margin-top: 30px;}
    .capSulesList.grid5, .capSulesList.grid4{ grid-template-columns: repeat(2, 1fr); gap: 10px;}
    .currencyList{ grid-template-columns: repeat(2, 1fr); gap: 10px;}
    .capSulesList{ margin-top: 20px;}
    // .capSulesList li:nth-child(n+5), .currencyList li:nth-child(n+5){display: none;}
    .viewMore{ text-align: center; margin-top: 20px;}
    .faqList li{ border-radius: 6px; box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.22); background-color: #fff; padding: 20px 10px; margin-bottom: 40px;}
    .faqList li:last-child{ margin-bottom: 0;}
    .faqList li:hover h4.question{ border-radius: unset; box-shadow: unset; background-color: unset;}
    .faqList li h4.question{ padding: 0 60px 0 0; font-size: 18px; line-height: 22px;}
    .faqList li h4.question::after{ padding: 4px; right: 10px; top: 5px;}
    .faqList li.active h4.question::after{ top: 10px;}
    .faqList li div.answer{ margin-bottom: unset; padding: 10px 0 0 0; font-size: 16px;}
    .faqList li.active{ box-shadow: unset;}
    .pageContent{ margin-bottom: 0;}
    footer{ padding: 30px 12px;}
    .footernavCont{ grid-template-columns: repeat(2, 1fr);}
    .footerBottom{ text-align: left;}

    footer .container{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        width: 100%;
        padding: 0 16px;
      }
      footer .item{
        margin-bottom: 50px;
      }
      .reviewedCurrencies{
        padding: 30px 16px;
      }
      .reviewedCurrencies .container{
        flex-direction: column;
      }
  
      .reviewedCurrencies h3{
        margin-bottom: 0;
        line-height:1.3;
      }
  
      .footerLinkCol{
        margin-left:0;
      }

      footer {
        background-color: #16223e;
        padding: 46px 0 46px;
      }
      
      footer .container {
        margin-top: 0;
        color: #fff;
        font-size: 16px;
      }
      
      footer a {
        font-size: 16px;
        color: #fff;
      }
      
      footer .linkTitle {
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 20px;
      }

        .breadcrumb_container{
            display:flex;
            justify-content:center;
            align-items:center;
        }
}

/* Ipad and above */
@media (min-width: 768px) and (max-width: 1280px) {
    header{ padding: 10px 16px;}
    .heroSection{ padding: 0 16px;}
    .heroContent__left{ width: 48%;}
    .heroContent__right{ width: 48%;}
    .pageContainer{ margin: 30px auto; padding: 0 16px;}
    .convertCurrency{ padding: 20px 30px;}
    .forexServices__itemHdr{ flex-direction: column;}
    .conversionRate{ gap: 30px;}
    .capSulesList.grid5, .capSulesList.grid4, .currencyList{ grid-template-columns: repeat(3, 1fr);}
    footer{ padding: 30px 16px;}
    .faqList li h4.question{ padding-right: 50px;}
}


`;
