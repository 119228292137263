// @ts-ignore
import { initAndGetAnalyticsSdk } from "@mmt/event-logger";
import { getEventDetails, getVendorErrorDetails } from "./pdtutils";
import { generateRequestId, getHostName, isOnServer } from "..";
import { HOST, LOB, PARTNER } from "../../constants";
import { getCommonPdtDataV2 } from "./getCommonPdtDataV2";
import PdtSchemaUpdater from "./PdtSchemaUpdater";
import {
  IEventDetailInput,
  IEventTrackingContext,
  IPageContextInput,
} from "./types";

export interface IPdtDataV2 {
  event_detail: IEventDetailInput;
  page_context?: IPageContextInput;
  event_tracking_context?: IEventTrackingContext;
  user_id?: string | null;
  partner_id?: string | null;
  funnelType?: string | null;
  category?: string | null;
}

const AnalyticsSdk = initAndGetAnalyticsSdk({});

export const getPartner = (): string | null => {
  if (isOnServer()) return null;
  const host = getHostName() || "";
  if ([HOST.MAKEMYTRIP, HOST.TRIPMONEY_MAKEMYTRIP].includes(host)) {
    return PARTNER.MMT;
  } else if ([HOST.GOIBIBO, HOST.TRIPMONEY_GOIBIBO].includes(host)) {
    return PARTNER.GI;
  }
  return null;
};

export const getPdtLoggerMethods = ({
  lobFunnelType,
  userId,
  puid,
  lobCategory,
  pdtEvents,
  funnelStep,
}: {
  lobFunnelType: string;
  userId?: string | null;
  puid?: string | null;
  lobCategory?: string | null;
  pdtEvents: Record<string, string>;
  funnelStep?: string;
}) => {
  const partner = getPartner();

  const sendCTAData = ({
    pdtData = {},
    eventDetail,
    event_tracking_context,
  }: {
    eventDetail: IEventDetailInput;
    pdtData?: any;
    event_tracking_context?: IEventTrackingContext;
  }) => {
    try {
      pdtData = getCommonPdtDataV2({
        partner,
        userId,
        puid,
        lobFunnelType,
        lobCategory,
        funnelStep,
      });
      AnalyticsSdk.setRuntimeContextProvider({
        getUserContext: () => {
          return pdtData.user_context;
        },
        getDeviceContext: () => {
          return pdtData.device_context;
        },
      });
      const pdtSchemaUpdater = new PdtSchemaUpdater(pdtData);

      const data = pdtSchemaUpdater
        .mergeEventDetailsData(eventDetail)
        .mergeEventTrackingContext({
          ...event_tracking_context,
          request_id: event_tracking_context?.request_id ?? generateRequestId(),
        } as IEventTrackingContext)
        .getSchema();
      updateEventName(data.event_detail, pdtEvents);
      AnalyticsSdk.track(data, LOB.FOREX);
    } catch (e: any) {
      console.error(e?.message);
    }
  };

  const sendPdtDataV2 = ({
    event_detail,
    page_context,
    event_tracking_context,
    user_id,
    partner_id,
    funnelType,
    category,
  }: IPdtDataV2) => {
    try {
      const commonPdtData = getCommonPdtDataV2({
        partner,
        userId: user_id ?? userId,
        puid: partner_id ?? puid,
        lobFunnelType: funnelType ?? lobFunnelType,
        lobCategory: category ?? lobCategory,
        funnelStep,
      });
      AnalyticsSdk.setRuntimeContextProvider({
        getUserContext: () => {
          return commonPdtData.user_context;
        },
        getDeviceContext: () => {
          return commonPdtData.device_context;
        },
      });
      const pdtSchemaUpdater = new PdtSchemaUpdater(commonPdtData);
      const data = pdtSchemaUpdater
        .mergeEventDetailsData(event_detail)
        .mergePageContextData(page_context)
        .mergeEventTrackingContext({
          ...event_tracking_context,
          request_id: event_tracking_context?.request_id ?? generateRequestId(),
        } as IEventTrackingContext)
        .getSchema();
      updateEventName(data.event_detail, pdtEvents);
      AnalyticsSdk.track(data, LOB.FOREX);
    } catch (e) {
      console.error("sendPdtDataV2: ", e);
    }
  };

  return {
    sendCTAData,
    sendPdtDataV2,
  };
};

export function sendPdtErrorEvent({
  appErrorReducer,
  sendPdtDataV2,
}: {
  appErrorReducer: any;
  sendPdtDataV2: (data: IPdtDataV2) => void;
}) {
  try {
    const { apiUrl } = appErrorReducer || {};
    const errorMessage =
      appErrorReducer?.pdtInfo?.errorDescription ||
      appErrorReducer?.errorTitle ||
      appErrorReducer?.errorDescription ||
      appErrorReducer?.message;

    if (!errorMessage) {
      return;
    }

    sendPdtDataV2({
      event_detail: getEventDetails({
        event_name: "NA",
        vendor_error_details: getVendorErrorDetails({
          error_code:
            appErrorReducer?.pdtInfo?.code ||
            appErrorReducer?.code ||
            "NO_CODE_FOUND",
          error_message_shown: errorMessage,
          api_url: apiUrl,
        }),
      }),
    });
  } catch (e) {
    console.error("sendPdtErrorEvent fn ", e);
  }
}

function updateEventName(
  eventDetail: IEventDetailInput,
  pdtEvents: Record<string, string>
) {
  const eventName = eventDetail?.event_name || "";
  if (eventName) {
    eventDetail.event_name = pdtEvents[eventName] || eventName;
  }
}
