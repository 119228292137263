import { useGlobalConfig } from "../useGlobalConfig";
import { fetchApiForexListing } from "../../api/fetchApi";
import {
  DEFAULT_MX_FLOW_VERSION,
  ERROR_CODE,
  ERROR_MSGS,
  HOST,
} from "../../constants";
import {
  fetchingListingPageOnLocationChange,
  loadingListingPageOnLocationChange,
} from "../../store/action";

import { useGlobalDispatch } from "../useGlobalDispatch";
import { useGlobalSelector } from "../useGlobalSelector";
import {
  getContextUrl,
  getHostName,
  getRequestIdHeaders,
  setDefaultForexCardsData,
  setJourneyIdInCookie,
} from "../../utils";
import { getForexRatesFilterInfo } from "../useFetchForexRates";
import { ListingReducerType } from "../../store/forexListingReducer";
import { DeliveryDetails, ForexRates } from "../../types";
import { useDevice } from "../useDevice";
import { useListingPdtLogger } from "../usePdtLogger";
import useListingPdtLoggerV2 from "../useListingPdtLoggerV2";

const useFetchListingDetails = () => {
  const { partner } = useGlobalConfig();
  const device = useDevice();
  const dispatch = useGlobalDispatch();
  const { forex_rates } = useGlobalSelector(
    (state: ListingReducerType) => state || {}
  );
  const { sendPdtRateTickerViewEvent, sendPdtListingError } =
    useListingPdtLogger();

  const { sendPdtListingErrorV2 } = useListingPdtLoggerV2();

  const fetchListingDetailsOnLocationChange = async (
    request_id?: string | null,
    delivery_details?: DeliveryDetails,
    options?: {
      reqId: string;
    }
  ) => {
    const contextUrl = getContextUrl(partner);
    try {
      const { reqId } = options || {};
      dispatch(loadingListingPageOnLocationChange());
      const data = getBody(
        delivery_details || {},
        request_id,
        forex_rates,
        device
      );
      let headers = {};
      if (getHostName() !== HOST.MAKEMYTRIP) {
        headers = {
          ...getRequestIdHeaders(reqId),
        };
      }
      const response = await fetchApiForexListing(contextUrl, {
        method: "POST",
        data,
        headers,
      });
      if (response?.success) {
        dispatch(fetchingListingPageOnLocationChange(response?.data));

        sendPdtRateTickerViewEvent(
          response?.data?.forex_rates,
          response?.data?.tm_user_id,
          response?.data?.puid
        );

        setJourneyIdInCookie({
          partner,
          journeyId: response?.data?.["x-jo-id"],
        });

        return {
          listingData: response.data,
        };
      } else if (!response?.success) {
        sendPdtListingError(
          response?.error,
          ERROR_MSGS.PRODUCT_LISTING_API_ERROR
        );
        sendPdtListingErrorV2(
          response?.error,
          ERROR_MSGS.PRODUCT_LISTING_API_ERROR
        );
        dispatch(
          fetchingListingPageOnLocationChange({
            // TODO: Set default version according to the other application releases.
            version: DEFAULT_MX_FLOW_VERSION,
            products: setDefaultForexCardsData(),
          })
        );
        return response?.error;
      }
      const products = response?.data?.products;
      // Set default products if api doesn't return forex products list.
      if (!products || !products.length) {
        sendPdtListingError(response?.error, ERROR_MSGS.PRODUCT_LIST_ERROR);
        sendPdtListingErrorV2(response?.error, ERROR_MSGS.PRODUCT_LIST_ERROR);
        dispatch(
          fetchingListingPageOnLocationChange({
            // TODO: Set default version according to the other application releases.
            version: DEFAULT_MX_FLOW_VERSION,
            products: setDefaultForexCardsData(),
          })
        );
        return response?.data;
      }
    } catch (e: any) {
      const error = {
        apiUrl: contextUrl,
        code: e?.message?.includes(ERROR_MSGS.FETCH_ERROR)
          ? ERROR_CODE.NETWORK
          : "",
        description: e?.message?.includes(ERROR_MSGS.FETCH_ERROR)
          ? ERROR_MSGS.FETCH_ERROR
          : e?.message,
      };
      sendPdtListingError(error);
      sendPdtListingErrorV2(e, ERROR_MSGS.LISTING_API_FAIL);
      // Set default product if api fails for any reason.
      dispatch(
        fetchingListingPageOnLocationChange({
          // TODO: Set default version according to the other application releases.
          version: DEFAULT_MX_FLOW_VERSION,
          products: setDefaultForexCardsData(),
        })
      );
      console.error(e);
    }
  };
  return {
    fetchListingDetailsOnLocationChange,
  };
};

export default useFetchListingDetails;

function getBody(
  delivery_details: DeliveryDetails,
  request_id?: string | null,
  forex_rates?: ForexRates | null,
  device?: string | null
) {
  const filter = getForexRatesFilterInfo(forex_rates);
  return {
    query: {
      request_id,
      mandatory: {
        forex_rates: {
          filter,
        },
        products: {
          inr_card: {
            delivery_details: device === "sm" ? true : false,
            persuasions: true,
          },
          multi_cx_card: {
            cta: true,
            delivery_details: device === "sm" ? true : false,
            persuasions: true,
            card_cta: true,
            program_details: true,
          },
          currency: {
            cta: true,
            delivery_details: device === "sm" ? true : false,
            persuasions: true,
          },
        },
        persona: true,
        offer_persuasions: true,
        recommended_currencies_ticker: true,
      },
    },
    data: {
      location: {
        ...delivery_details,
      },
    },
  };
}
