import {
  ActivationInProgressPersuasionData,
  ActivationInProgressPersuasionProps,
  MiniDashboardPersuasionProps,
  PersuasionCTA,
  PersuasionData,
  PersuasionType,
  ProductType,
  ReloadTripPersuasionProps,
  ReloadTripPerusasionData,
  StartOrderPersuasionData,
  StartOrderPersuasionProps,
  UndeliverablePersuasionData,
  UndeliverablePersuasionProps,
  UpcomingTripsPersuasionData,
  UpcomingTripsPersuasionProps,
} from "./../types/index";
import { defaultForexCardsData } from "../constants/defaultCardData.ts";
import { PERSUASION_TYPE, USER_TYPE, HOST, VERSIONS } from "../constants";
import { landingMarqueeDataProps } from "../static/mockData";
import { getFutureDate } from "../static/utils";
import {
  BackendPersuasionData,
  CardType,
  DeliveryDetails,
  DeliveryDetailsContent,
  DeliveryDetailsPersuasionData,
  DropOffPersuasionData,
  ForexProduct,
  ForexProducts,
  ForexRates,
  PersonaType,
  LocationFetchingProps,
  MiniDashboardPersuasionData,
  PersuationProps,
  ProductData,
  UploadDocsPersuasaionData,
  ViewOrdersPersuasaionData,
} from "../types";
import { getHostName } from ".";
import { CrossSellDataType } from "../store/forexListingReducer";
import { FreeCardOfferProps } from "../static/components/LandingCardFeature/Persuasions/ProductOffers";

export function getTickerData(forex_rates?: ForexRates | null) {
  const data: landingMarqueeDataProps[] = [];
  for (const [key, value] of Object.entries(forex_rates || {})) {
    data.push({
      id: key,
      text: `₹${value}`,
      boldText: key.toUpperCase(),
    });
  }
  if (data.length > 0) {
    data.push({
      id: "100_currencies_text",
      blueText: "100+ Currencies",
    });
  }
  return data;
}

export function getDeliverableText(
  eta_in_days: number,
  city: string | null | undefined,
  pincode: number | undefined | null,
  version: number | null | undefined
) {
  const ctaText =
    version === VERSIONS.V3
      ? `<span class='regular blackText'>in</span> ${city ?? ""} ${
          pincode ?? ""
        }`
      : `<span class='regular greyText9'>in</span> ${city ?? ""} ${
          pincode ?? ""
        }`;

  let datePlaceHolder = "";
  const { date, monthName, suffix, day } = getFutureDate(Number(eta_in_days));
  const formattedDate = `${date} ${monthName}`;

  if (Number(eta_in_days) >= 0) {
    datePlaceHolder =
      Number(eta_in_days) === 0
        ? `Today, 9 pm`
        : Number(eta_in_days) === 1
        ? `Tomorrow, 9 pm`
        : `${day}, ${formattedDate}`;
  } else {
    throw new Error("Delivery eta should be greater than or equal to 0");
  }
  const text =
    version === VERSIONS.V3
      ? `<span class="blackText">Delivery by </span> <span id='listingPage_delivery_deliveryEta' class='boldText greenText'>${datePlaceHolder}</span>`
      : `Delivery by <br/> <span id='listingPage_delivery_deliveryEta' class='boldText greenText'>${datePlaceHolder}</span>`;
  return {
    checkPincode: false,
    checkPincodeLabel: "",
    ctaText,
    text,
  };
}
export function getDeliveryDetailsContent(
  deliveryDetails: DeliveryDetails,
  isErrorDeliveryDetails: boolean | null | undefined,
  version: number | null | undefined
) {
  const { city, deliverable, eta_in_days = 0, pincode } = deliveryDetails || {};
  let deliveryContent = {
    ctaText: "",
    text: "",
    checkPincode: false,
    checkPincodeLabel: "",
  };
  if (isErrorDeliveryDetails) {
    return {
      ...deliveryContent,
      checkPincode: true,
      checkPincodeLabel: "Enter pincode to check Delivery Dates",
    };
  }

  if (deliverable) {
    deliveryContent = getDeliverableText(eta_in_days, city, pincode, version);
  } else if (!deliverable) {
    const message = "Enter pincode to check Delivery Dates";
    deliveryContent.checkPincode = true;
    deliveryContent.checkPincodeLabel = message;
    deliveryContent.ctaText = message;
    deliveryContent.text = "";
  }
  return deliveryContent;
}

export const getLocationFetchingScreenLabel = (
  locFetchingMode: LocationFetchingProps,
  deliveryDetailsContent: DeliveryDetailsContent | null,
  persona: PersonaType | null | undefined,
  version: number,
  isErrorDeliveryDetails?: boolean
) => {
  if (persona?.multi_cx_card_cust && version === VERSIONS.V3) {
    return "Welcome back";
  }

  if (isErrorDeliveryDetails) {
    return `Enter pincode to check Delivery Dates`;
  } else {
    if (!deliveryDetailsContent) {
      return null;
    }
    return locFetchingMode === "SUCCESS"
      ? `${
          deliveryDetailsContent?.text ? `${deliveryDetailsContent?.text}` : ""
        } <span class='boldText blueText'>${
          deliveryDetailsContent?.ctaText
        }</span>`
      : `Delivery by <span class='boldText greenText'>Tomorrow*</span> in <span class='boldText blueText'>100+ cities </span>`;
  }
};

export function prepareNonPinnedForexCardsMobile(
  products: ForexProducts,
  device: string | null,
  version: number | null | undefined
) {
  const nonPinnedForexCards: CardType[] = [];
  for (const product of products) {
    for (const [productKey, productValue] of Object.entries(product)) {
      const { render_separately } = productValue || {};
      if (!render_separately) {
        let card: CardType = {
          ...defaultForexCardsData[productKey],
          ...productValue,
        };
        if (version) {
          card.version = version;
        }
        // Not showing subLabel for mobile.
        card.subLabel = "";
        card = setForexCardPersuasions(card, productValue);
        card = setUnavailableCardContent(card, productValue);

        nonPinnedForexCards.push(card);
      }
    }
  }
  return nonPinnedForexCards;
}
export function prepareForexCardsForDesktop(
  products: ForexProducts,
  device: string | null,
  version: number | null | undefined
) {
  const forexCards: CardType[] = [];
  for (const product of products) {
    for (const [productKey, productValue] of Object.entries(product)) {
      let card: CardType = {
        ...defaultForexCardsData[productKey],
        ...productValue,
      };
      card = setForexCardPersuasions(card, productValue);
      card = setUnavailableCardContent(card, productValue);
      if (version) {
        card.version = version;
      }
      if (device === "lg") {
        card.isUnavailableAtLoc = null;
      }
      forexCards.push(card);
    }
  }
  return forexCards;
}

export function prepareForexCardsV3(
  products: ForexProduct[],
  device: string | null,
  version: number | null | undefined,
  persona: PersonaType | null | undefined
) {
  const pinnedCards: CardType[] = [];
  const otherCards: any = [];

  const pinned_mapping: { [key in ProductType]: boolean } = {
    multi_cx_card: true,
    currency: true,
    inr_card: true,
  };

  if (persona?.multi_cx_card_cust && !persona?.currency_order_active) {
    pinned_mapping["currency"] = false;
  } else if (persona?.multi_cx_card_cust && persona?.currency_order_active) {
    // In this case delivery date should hide
    pinned_mapping["multi_cx_card"] = false;
    pinned_mapping["currency"] = false;
  }

  if (device === "lg") {
    pinned_mapping["inr_card"] = false;
    pinned_mapping["multi_cx_card"] = false;
    pinned_mapping["currency"] = false;
  }

  for (const product of products) {
    for (const [productKey, productValue] of Object.entries<ProductData>(
      product
    )) {
      let card = {
        ...defaultForexCardsData[productKey],
        ...productValue,
      } as CardType;
      if (version) {
        card.version = version;
      }

      if (!productValue.version) {
        productValue.version = version as number | undefined;
      }

      if (productValue.show_best_rate) {
        card.showBestRate = true;
      }

      card = setForexCardPersuasions(card, productValue);
      card = setUnavailableCardContent(card, productValue);
      if (device === "lg") {
        card.isUnavailableAtLoc = null;
      } else {
        // Not showing subLabel for mobile.
        card.subLabel = "";
      }
      if (pinned_mapping[productKey as ProductType]) {
        pinnedCards.push(card);
      } else {
        otherCards.push(card);
      }
    }
  }

  return [pinnedCards, otherCards];
}

export function preparePinnedForexCardsMobile(
  products: ForexProduct[],
  device: string | null,
  version: number | null | undefined
) {
  const pinnedForexCards: CardType[] = [];
  for (const product of products) {
    for (const [productKey, productValue] of Object.entries<ProductData>(
      product
    )) {
      const { render_separately } = productValue || {};
      if (render_separately) {
        let card = {
          ...defaultForexCardsData[productKey],
          ...productValue,
        } as CardType;
        if (version) {
          card.version = version;
        }
        // Not showing subLabel for mobile.
        card.subLabel = "";
        card = setForexCardPersuasions(card, productValue);
        card = setUnavailableCardContent(card, productValue);

        pinnedForexCards.push(card);
      }
    }
  }
  return pinnedForexCards;
}

function setForexCardPersuasions(card: CardType, productValue: ProductData) {
  const newCard: CardType = { ...card };
  // prepare persuasion related data.
  const persuations = getPersuasionsProps(productValue);
  newCard.isRecommended = persuations[PERSUASION_TYPE.DELIVERY_TRACKING];
  newCard.loadCurrency = persuations[PERSUASION_TYPE.MINI_DASHBOARD];
  newCard.docsPending = persuations[PERSUASION_TYPE.UPLOAD_DOCUMENTS];
  newCard.isleftOffBefore = persuations[PERSUASION_TYPE.DROP_OFF];
  newCard.backendPersuasion = persuations[PERSUASION_TYPE.BACKEND];
  newCard.viewOrdersPersuasion = persuations[PERSUASION_TYPE.VIEW_ORDERS];
  newCard.startOrder = persuations[PERSUASION_TYPE.START_ORDER];
  return newCard;
}

const setUnavailableCardContent = (
  card: CardType,
  productValue: ProductData
) => {
  const { delivery_details } = productValue || {};
  const { deliverable, eta_in_days } = delivery_details || {};
  const newCard = { ...card };
  const host = getHostName();
  if (
    (!host && host === HOST.MAKEMYTRIP) ||
    deliverable === null ||
    deliverable === undefined
  ) {
    return newCard;
  }

  if (deliverable) {
    let deliveryLabel = "Delivery by ";
    const { date, monthName, suffix } = getFutureDate(Number(eta_in_days));
    deliveryLabel += `${date} ${monthName}`;

    newCard.deliveryLabel = deliveryLabel;
  } else if (deliverable === false) {
    newCard.isUnavailableAtLoc = {
      normalText:
        "Unavailable at your location. You can try getting it delivered to",
      linkText: {
        text: "Another Location",
        hrefLink: "",
      },
    };
  }
  return newCard;
};

export function getPersuasionProp(type: PersuasionType, data?: PersuasionData) {
  switch (type) {
    case PERSUASION_TYPE.DELIVERY_TRACKING:
      return getDeliveryTrackingPersuasionProps(
        data as DeliveryDetailsPersuasionData
      );
    case PERSUASION_TYPE.BACKEND:
      return getBackendPersuasionProps(data as BackendPersuasionData);

    case PERSUASION_TYPE.DROP_OFF:
      return getDropOffPersuasionProps(data as DropOffPersuasionData);
    case PERSUASION_TYPE.MINI_DASHBOARD:
      return getMiniDashboardPersuasionProps(
        data as MiniDashboardPersuasionData
      );
    case PERSUASION_TYPE.UPLOAD_DOCUMENTS:
      return getUploadDocsPersuationProps(data as UploadDocsPersuasaionData);
    case PERSUASION_TYPE.VIEW_ORDERS:
      return getViewOrdersPersuationProps(data as ViewOrdersPersuasaionData);
    case PERSUASION_TYPE.ACTIVATION_IN_PROGRESS:
      return getActivationInProgressPersuasionProps(
        data as ActivationInProgressPersuasionData
      );
    case PERSUASION_TYPE.START_ORDER:
      return getStartOrderPersuasionProps(data as StartOrderPersuasionData);
    case PERSUASION_TYPE.UNDELIVERABLE:
      return getUndeliverablePersuasionProps(
        data as UndeliverablePersuasionData
      );
    case PERSUASION_TYPE.UPCOMING_TRIPS:
      return getUpcomingTripsPersuasionProps(
        data as UpcomingTripsPersuasionData
      );
    case PERSUASION_TYPE.CROSS_SELL:
      return getCrossSellPersuasionProps(data as any);

    case PERSUASION_TYPE.FREE_CARD:
      return getFreeCardPersuasionProps(data as any);

    case PERSUASION_TYPE.RELOAD_TRIP:
      return getReloadTripPersuasionProps(data as ReloadTripPerusasionData);
    default:
      console.error(`Invalid persuasion type [${type}]`);
      return data;
  }
}

function getReloadTripPersuasionProps(
  data: ReloadTripPerusasionData
): ReloadTripPersuasionProps {
  const countries = data?.reload_discovery_order_details?.countries;
  let countryLabel = "";

  if (!countries?.length) {
    countryLabel = "";
  } else if (countries.length === 1) {
    countryLabel = `${countries[0]}`;
  } else {
    countryLabel = `${countries[0]} +${countries.length - 1}`;
  }

  return {
    label: `Load Instantly for your ${countryLabel} trip`,
    btnProps: {
      customTitle: "Reload Now",
    },
  };
}

export function getPersuasionsProps(product: ProductData) {
  const persuationProps: PersuationProps = {
    [PERSUASION_TYPE.DELIVERY_TRACKING]: null,
    [PERSUASION_TYPE.BACKEND]: [], // multiple backend persuasions can come hence array.
    [PERSUASION_TYPE.DROP_OFF]: null,
    [PERSUASION_TYPE.MINI_DASHBOARD]: null,
    [PERSUASION_TYPE.UPLOAD_DOCUMENTS]: null,
    [PERSUASION_TYPE.VIEW_ORDERS]: null,
    [PERSUASION_TYPE.ACTIVATION_IN_PROGRESS]: null,
    [PERSUASION_TYPE.START_ORDER]: null,
    [PERSUASION_TYPE.UNDELIVERABLE]: null,
    [PERSUASION_TYPE.UPCOMING_TRIPS]: null,
    [PERSUASION_TYPE.CROSS_SELL]: null,
    [PERSUASION_TYPE.FREE_CARD]: null,
  };
  const { persuasions = [], version } = product;
  for (const persuasionObj of persuasions) {
    const { type, data, cta } = persuasionObj || {};
    switch (type) {
      case PERSUASION_TYPE.DELIVERY_TRACKING:
        persuationProps[PERSUASION_TYPE.DELIVERY_TRACKING] =
          getDeliveryTrackingPersuasionProps(
            data as DeliveryDetailsPersuasionData,
            cta
          );
        break;
      case PERSUASION_TYPE.BACKEND:
        persuationProps[PERSUASION_TYPE.BACKEND].push(
          getBackendPersuasionProps(data as BackendPersuasionData, cta)
        );
        break;
      case PERSUASION_TYPE.DROP_OFF:
        persuationProps[PERSUASION_TYPE.DROP_OFF] = getDropOffPersuasionProps(
          data as DropOffPersuasionData,
          cta
        );
        break;
      case PERSUASION_TYPE.MINI_DASHBOARD:
        persuationProps[PERSUASION_TYPE.MINI_DASHBOARD] =
          version === VERSIONS.V3
            ? getMiniDashboardPersuasionPropsV2(
                data as MiniDashboardPersuasionData,
                cta
              )
            : getMiniDashboardPersuasionProps(
                data as MiniDashboardPersuasionData,
                cta
              );
        break;
      case PERSUASION_TYPE.UPLOAD_DOCUMENTS:
        persuationProps[PERSUASION_TYPE.UPLOAD_DOCUMENTS] =
          getUploadDocsPersuationProps(data as UploadDocsPersuasaionData, cta);
        break;
      case PERSUASION_TYPE.VIEW_ORDERS:
        persuationProps[PERSUASION_TYPE.VIEW_ORDERS] =
          getViewOrdersPersuationProps(data as ViewOrdersPersuasaionData, cta);
        break;
      case PERSUASION_TYPE.ACTIVATION_IN_PROGRESS:
        persuationProps[PERSUASION_TYPE.ACTIVATION_IN_PROGRESS] =
          getActivationInProgressPersuasionProps(
            data as ActivationInProgressPersuasionProps,
            cta
          );
        break;
      case PERSUASION_TYPE.START_ORDER:
        persuationProps[PERSUASION_TYPE.START_ORDER] =
          getStartOrderPersuasionProps(data as StartOrderPersuasionData, cta);
        break;
      case PERSUASION_TYPE.UNDELIVERABLE:
        persuationProps[PERSUASION_TYPE.UNDELIVERABLE] =
          getUndeliverablePersuasionProps(
            data as UndeliverablePersuasionProps,
            cta
          );
        break;
      case PERSUASION_TYPE.UPCOMING_TRIPS:
        persuationProps[PERSUASION_TYPE.UPCOMING_TRIPS] =
          getUpcomingTripsPersuasionProps(
            data as UpcomingTripsPersuasionData,
            cta
          );
        break;
      case PERSUASION_TYPE.CROSS_SELL:
        persuationProps[PERSUASION_TYPE.CROSS_SELL] =
          getCrossSellPersuasionProps(data as any);
        break;

      case PERSUASION_TYPE.FREE_CARD:
        persuationProps[PERSUASION_TYPE.FREE_CARD] = getFreeCardPersuasionProps(
          data as any
        );
        break;

      default:
        console.error(`Invalid persuasion type [${type}]`);
    }
  }
  return persuationProps;
}

function getUpcomingTripsPersuasionProps(
  data: UpcomingTripsPersuasionData,
  cta?: PersuasionCTA
): UpcomingTripsPersuasionProps {
  return {
    label: `<span><span style="color:#2B825E; font-weight:bold">Recommended</span> <span style="font-weight:bold">For your upcoming trip</span><br/> Preferred by the users travelling to ${data.destination} <span>`,
    type: PERSUASION_TYPE.UPCOMING_TRIPS,
  };
}

function getActivationInProgressPersuasionProps(
  data: ActivationInProgressPersuasionData,
  cta?: PersuasionCTA
): ActivationInProgressPersuasionProps {
  return {
    label: `Card Delivered. <b style="color: #2B825E">Activation Under Progress </b>`,
    type: PERSUASION_TYPE.ACTIVATION_IN_PROGRESS,
  };
}

function getUndeliverablePersuasionProps(
  data: UndeliverablePersuasionData,
  cta?: PersuasionCTA
): UndeliverablePersuasionProps {
  return {
    normalText: "Delivery unavailable at your location. You can try",
    linkText: {
      text: "Another location",
    },
  };
}

function getStartOrderPersuasionProps(
  data: StartOrderPersuasionData,
  cta?: PersuasionCTA
): StartOrderPersuasionProps {
  return {
    isPrimaryButton:
      data?.is_primary_button !== undefined ? data.is_primary_button : true,
    linkBtnInMobile: {
      label: (data?.label || "Start Order").toUpperCase(),
    },
    cta,
  };
}

function getCrossSellPersuasionProps(data: any): CrossSellDataType {
  const {
    cross_sell_product,
    pii,
    address,
    upcoming_trip,
    cards,
    delivery_details,
  } = data?.cross_sell_data || {};
  const { name, email, phone, user_id } = pii ?? {};

  const { line1, line2, city, state, country, pincode, city_code, state_code } =
    address ?? {};

  const { intl_travel, start_date, end_date, no_of_travellers, trip_details } =
    upcoming_trip ?? {};

  const {
    deliverable,
    eta_in_days,
    city: delivery_city,
    pincode: delivery_pincode,
  } = delivery_details ?? {};

  const payload: CrossSellDataType = {
    crossSellProduct: cross_sell_product,
    crossSellPii: {
      name,
      email,
      phone,
      userId: user_id,
    },
    crossSellAddress: {
      addressLine1: line1,
      addressLine2: line2,
      city,
      state,
      country,
      pincode,
      cityCode: city_code,
      stateCode: state_code,
    },
    crossSellUpcomingTrips: {
      intlTravel: intl_travel,
      startDate: start_date,
      endDate: end_date,
      noOfTravellers: no_of_travellers,
      tripDetails: trip_details?.map((trip: any) => ({
        countryName: trip.country_name,
        currencyCode: trip.currency_code,
        rate: trip.rate,
      })),
    },
    crossSellCards: cards?.map((card: any) => ({
      acqCurr: card.acq_curr,
      acqQuantity: card.acq_quantity,
      isActive: card.is_active,
      rate: card.rate,
    })),
    crossSellDeliveryDetails: {
      deliverable,
      eta_in_days,
      city: delivery_city,
      pincode: delivery_pincode,
    },
  };
  return payload;
}

function getFreeCardPersuasionProps(data: any): FreeCardOfferProps {
  const { cashback, offer_text } = data || {};
  return {
    cashback,
    text: offer_text,
  };
}

const getDeliveryEta = (etaInDays: number, deliveryEtaStr: string) => {
  let deliveryText = "";
  if (etaInDays === 0) {
    deliveryText = "Today, 9 pm";
  } else if (etaInDays === 1) {
    deliveryText = "Tomorrow, 9 pm";
  } else {
    deliveryText = deliveryEtaStr;
  }
  return deliveryText;
};

function getDeliveryTrackingPersuasionProps(
  data?: DeliveryDetailsPersuasionData,
  cta?: PersuasionCTA
) {
  const { eta_in_days, ...rest } = data || {};
  let cardDeliveryMessage = "";
  let label = "";
  if (eta_in_days === 0 || eta_in_days) {
    const { date, monthName, suffix, day } = getFutureDate(Number(eta_in_days));
    cardDeliveryMessage = `Expected delivery by ${date} ${monthName}`;
    let deliveryEtaStr = `${day}, ${date} ${monthName}`;
    deliveryEtaStr = getDeliveryEta(eta_in_days, deliveryEtaStr);
    label = `Expected delivery by <b style="color: #2B825E"> ${deliveryEtaStr} </b>`;
  }
  return {
    text: {
      blackBoldText: "Your card is",
      greenBoldText: "On the Way!",
      normalText: cardDeliveryMessage,
    },
    linkBtn: {
      label: "Track",
    },
    label,
    type: PERSUASION_TYPE.DELIVERY_TRACKING,
    ...rest,
    cta,
  };
}
function getBackendPersuasionProps(
  data?: BackendPersuasionData,
  cta?: PersuasionCTA
) {
  const { theme, value, ...rest } = data || {};
  return {
    theme,
    value,
    ...rest,
    cta,
  };
}
function getDropOffPersuasionProps(
  data?: DropOffPersuasionData,
  cta?: PersuasionCTA
) {
  return {
    text: {
      blackBoldText: "Almost there! Order now and save big on forex markup",
    },
    linkBtn: {
      label: "Complete Order",
    },
    ...data,
    cta,
  };
}

function getMiniDashboardPersuasionPropsV2(
  data?: MiniDashboardPersuasionData,
  cta?: PersuasionCTA
) {
  const {
    sub_label,
    action,
    title,
    show_add_btn = false,
    is_primary_button = true,
    ...rest
  } = data || {};
  return {
    label: title,
    subLabel: sub_label,
    linkBtnInMobile: {
      label: "VIEW DASHBOARD",
    },
    toAdd: true,
    addText: show_add_btn ? "LOAD CURRENCY" : null,
    isPrimaryButton: is_primary_button,
    ...rest,
    cta,
  } as MiniDashboardPersuasionProps;
}

function getMiniDashboardPersuasionProps(
  data?: MiniDashboardPersuasionData,
  cta?: PersuasionCTA
) {
  const {
    sub_label,
    action,
    title,
    show_add_btn = false,
    is_primary_button = true,
    ...rest
  } = data || {};
  return {
    label: title,
    subLabel: sub_label,
    linkBtnInMobile: {
      label: "VIEW DASHBOARD",
    },
    toAdd: true,
    addText: show_add_btn ? "+ Add" : null,
    isPrimaryButton: is_primary_button,
    ...rest,
    cta,
  } as MiniDashboardPersuasionProps;
}
function getUploadDocsPersuationProps(
  data?: UploadDocsPersuasaionData,
  cta?: PersuasionCTA
) {
  const { value = "", order_id = "", redirect_to = "", ...rest } = data || {};
  return {
    label: value, //'Upload documents to add currency in forex card',
    iconSrc: undefined,
    btnProps: {
      customTitle: "Upload Now",
    },
    order_id: order_id,
    redirect_to: redirect_to,
    ...rest,
    cta,
  };
}

function getViewOrdersPersuationProps(
  data?: ViewOrdersPersuasaionData,
  cta?: PersuasionCTA
) {
  const { value = "", ...rest } = data || {};
  return {
    label: value,
    iconSrc: undefined,
    linkBtnInMobile: {
      label: "VIEW ORDERS",
    },
    btnProps: {
      customTitle: "View Orders",
    },
    ...rest,
    cta,
  };
}

export function getMobileTitle(
  persona: PersonaType | null | undefined,
  device: string | null
): string {
  if (persona && device === "sm") {
    const { upcoming_trip, user_name, user_type } = persona || {};
    if (user_type === USER_TYPE.EXISTING_CUSTOMER && user_name) {
      return `Hey ${user_name ?? ""}`;
    }

    const { country } = upcoming_trip || {};
    if (upcoming_trip && country) {
      return `Get Forex Card & Currency For Your Upcoming Trip`;
    }
  }
  return `Get Forex Card & Currency At Your Doorstep`;
}
