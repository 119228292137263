import { removeSessionStorageItem } from "./clientStorageUtils";
import {
  CARD_TYPE,
  SESSION_STORAGE_KEYS,
  deepLink,
  SIGNAL_STATE,
  PARTNER,
  HOST,
  COOKIES,
  MMT_FOREX_URL,
  MMT_SUB_DOMAIN,
  MakeMyTrip,
  GI_SUB_DOMAIN,
} from "../constants";
import { redirectInterceptHref } from "../static/utils";
import {
  landingCardShowcaseDataProps,
  landingForexOfferingCardDataProps,
} from "../static/mockData";
import { DeviceType } from "../hooks/useRedirectionUrls";
import {
  getHostName,
  getPartnerFromUrl,
  persistSpecificParamInCookie,
} from ".";

interface IRedirectUrlOption {
  otuLandingPartnerUrl?: string;
  otuForexPartnerUrl?: string;
  tmBaseUrl?: string;
  partner?: string | null;
  device: DeviceType | null;
  mwebtmBaseUrl?: string;
}

export const signalStateRedirectionForCurrency = (
  tmBaseUrl: string,
  mwebtmBaseUrl: string,
  signalState: any,
  data: any = {},
  otuForexPartnerUrl: string | undefined
) => {
  const url = `${tmBaseUrl}/bmf/currency`;
  const redirectBaseUrl = tmBaseUrl + "?";
  let queryParam = "";
  const partner = getPartnerFromUrl();
  switch (signalState) {
    case SIGNAL_STATE.TRAVELLER_DETAILS:
      redirectInterceptHref(
        `${url}/onboarding/traveller-details?${
          data?.order_id ? "dp_orderId=" + data?.order_id : ""
        }&source=listing`
      );
      break;
    case SIGNAL_STATE.ADD_PRODUCTS:
      redirectInterceptHref(
        `${url}/onboarding/load-currency?${
          data?.order_id ? "dp_orderId=" + data?.order_id : ""
        }`
      );
      break;
    case SIGNAL_STATE.ORDER_UNDER_PROCESSING:
      redirectInterceptHref(
        `${url}/onboarding/traveller-details?${
          data?.order_id ? "dp_orderId=" + data?.order_id : ""
        }&source=listing`
      );
      break;
    case SIGNAL_STATE.DOCUMENT_PENDING:
      redirectInterceptHref(
        `${redirectBaseUrl}deeplink=${deepLink.CURRENCY_NOTES}`
      );
      break;
    case SIGNAL_STATE.START_PAYMENT:
      if (data?.signal_data?.checkout_url) {
        redirectInterceptHref(`${data.signal_data.checkout_url}`);
      } else {
        redirectInterceptHref(`/partners/${partner}/forex/listing`);
      }
      break;
    case SIGNAL_STATE.VIEW_ORDERS:
      redirectInterceptHref(`deeplink=${deepLink.CURRENCY_NOTES}`);
      break;
    default:
      redirectInterceptHref(`/partners/${partner}/forex/listing`);
      break;
  }
};

export function productTilesRedirections(
  item: landingCardShowcaseDataProps,
  {
    otuForexPartnerUrl,
    tmBaseUrl = "",
    device,
    mwebtmBaseUrl = "",
  }: IRedirectUrlOption
) {
  let queryParam = "";
  const redirectBaseUrl = tmBaseUrl + "?";
  // device === DEVICE_MODEL.WEB
  //     ? `${otuForexPartnerUrl}&`
  //     : `${tmBaseUrl}/common?`;
  let nextSignalState;
  switch (item.id) {
    case CARD_TYPE.MULTI_CURRENCY_CARD:
      queryParam = `deeplink=${deepLink.MULTI_CURRENCY_CARD}`;
      redirectInterceptHref(`${redirectBaseUrl}${queryParam}`);
      break;

    case CARD_TYPE.CURRENCY:
      nextSignalState = item?.cta?.user_state_data?.next_signal_state;
      signalStateRedirectionForCurrency(
        tmBaseUrl,
        mwebtmBaseUrl,
        nextSignalState,
        item?.cta?.user_state_data,
        otuForexPartnerUrl
      );
      break;

    case CARD_TYPE.INR_CARD:
      queryParam = `deeplink=${deepLink.INR_CARD}`;
      redirectInterceptHref(`${redirectBaseUrl}${queryParam}`);
      break;

    default:
      throw new Error(`Invalid Card Id: ${item.id} [productTilesRedirections]`);
  }
}

export function redirectUploadDocs(
  item: landingCardShowcaseDataProps,
  { otuForexPartnerUrl, tmBaseUrl, device }: IRedirectUrlOption
) {
  removeSessionStorageItem(SESSION_STORAGE_KEYS.REQUEST_ID);
  let queryParam = "";
  const redirectBaseUrl = tmBaseUrl + "?";
  // device === DEVICE_MODEL.WEB
  //     ? `${otuForexPartnerUrl}&`
  //     : `${tmBaseUrl}/common?`;

  switch (item.id) {
    case CARD_TYPE.MULTI_CURRENCY_CARD:
      queryParam = `deeplink=${deepLink.MULTI_CURRENCY_CARD}`;
      break;
    case CARD_TYPE.CURRENCY:
      queryParam = `deeplink=${deepLink.CURRENCY_NOTES}`;
      break;

    // Note: For INR CARD, Upload docs persuasion won't come.
    // case CARD_TYPE.INR_CARD:
    //     redirectInterceptHref('');
    //     break;
    default:
      throw new Error(`Invalid Card Id: ${item.id} [redirectUploadDocs]`);
  }
  return redirectInterceptHref(`${redirectBaseUrl}${queryParam}`);
}

export function redirectKnowMoreCardLinks(
  item: landingForexOfferingCardDataProps,
  {
    otuForexPartnerUrl,
    tmBaseUrl = "",
    device,
    mwebtmBaseUrl = "",
  }: IRedirectUrlOption
) {
  removeSessionStorageItem(SESSION_STORAGE_KEYS.REQUEST_ID);
  let queryParam = "";
  const redirectBaseUrl = tmBaseUrl + "?";
  // device === DEVICE_MODEL.WEB
  //     ? `${otuForexPartnerUrl}&`
  //     : `${tmBaseUrl}/common?`;
  let nextSignalState;
  switch (item.id) {
    case CARD_TYPE.MULTI_CURRENCY_CARD:
      queryParam = `deeplink=${deepLink.MULTI_CURRENCY_CARD}`;
      redirectInterceptHref(`${redirectBaseUrl}${queryParam}`);
      break;

    case CARD_TYPE.CURRENCY:
      nextSignalState = item?.cta?.user_state_data?.next_signal_state;
      signalStateRedirectionForCurrency(
        tmBaseUrl,
        mwebtmBaseUrl,
        nextSignalState,
        item?.cta?.user_state_data,
        otuForexPartnerUrl
      );
      break;

    case CARD_TYPE.INR_CARD:
      queryParam = `deeplink=${deepLink.INR_CARD}`;
      redirectInterceptHref(`${redirectBaseUrl}${queryParam}`);
      break;

    default:
      throw new Error(
        `Invalid Card Id: ${item.id} [redirectKnowMoreCardLinks]`
      );
  }
}

export function exploreForexCurrencyRedirection(
  item: landingForexOfferingCardDataProps,
  {
    otuForexPartnerUrl,
    tmBaseUrl = "",
    device,
    mwebtmBaseUrl = "",
  }: IRedirectUrlOption
) {
  const nextSignalState = item?.cta?.user_state_data?.next_signal_state;
  signalStateRedirectionForCurrency(
    tmBaseUrl,
    mwebtmBaseUrl,
    nextSignalState,
    item?.cta?.user_state_data,
    otuForexPartnerUrl
  );
}

export function quickLoadMoneyRedirections(
  item: landingCardShowcaseDataProps,
  { tmBaseUrl, otuForexPartnerUrl, device }: IRedirectUrlOption
) {
  let queryParam = "";
  const redirectBaseUrl = tmBaseUrl + "?";
  // device === DEVICE_MODEL.WEB
  //     ? `${otuForexPartnerUrl}&`
  //     : `${tmBaseUrl}/common?`;
  switch (item.id) {
    case CARD_TYPE.MULTI_CURRENCY_CARD:
      queryParam = `deeplink=${deepLink.MULTI_CURRENCY_CARD}`;
      break;

    // Note: For Currency Notes, Quick load persuasion won't come.
    // case CARD_TYPE.CURRENCY:
    //     // TODO: SET REIDRECTION LINKS.
    //     redirectInterceptHref('');
    //     break;

    case CARD_TYPE.INR_CARD:
      // For mobile - https://www.tripmoney.com/partners/mmt/forex/common?deeplink=load-money;
      // For desktop - otuForexPartnerUrl?deeplink=load-money
      queryParam = `deeplink=load-money`;
      break;
    default:
      throw new Error(`Invalid Card Id: ${item.id} [quickLoadRedirections]`);
  }
  return redirectInterceptHref(`${redirectBaseUrl}${queryParam}`);
}

export function completeOrderRedirections(
  item: landingCardShowcaseDataProps,
  {
    otuForexPartnerUrl,
    tmBaseUrl = "",
    device,
    mwebtmBaseUrl = "",
  }: IRedirectUrlOption
) {
  let queryParam = "";
  const redirectBaseUrl = tmBaseUrl + "?";
  // device === DEVICE_MODEL.WEB
  //     ? `${otuForexPartnerUrl}&`
  //     : `${tmBaseUrl}/common?`;
  let nextSignalState;
  switch (item.id) {
    case CARD_TYPE.MULTI_CURRENCY_CARD:
      queryParam = `deeplink=${deepLink.MULTI_CURRENCY_CARD}`;
      redirectInterceptHref(`${redirectBaseUrl}${queryParam}`);
      break;
    case CARD_TYPE.CURRENCY:
      nextSignalState = item?.cta?.user_state_data?.next_signal_state;
      signalStateRedirectionForCurrency(
        tmBaseUrl,
        mwebtmBaseUrl,
        nextSignalState,
        item?.cta?.user_state_data,
        otuForexPartnerUrl
      );
      break;
    // Note: For INR CARD, drop off persuasion won't come.
    // case CARD_TYPE.INR_CARD:
    //     redirectInterceptHref('');
    //     break;
    default:
      throw new Error(
        `Invalid Card Id: ${item.id} [completeOrderRedirections]`
      );
  }
}

export function trackCardRedirections(
  item: landingCardShowcaseDataProps,
  { tmBaseUrl, otuForexPartnerUrl, device }: IRedirectUrlOption
) {
  let queryParam = "";
  const redirectBaseUrl = tmBaseUrl + "?";
  // device === DEVICE_MODEL.WEB
  //     ? `${otuForexPartnerUrl}&`
  //     : `${tmBaseUrl}/common?`;
  removeSessionStorageItem(SESSION_STORAGE_KEYS.REQUEST_ID);
  switch (item.id) {
    case CARD_TYPE.MULTI_CURRENCY_CARD:
      queryParam = `deeplink=${deepLink.MULTI_CURRENCY_CARD}`;
      break;

    /* 
        Note: For INR CARD & CURRENCY notes, track card persuasion won't come. 
        case CARD_TYPE.CURRENCY:
            redirectInterceptHref(
                ''
            );
            break;

        case CARD_TYPE.INR_CARD:
            redirectInterceptHref('');
            break;
        */
    default:
      throw new Error(`Invalid Card Id: ${item.id} [trackCardRedirections]`);
  }
  return redirectInterceptHref(`${redirectBaseUrl}${queryParam}`);
}

export function landingPageRedirect({
  otuLandingPartnerUrl,
}: IRedirectUrlOption) {
  redirectInterceptHref(`${otuLandingPartnerUrl}`);
}

export function viewDashboardRedirectionsV1(
  item: landingCardShowcaseDataProps,
  { tmBaseUrl, otuForexPartnerUrl, device }: IRedirectUrlOption
) {
  let queryParam = "";
  const redirectBaseUrl = tmBaseUrl + "?";
  // device === DEVICE_MODEL.WEB
  //     ? `${otuForexPartnerUrl}&`
  //     : `${tmBaseUrl}/common?`;
  switch (item.id) {
    case CARD_TYPE.MULTI_CURRENCY_CARD:
      queryParam = `deeplink=${deepLink.MULTI_CURRENCY_CARD}`;
      redirectInterceptHref(`${redirectBaseUrl}${queryParam}`);
      break;

    case CARD_TYPE.INR_CARD:
      queryParam = `deeplink=${deepLink.INR_CARD}`;
      redirectInterceptHref(`${redirectBaseUrl}${queryParam}`);
      break;

    // For currency mini dashboard won't come
    // case CARD_TYPE.CURRENCY:
    //     queryParam = `deeplink=${deepLink.CURRENCY_NOTES}`;
    //     redirectInterceptHref(`${redirectBaseUrl}${queryParam}`);
    //     break;

    default:
      throw new Error(
        `Invalid Card Id: ${item.id} [viewDashboardRedirectionsV1]`
      );
  }
}

export const viewOrdersRedirections = (
  item: landingCardShowcaseDataProps,
  { otuForexPartnerUrl, tmBaseUrl, device }: IRedirectUrlOption
) => {
  switch (item.id) {
    case CARD_TYPE.CURRENCY:
      redirectInterceptHref(`deeplink=${deepLink.CURRENCY_NOTES}`);
      break;
    default:
      throw new Error(`Invalid Card Id: ${item.id} [viewOrdersRedirections]`);
  }
};
