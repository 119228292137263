import { theme } from "../../css/Global";

import {
  PriceRatesContainer,
  PriceRatesContainerOuter,
  PriceTickerOuter,
  PriceTickerInner,
  PriceRatesWrapper,
  LandingTitleStyle,
  BestPriceTextGradient,
} from "./ListingPageComponents.style";

import FlexComp from "../ui-atoms/FlexComp/FlexComp";
import ParaTag from "../ui-atoms/TextStyles/ParaTag";
import SpanTag from "../ui-atoms/TextStyles/SpanTag";
import { useDevice } from "../../../hooks/useDevice";
import ImageTag from "../ui-atoms/ImageTag/ImageTag";
import { getElementUniqueIds, getHostName } from "../../../utils";
import AnchorTag from "../ui-atoms/TextStyles/AnchorTag";
import PriceTicker, { PriceTickerDataProps } from "../PriceTicker/PriceTicker";
import {
  PageNameMapping,
  SectionNameMapping,
  ElementNameMapping,
  HOST,
} from "../../../constants";

interface ClubOfferBannerProps {
  viewAllPriceHandler?: () => void;
  priceTickerData?: PriceTickerDataProps[];
  desktopLandingTitle?: string;
  bestPriceLabel?: string;
}

const ClubOfferBanner = ({
  viewAllPriceHandler,
  priceTickerData,
  desktopLandingTitle,
  bestPriceLabel,
}: ClubOfferBannerProps) => {
  const device = useDevice();
  const host = getHostName();

  if (!priceTickerData?.length)
    return <>{host === HOST.MAKEMYTRIP ? <FlexComp></FlexComp> : <></>}</>;

  return device === "lg" ? (
    <FlexComp
      gap={"0px"}
      margin={`${host === HOST.MAKEMYTRIP ? "40px" : "0px"} 0px 24px 0px`}
      padding="0px"
      alignItems="center"
      justifyContent={"space-between"}
      width="auto"
      height="auto"
      id={getElementUniqueIds(
        PageNameMapping.LISTING_PAGE,
        SectionNameMapping.HEADER,
        ElementNameMapping.CLUB_OFFER
      )}
    >
      <PriceRatesWrapper>
        <PriceRatesContainerOuter>
          <PriceRatesContainer>
            <ImageTag
              src="https://tripmoneycmsimgak.mmtcdn.com/img/rupee_3c03082b28.svg"
              height="16px"
              width="16px"
            />
            <BestPriceTextGradient>{bestPriceLabel}</BestPriceTextGradient>
          </PriceRatesContainer>
        </PriceRatesContainerOuter>
        <PriceTickerOuter
          onClick={viewAllPriceHandler}
          id={getElementUniqueIds(
            PageNameMapping.LISTING_PAGE,
            SectionNameMapping.HEADER,
            ElementNameMapping.VIEW_PRICES
          )}
        >
          <PriceTickerInner>
            <PriceTicker height={60} priceTickerData={priceTickerData} />
            <ImageTag
              src="https://tripmoneycmsimgak.mmtcdn.com/img/circular_Right_Chev_0b385a64bf.svg"
              height="24px"
              width="24px"
              margin={"0px 0px 0px 16px"}
            />
          </PriceTickerInner>
        </PriceTickerOuter>
      </PriceRatesWrapper>
      {desktopLandingTitle && (
        <LandingTitleStyle
          color={theme.greyText}
          fontSize="sm"
          dangerouslySetInnerHTML={{
            __html: desktopLandingTitle,
          }}
        />
      )}
    </FlexComp>
  ) : (
    <>
      <FlexComp
        alignSelf="flex-start"
        id={getElementUniqueIds(
          PageNameMapping.LISTING_PAGE,
          SectionNameMapping.HEADER,
          ElementNameMapping.CLUB_OFFER
        )}
      >
        <FlexComp
          margin={"0px 16px"}
          bgColor="linear-gradient(87.29deg, #C86DD7 0%, #3023AE 100%)"
          padding="2px 8px"
          borderRadius="16px"
        >
          <SpanTag fontSize="xs" color={theme.whiteText}>
            {bestPriceLabel}
          </SpanTag>
        </FlexComp>
      </FlexComp>
      <FlexComp
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        gap="20px"
        margin="4px 16px 12px"
      >
        <PriceTicker height={56} priceTickerData={priceTickerData} />
        <AnchorTag
          fontWeight="bold"
          onClick={viewAllPriceHandler}
          fontSize="xs"
          id={getElementUniqueIds(
            PageNameMapping.LISTING_PAGE,
            SectionNameMapping.HEADER,
            ElementNameMapping.VIEW_PRICES
          )}
        >
          Know more
        </AnchorTag>
      </FlexComp>
    </>
  );
};

export default ClubOfferBanner;
