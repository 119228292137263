import {
  OMNITURE_MAP,
  OMNI_EVENTS_MAP,
  PAGE_AND_CATEGORY_MAP,
  CN,
  CURRENCY_PAGE_AND_CATEGORY_MAP,
} from "./constants";
import { trackEventMweb } from "../common";
import { getUserCityAndLocation, getLogInStatus } from "./utils";
import type { TrackPageLoadType, TrackSeoPageLoadType } from "./type";
import {
  OMNI_EVENTS_MAP as OMNITURE_EVENTS_MAP,
  getDeviceTypeForOmniture,
  OMNITURE_MAP as OMNITURE_ANALYTICS_MAP,
} from "@tm/ui-widgets";
import { getCommonOmniData } from "@tm/ui-widgets";
import { getCommonSeoOmniData } from "@tm/ui-widgets";

const {
  DASHBOARD_STATES,
  INTERACTION_ID,
  BOOKING_STATUS,
  EVENTS,
  PURCHASE_ID,
} = OMNI_EVENTS_MAP;
const { CITY, CURRENCY, DENOMINATION } = OMNITURE_EVENTS_MAP;

// function for page load events
export function trackPageLoad(deps: TrackPageLoadType) {
  try {
    const {
      pageName: page,
      partner,
      orderStatus,
      orderID,
      bookingStatus,
      code = "PC",
      events,
      purchaseID,
    } = deps;
    const { pageName, lob, siteSection } =
      code === CN
        ? CURRENCY_PAGE_AND_CATEGORY_MAP[page]
        : PAGE_AND_CATEGORY_MAP[page];

    const location: string = getUserCityAndLocation();
    const platform: string = getDeviceTypeForOmniture() || "";
    const loginStatus = getLogInStatus();
    const commonOmniData = getCommonOmniData({
      pageName,
      lob,
      siteSection,
      partner,
      location,
      platform,
      loginStatus,
      code,
    });
    const omniData = {
      ...commonOmniData,
      ...(orderStatus && {
        [OMNITURE_MAP[DASHBOARD_STATES]]: orderStatus,
      }),
      ...(orderID && { [OMNITURE_MAP[INTERACTION_ID]]: orderID }),
      ...(bookingStatus && {
        [OMNITURE_MAP[BOOKING_STATUS]]: bookingStatus,
      }),
      ...(events &&
        purchaseID && {
          [OMNITURE_MAP[EVENTS]]: events,
          [OMNITURE_MAP[PURCHASE_ID]]: purchaseID,
        }),
    };
    trackEventMweb({
      ...omniData,
      pageName,
    });
  } catch (e) {
    console.error(e);
  }
}

// function for seo page load events
export function trackSeoPageLoad(deps: TrackSeoPageLoadType) {
  try {
    const { pageName, city, currency, denomination, cardClicked } = deps;
    const commonOmniData = getCommonSeoOmniData({
      pageName,
    });
    const omniData = {
      ...commonOmniData,
      ...(city && {
        [OMNITURE_ANALYTICS_MAP[CITY]]: city,
      }),
      ...(currency && {
        [OMNITURE_ANALYTICS_MAP[CURRENCY]]: currency,
      }),
      ...(denomination && {
        [OMNITURE_ANALYTICS_MAP[DENOMINATION]]: denomination,
      }),
      ...(cardClicked && {
        [OMNITURE_ANALYTICS_MAP.freeCardOpted]: cardClicked,
      }),
    };
    trackEventMweb({
      ...omniData,
      pageName,
    });
  } catch (e) {
    console.error(e);
  }
}
