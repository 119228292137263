import Head from "next/head";

const CityDocumet = ({ cityData, currency_exchange_in_city }: any) => {
  return (
    <Head>
      <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="description" content={cityData?.Meta_desc}></meta>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      {cityData?.allow_indexing ? null : <meta name="robots" content="none" />}
      <meta
        name="keywords"
        content={
          cityData?.Primary_keyword + ", " + cityData?.Secondary_keywords
        }
      />
      <link
        rel="preload"
        href="https://fonts.gstatic.com/s/lato/v23/S6uyw4BMUTPHjx4wXiWtFCc.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="canonical"
        href={`https://www.makemytrip.com/tripmoney/${currency_exchange_in_city}`}
      />
      {cityData?.hero_img?.src ? (
        <link rel="preload" as="image" href={cityData.hero_img?.src} />
      ) : null}
      <title>{cityData?.Meta_title}</title>
    </Head>
  );
};

export default CityDocumet;
