import { useEffect, useState, lazy, Suspense } from "react";
import { useGlobalConfig } from "libs/ui-widgets/src/lib/hooks/useGlobalConfig";
import {
  getCamelCaseStr,
  getElementUniqueIds,
  persistSpecificParamInCookie,
} from "libs/ui-widgets/src/lib/utils";
import FlexComp from "../../ui-atoms/FlexComp/FlexComp";
import ParaTag from "../../ui-atoms/TextStyles/ParaTag";
import { theme } from "../../../css/Global";
import LandingButton from "../../ui-atoms/LandingButton/LandingButton";
import {
  CARD_TYPE,
  COOKIES,
  ElementNameMapping,
  GI_SUB_DOMAIN,
  MMT_SUB_DOMAIN,
  MakeMyTrip,
  PDT_EVENTS,
  PageNameMapping,
} from "libs/ui-widgets/src/lib/constants";
import SpanTag from "../../ui-atoms/TextStyles/SpanTag";
import { CrossSellDataType } from "libs/ui-widgets/src/lib/store/forexListingReducer";
import { landingCardShowcaseDataProps } from "../../../mockData";
import { useDevice } from "libs/ui-widgets/src/lib/hooks/useDevice";
import { getCookie } from "libs/ui-widgets/src/lib/utils/cookieUtils";
import { getCrossSellComponentData } from "libs/ui-widgets/src/lib/utils/crossSellUtils";
import useRedirectionUrls from "libs/ui-widgets/src/lib/hooks/useRedirectionUrls";
import { PersuasionHandler } from "../Persuasion";

const BuyCurrencyBS = lazy(
  () =>
    import(
      /* webpackChunkName: 'BuyCurrencyBS' */ "../../BuyCurrencyBS/BuyCurrencyBS"
    )
);

const CrossSellPersuasion = (props: {
  persuasionProps: CrossSellDataType;
  cardData: landingCardShowcaseDataProps;
  handlers: PersuasionHandler;
}) => {
  const { partner } = useGlobalConfig();
  const device = useDevice();
  const { crossSellRedirect } = useRedirectionUrls();
  const [isBuyCurrencyModalOpen, setBuyCurrencyModalOpen] = useState(false);
  const {
    crossSellProduct,
    crossSellAddress,
    crossSellCards,
    crossSellDeliveryDetails,
    crossSellPii,
    crossSellUpcomingTrips,
  } = props.persuasionProps;

  // showing cross sell nudge
  useEffect(() => {
    const isCrossSellNudgeShown = getCookie(COOKIES.CROSS_SELL_NUDGE_SHOWN);
    if (!isCrossSellNudgeShown) {
      setTimeout(() => {
        setBuyCurrencyModalOpen(true);
        persistSpecificParamInCookie(COOKIES.CROSS_SELL_NUDGE_SHOWN, "true", {
          path: "/",
          domain: partner === MakeMyTrip ? MMT_SUB_DOMAIN : GI_SUB_DOMAIN,
        });
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (crossSellProduct) {
      props.handlers.sendCrossSellPdtEvent({
        eventName: PDT_EVENTS.CROSS_SELL_NUDGE_SHOWN,
        eventValue: crossSellProduct || "",
      });
    }
  }, [crossSellProduct]);

  const getFacilities = () => {
    if (crossSellProduct === CARD_TYPE.MULTI_CURRENCY_CARD) {
      return [
        { id: "1", label: `<b>ZERO</b> Forex Markup` },
        { id: "2", label: `<b>Best Exchange Rates</b>` },
      ];
    } else {
      return [
        { id: "1", label: `<b><BEST/b> Exchange Rates` },
        { id: "2", label: `<b>RBI</b> Authorised` },
      ];
    }
  };

  return (
    <>
      <FlexComp
        padding={device === "lg" ? "8px 12px" : "16px"}
        borderRadius="8px"
        margin={device === "lg" ? "24px 0px 0px" : "24px -16px -16px"}
        justifyContent="space-between"
        flexDirection={"row"}
        bgColor="linear-gradient(147deg, #FFF6D8 4.79%, #FFFAF3 43.98%, #F3F0E6 120.5%)"
        alignItems="center"
      >
        {/* {(props.persuasionProps.label ||
                    props.persuasionProps.iconSrc) && (
                    <FlexComp
                        padding="0px"
                        alignItems="center"
                        justifyContent="space-between"
                        margin={device === 'sm' ? '0px 0px 0' : '0px'}
                        bgColor="transparent"
                    >
                        <ParaTag
                        color={theme.greyText}
                        fontSize="xs"
                        dangerousText={'For your upcoming trip, buy <b>USD, THB</b> in just one step'}
 margin="0px 20px 0px 0px"
                        />
                    </FlexComp>
                )} */}
        <FlexComp
          padding="0px"
          // alignItems="center"
          // justifyContent="space-between"
          margin={device === "sm" ? "0px 0px 0" : "0px"}
          bgColor="transparent"
          flexDirection="column"
        >
          {/* {props.persuasionProps.label && (
                            <ParaTag
                                fontWeight="bold"
                                color={theme.blackText}
                                margin="0px 20px 0px 0px"

                            >
                                {props.persuasionProps.label}
                            </ParaTag>
                        )} */}
          <ParaTag
            color={theme.greyText}
            fontSize="xs"
            dangerousText={
              crossSellProduct === CARD_TYPE.MULTI_CURRENCY_CARD
                ? `For your upcoming trip, get forex card with <span class="boldText blackText">${crossSellCards
                    ?.map((e: any) => e.acqCurr)
                    .join(", ")}</span> in just one step`
                : `For your upcoming trip, buy <span class="boldText blackText">${crossSellCards
                    ?.map((e: any) => e.acqCurr)
                    .join(", ")}</span> in just one step`
            }
            margin="0px 20px 0px 0px"
          />
          <ParaTag fontSize="xs" color="#2B825E" fontWeight="bold">
            No additional documents
            <SpanTag color="#EB2026">*</SpanTag>
          </ParaTag>
        </FlexComp>

        <LandingButton
          id={getElementUniqueIds(
            PageNameMapping.LISTING_PAGE,
            getCamelCaseStr(props.cardData.label),
            ElementNameMapping.UPLOAD_NOW
          )}
          // {...props.persuasionProps.btnProps}
          customTitle={
            props.persuasionProps.crossSellProduct ===
            CARD_TYPE.MULTI_CURRENCY_CARD
              ? "BUY CARD"
              : "BUY CURRENCY"
          }
          flexShrink="0"
          fontSize={"12px"}
          customHeight="32px"
          padding="12px 16px"
          onClick={() => {
            crossSellRedirect({
              partner,
              crossSellProduct,
            });
          }}
        />
      </FlexComp>
      {isBuyCurrencyModalOpen && (
        <div onClick={(e) => e?.stopPropagation()}>
          <Suspense fallback={null}>
            <BuyCurrencyBS
              // deliveryDate={'Mon, 25th December'}
              onModalClose={() => {
                setBuyCurrencyModalOpen(false);
              }}
              // heading="Foreign Currency Notes, Just One Step Away!"
              // tripDetail={tripDetails}
              // btnTitle="BUY CURRENCY"
              btnClickHandler={() => {
                props.handlers.sendCrossSellPdtEvent({
                  eventName: PDT_EVENTS.CROSS_SELL_NUDGE_CLICKED,
                  eventValue: crossSellProduct || "",
                });
                crossSellRedirect({
                  partner,
                  crossSellProduct,
                });
              }}
              // additionalInfo={`BookMyForex Currency is the trusted choice of <b>80% Traveller</b>`}
              // isCardCrossSell={true}
              facilities={getFacilities()}
              isLoading={false}
              {...getCrossSellComponentData({
                crossSellAddress,
                crossSellCards,
                crossSellDeliveryDetails,
                crossSellPii,
                crossSellUpcomingTrips,
                crossSellProduct,
              })}
            />
          </Suspense>
        </div>
      )}
    </>
  );
};

export default CrossSellPersuasion;
