// @ts-ignore
import UAParser from "ua-parser-js";

import { useGlobalSelector } from "./useGlobalSelector";
import { ListingReducerType } from "../store/forexListingReducer";
import { useGlobalConfig } from "./useGlobalConfig";
import { PARTNER } from "../constants";
import {
  getDeviceId,
  getDeviceIdStatus,
  getDeviceType,
  getPartner,
  isMobile,
  isOnServer,
} from "../utils";
import { BridgeMethods } from "../utils/bridges";
import useItineraryId from "./useItineraryId";

export const useGetCommonPdtData = (
  latitude: number | null = null,
  longitude: number | null = null
) => {
  const {
    tm_user_id: userId,
    puid,
    version,
  } = useGlobalSelector((state: ListingReducerType) => state);
  const { partner } = useGlobalConfig();
  const { itineraryId } = useItineraryId();
  const ua = !isOnServer() ? navigator.userAgent : null;
  const parser = new UAParser().setUA(ua).getResult();
  let app_version = !isOnServer() ? BridgeMethods.getAppVersion() : null;

  const user = {
    zuid: `${userId ?? ""}`,
    puid: `${puid ?? ""}`,
    partner: getPartner(partner),
    is_loggedin: userId ? "true" : null,
  };
  app_version =
    `${
      getPartner() === PARTNER.MMT
        ? PARTNER.MMT.toUpperCase()
        : PARTNER.GI.toUpperCase()
    }_` + app_version;

  let context = {
    topic_name: "tripmoney_forex_ui",
    template_id: "59243",
    client_timestamp: new Date().getTime(),
    lob_name: "LEN",
    environment: "prod",
    correlation_key: itineraryId,
  };

  return {
    context: context,
    user_details: {
      zuid: user && user.zuid ? user.zuid : null,
      puid: user && user.puid ? user.puid : null,
      partner: user && user.partner ? user.partner : null,
      is_loggedin: user?.is_loggedin ? user.is_loggedin : null,
    },
    device: {
      latitude: latitude ? latitude : null,
      longitude: longitude ? longitude : null,
      os: parser && parser.os && parser.os.name ? parser.os.name : null,
      type: !isMobile()
        ? "Desktop"
        : parser && parser.device && parser.device.type
        ? parser.device.type
        : null,
      os_version:
        parser && parser.os && parser.os.version ? parser.os.version : null,
      app_version: app_version || null,
      user_agent: ua,
      model: getDeviceType() || null,
      connection_type: getDeviceIdStatus(),
      id: getDeviceId(getPartner(partner)),
    },
    version,
  };
};
