import { DEVICE_TYPE, DEVICE_MODEL_TYPE, HOST } from "@tm/types";
declare const window: any;

export const getAttributesByType = (buttons: any, type: string) =>
  buttons.data?.find((button: any) => button.attributes.type === type);

export const t = (sentence: any, wordsToReplace: any): any => {
  return Object.keys(wordsToReplace).reduce(
    (f, s) => `${f}`.replace(new RegExp(s, "ig"), wordsToReplace[s]),
    sentence
  );
};

export const fetcher = (resource: any, init: any) =>
  fetch(resource, init).then((res) => res.json());

const getSuffix = (dayNo: number) => {
  if (dayNo > 3 && dayNo < 21) return "th";
  switch (dayNo % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const SHORT_WEEK_DAYS = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
];

export const getFutureDate = (count = 10) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  // Get today's date
  const today = new Date();

  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + count);

  // Get the month name from the monthNames array
  const monthName = monthNames[tomorrow.getMonth()];

  // Output the date in the desired format
  const date = tomorrow.getDate();
  const year = tomorrow.getFullYear();
  const suffix = getSuffix(date);
  return {
    date,
    year,
    monthName,
    suffix,
    day: SHORT_WEEK_DAYS[tomorrow.getDay()],
  };
};

export function convertToPascalCase(str = "") {
  str = str.toLowerCase();
  let result = "";
  let nextUpper = true;
  for (let i = 0; i < str.length; i++) {
    const char = str[i];
    if (char === "_" || char === " ") {
      nextUpper = true;
      result += char;
    } else if (nextUpper) {
      result += char.toUpperCase();
      nextUpper = false;
    } else {
      result += char;
    }
  }
  return result;
}

export function generateRandomIndex(
  finalEvents: any,
  result: any,
  attempts = 1,
  debug = false
): { randomIndex: number; failedRandomEvents: number } {
  const random = Math.random() * result.length;
  const randomIndex = Math.round(random);
  const randomEvent = result[randomIndex];
  let failedRandomEvents = 0;
  let locationIsClose = false;
  const last = [];
  for (let i = 0; i < 4; i++) {
    const lastEvent = finalEvents[finalEvents.length - 1 - i];
    if (lastEvent) {
      const latIsClose =
        Math.abs(randomEvent?.src?.lat - lastEvent?.src?.lat) < 5;
      const longIsClose =
        Math.abs(randomEvent?.src?.long - lastEvent?.src?.long) < 5;
      locationIsClose = latIsClose && longIsClose;
      if (locationIsClose) {
        break;
      }
      debug && last.push(`${lastEvent.src.lat}:${lastEvent.src.long}`);
    }
  }
  debug &&
    console.log({
      current: `${randomEvent.src.lat}:${randomEvent.src.long}`,
      lastLats: last,
    });
  if (
    randomEvent?.src?.lat &&
    randomEvent?.src?.long &&
    attempts <= 10 &&
    finalEvents.length > 0 &&
    locationIsClose
  ) {
    return generateRandomIndex(finalEvents, result, attempts + 1);
  }
  if (attempts > 11) {
    failedRandomEvents++;
  }
  return { randomIndex, failedRandomEvents };
}

export const generateRandomNum = (start: number, end: number) => {
  if (start <= end) {
    return Math.floor(Math.random() * (end - start + 1)) + start;
  }
  return null;
};

const defaultTemplateValues = {
  "{year}": new Date().getFullYear().toString(),
};

/**
 *
 * @param object : Object contains interpolated string.
 * @param values Object with key - value pair where key - replaceable text and value - replaced text.
 * @returns Input Object with all the interpolated values replaced with value provided in the "values".
 */
export const template = (object: any, values?: { [k: string]: any }) => {
  const newValues = values
    ? { ...defaultTemplateValues, ...values }
    : defaultTemplateValues;
  object = JSON.stringify(object, (_, objValue) => {
    if (typeof objValue === "string") {
      for (const [key, value] of Object.entries(newValues)) {
        if (objValue.includes(key)) {
          return objValue.replace(key, value);
        }
      }
    }
    return objValue;
  });
  return JSON.parse(object);
};

export function getDeviceType(userAgent: string): DEVICE_TYPE {
  const mobileRegex = new RegExp(/Mobile|Android|BlackBerry/);
  const tabletRegex = new RegExp(
    /iPad|(Android(?!.*Mobile))|Tablet|PlayBook|Kindle|GT-P1000|SCH-I800|sholes|Xoom|Libero|\b4G\b/
  );
  if (userAgent.match(mobileRegex)) {
    return DEVICE_TYPE.MOBILE;
  } else if (userAgent.match(tabletRegex)) {
    return DEVICE_TYPE.TABLET;
  }
  return DEVICE_TYPE.DESKTOP;
}

export const doubleDigitCreator = (number: number) => {
  if (number < 10) {
    return `0${number}`;
  }
  return number;
};

export const dateSeprationHandler = (
  date: string,
  monthLength: number | null = null,
  weekLength: number | null = null
) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const weeks = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let dateObj: any = "";
  try {
    dateObj = new Date(date);
  } catch (e) {
    return null;
  }
  if (!dateObj || !date) {
    return null;
  }
  const currentMonth = months[dateObj.getMonth()];
  const currentDay = weeks[dateObj.getDay()];
  return {
    day: weekLength ? currentDay.substr(0, weekLength) : currentDay,
    date: doubleDigitCreator(dateObj.getDate()),
    month: monthLength ? currentMonth.substr(0, monthLength) : currentMonth,
    monthNumber: dateObj.getMonth() + 1,
    year: dateObj.getFullYear(),
    hours:
      dateObj.getHours() > 12
        ? doubleDigitCreator(dateObj.getHours() - 12)
        : doubleDigitCreator(dateObj.getHours()),
    minutes: doubleDigitCreator(dateObj.getMinutes()),
    format: dateObj.getHours() >= 12 ? "PM" : "AM",
  };
};

export const dateFormator = (dateObject: any) => {
  const { date, monthNumber, year } = dateObject;
  return `${date} / ${monthNumber} / ${year}`;
};

export const getMobileOperatingSystem = () => {
  if (isOnServer()) return "unknown";

  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
};

export const isOnServer = () => {
  if (typeof window === "undefined") {
    return true;
  }
  return false;
};

export const getDeviceModelType = (): DEVICE_MODEL_TYPE | null => {
  if (isOnServer()) return null;
  // @ts-ignore
  if (window.mmt_android_bridge || window.app_bridge) {
    return DEVICE_MODEL_TYPE.APP;
  }
  return DEVICE_MODEL_TYPE.WEB;
};

export function getHostName() {
  if (isOnServer()) {
    return null;
  }
  return window.location.hostname;
}

export function disableEncrytionInWeb() {
  return (
    getDeviceModelType() === DEVICE_MODEL_TYPE.WEB &&
    [HOST.MAKEMYTRIP, HOST.TRIPMONEY_MAKEMYTRIP].includes(getHostName() || "")
  );
}

export function getUtilOptions(options: any) {
  return disableEncrytionInWeb() ? { ...options, isPiiData: false } : options;
}

export const TRIPMONEY_MMT_DOMAIN_NAMES = [
  "tripmoney.makemytrip.com",
  "www.makemytrip.com",
];

export const isHostMakemytrip = () => {
  if (isOnServer()) return false;
  const url = new URL(window.location.href);
  return TRIPMONEY_MMT_DOMAIN_NAMES.includes(url.hostname);
};

const getGtmScript = (gtmId: string) => {
  return `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer', '${gtmId}');
  `;
};

export const loadGTM = () => {
  if (isHostMakemytrip()) {
    const script = document.createElement("script");
    script.innerHTML = getGtmScript("GTM-NWV3K7C");
    script.defer = true;
    document.head.appendChild(script);
  }
};

export function convertToSnakeCase(input: string): string {
  // Handle camelCase
  let snakeCase = input.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase();

  // Handle kebab-case
  snakeCase = snakeCase.replace(/-/g, "_");

  return snakeCase;
}
export * from "./server";
export * from "./redirectionUtils";
export * from "./dateUtils";
