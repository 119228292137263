import {
  CARD_TYPE,
  FAQ_CLICK_HERE_LINK_ID,
  PDT_EVENTS,
  VERSIONS,
} from "../../constants";
import { useDevice } from "../../hooks/useDevice";
import { useGlobalConfig } from "../../hooks/useGlobalConfig";
import { useGlobalSelector } from "../../hooks/useGlobalSelector";
import useListingPdtLoggerV2 from "../../hooks/useListingPdtLoggerV2";
import useOpenLoginForm from "../../hooks/useOpenLoginForm";
import { useListingPdtLogger } from "../../hooks/usePdtLogger";
import useRedirectionUrls from "../../hooks/useRedirectionUrls";
import { WhiteBackgroundStyled } from "../../static/CommonStyle/CommonStyles.style";
import LandingFaq from "../../static/components/LandingFaq/LandingFaq";
import LandingFaqV2 from "../../static/components/LandingFaq/LandingFaqV2";
import { landingFaqData, landingFaqDataV2 } from "../../static/mockData";
import { ListingReducerType } from "../../store/forexListingReducer";
import { ForexProducts, ProductData } from "../../types";
import { getEventDetails } from "../../utils";

const landingFaq = {
  data: landingFaqData,
  title: {
    desktop: "All Your Travel safety questions answered",
    mobile: "FAQs",
  },
};

const landingFaqV2 = {
  data: landingFaqDataV2,
  title: {
    desktop: "FAQs",
    mobile: "FAQs",
  },
};

interface IForexLandingFaqs {
  id?: string;
}
const ForexLandingFaqs = ({ id }: IForexLandingFaqs) => {
  const { isLoggedInUser } = useGlobalConfig();
  const device = useDevice();
  const {
    redirectKnowMoreCardLinks,
    completeOrderRedirections,
    exploreForexCurrencyRedirection,
  } = useRedirectionUrls();
  const {
    products,
    version,
    feature_flag = {},
  } = useGlobalSelector((state: ListingReducerType) => state) || {};
  const { sendPdtFaqTabClickEvent } = useListingPdtLogger();
  const { openLoginFormForNonLoggedInUser } = useOpenLoginForm();
  const { sendPdtDataV2 } = useListingPdtLoggerV2();

  const clickHereHandler = async (item: any) => {
    const itemObj = { ...item };
    itemObj.id = CARD_TYPE.MULTI_CURRENCY_CARD;
    if (item.id === FAQ_CLICK_HERE_LINK_ID.WHAT_IS_MX_CARD_CLICK_HERE) {
      redirectKnowMoreCardLinks(itemObj);
    } else if (item.id === FAQ_CLICK_HERE_LINK_ID.HOW_TO_GET_CARD_CLICK_HERE) {
      itemObj.cta = getMXCardCtaObj(products);
      itemObj.feature_flag = feature_flag;
      completeOrderRedirections(itemObj);
    } else if (item.id === FAQ_CLICK_HERE_LINK_ID.ORDER_CURRENCY_CLICK_HERE) {
      exploreForexCurrencyRedirection(item);
    }
  };

  function onTabClick(tabName: string) {
    sendPdtFaqTabClickEvent(tabName);
    sendPdtDataV2({
      event_detail: getEventDetails({
        event_name: PDT_EVENTS.FAQ_TAB_CTA,
        event_value: tabName,
      }),
    });
  }

  return (
    <WhiteBackgroundStyled>
      {version === VERSIONS.V3 ? (
        <LandingFaqV2
          id={id}
          faq={landingFaqV2.data}
          padding="80px 0px 50px 0px"
          customTitle={
            device === "lg"
              ? landingFaqV2.title.desktop
              : landingFaqV2.title.mobile
          }
          faqAnswerLinkTextOnClick={clickHereHandler}
          onTabClick={onTabClick}
        />
      ) : (
        <LandingFaq
          id={id}
          faq={landingFaqData}
          padding="80px 0px 50px 0px"
          customTitle={
            device === "lg" ? landingFaq.title.desktop : landingFaq.title.mobile
          }
          faqAnswerLinkTextOnClick={clickHereHandler}
        />
      )}
    </WhiteBackgroundStyled>
  );
};

export default ForexLandingFaqs;

function getMXCardCtaObj(products: ForexProducts | undefined) {
  if (!products) {
    return null;
  }
  for (let product of products) {
    for (let [card, cardValue] of Object.entries<ProductData>(product)) {
      if (card === CARD_TYPE.MULTI_CURRENCY_CARD) {
        return cardValue?.cta;
      }
    }
  }
  return null;
}
