import type { PageName, FunnelVars } from "./type";
export const USER_LOCATION_LOCAL_STORAGE_KEY = "user_location";

export const PAGE_NAMES: Record<PageName, PageName> = {
  LISTING: "LISTING",
  FOREX_DASHBOARD: "FOREX_DASHBOARD",
};

export const PAGE_NAME_MAP = {
  LISTING: "landing:tripmoney forex:landing",
  FOREX_DASHBOARD: "funnel:tripmoney forex:dashboard",
};

export const OMNI_EVENTS_MAP = {
  PAGE_NAME: "pageName",
  LOB: "lob",
  SITE_SECTION: "siteSection",
  BOTTOM_SHEET_TRIGGER: "bottomSheetTrigger",
  FUNNEL_TYPE: "funnelType",
  USER_LOC: "userLocation",
  CTA_INFO: "ctaInfo",
  INTERACTION_ID: "interactionID",
  LOGIN_STATUS: "loginStatus",
  BOOKING_STATUS: "bookingStatus",
  DASHBOARD_STATES: "dashboardStates",
  PLATFORM: "platform",
  AMOUNT: "amount",
  ERROR_CODE: "errorCode",
  CURRIENCIES_SELECTED: "currienciesSelected",
  CITY: "city",
  CURRENCY: "currency",
  DENOMINATION: "denomination",
  TM_USER_ID: "tm_user_id",
};

export const OMNITURE_MAP = {
  [OMNI_EVENTS_MAP.PAGE_NAME]: "eVar15",
  [OMNI_EVENTS_MAP.LOB]: "eVar24",
  [OMNI_EVENTS_MAP.SITE_SECTION]: "channel",
  [OMNI_EVENTS_MAP.BOTTOM_SHEET_TRIGGER]: "event327",
  [OMNI_EVENTS_MAP.FUNNEL_TYPE]: "eVar108",
  [OMNI_EVENTS_MAP.USER_LOC]: "eVar32",
  ApWindow: "eVar7",
  [OMNI_EVENTS_MAP.CTA_INFO]: "prop67",
  previousPageName: "prop23",
  [OMNI_EVENTS_MAP.INTERACTION_ID]: "eVar16",
  [OMNI_EVENTS_MAP.LOGIN_STATUS]: "eVar39",
  [OMNI_EVENTS_MAP.TM_USER_ID]: "eVar20",
  travelPurpose: "eVar9",
  freeCardOpted: "prop52",
  [OMNI_EVENTS_MAP.BOOKING_STATUS]: "eVar14",
  [OMNI_EVENTS_MAP.DASHBOARD_STATES]: "prop67",
  [OMNI_EVENTS_MAP.PLATFORM]: "eVar75",
  [OMNI_EVENTS_MAP.AMOUNT]: "event328",
  [OMNI_EVENTS_MAP.ERROR_CODE]: "eVar22",
  [OMNI_EVENTS_MAP.CURRIENCIES_SELECTED]: "eVar46",
  [OMNI_EVENTS_MAP.CITY]: "eVar31",
  [OMNI_EVENTS_MAP.CURRENCY]: "eVar123",
  [OMNI_EVENTS_MAP.DENOMINATION]: "prop46",
};

const LOB_NAME = {
  FOREX_CARDS: "tripmoney forex card",
  PS_FOREX_CARDS: "ps tripmoney forex card",
  FOREX_CURRENCY: "tripmoney forex currency",
};

const SITE_SECTION = {
  LANDING: "landing",
  FUNNEL: " tripmoney forex funnel card",
  NA: "",
};

export const PAGE_AND_CATEGORY_MAP: Record<PageName, FunnelVars> = {
  LISTING: {
    pageName: PAGE_NAME_MAP.LISTING,
    lob: LOB_NAME.FOREX_CARDS,
    siteSection: SITE_SECTION.LANDING,
  },
  FOREX_DASHBOARD: {
    pageName: PAGE_NAME_MAP.FOREX_DASHBOARD,
    lob: LOB_NAME.PS_FOREX_CARDS,
    siteSection: SITE_SECTION.NA,
  },
};

export const LOGGED_IN = "Loggedin";
export const NOT_LOGGED_IN = "Not Loggedin";
