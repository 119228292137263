export { default as ForexCardWidget } from "./lib/components/ForexCardsWidget";
export { default as ForexCurrencyCard } from "./lib/components/ForexCurrencyCard";
export { default as ForexLandingFaqs } from "./lib/components/ForexLandingFaqs";
export { default as ForexLandingOfferings } from "./lib/components/ForexLandingOfferings";
export { default as TrustUs } from "./lib/components/TrustUs";
export { default as ForexBanners } from "./lib/components/ForexBanners";
export { default as BMFLicenseFooter } from "./lib/components/BMFLicenceFooter";

export { default as RootConfigContainer } from "./lib/components/RootContainer";

export * from "./lib/constants/index";
export * from "./lib/utils/index";
export * from "./lib/utils/clientStorageUtils";
export * from "./lib/utils/cookieUtils";
export * from "./lib/api/fetchApi";
export * from "./lib/analytics";
export { DeviceContextV2 } from "./lib/Contexts/DeviceContext";
export { ExperimentTrackingString } from "./lib/Contexts/ExperimentTrackingStringContext";
export type {
  DeviceContextV2Type,
  DeviceComponentProps,
} from "./lib/Contexts/DeviceContext";
export { useBreakpointV2 } from "./lib/hooks/useDeviceContext";
export { withDeviceProvider } from "./lib/hoc/withDeviceProvider";

export * from "./lib/hooks";
export { default as useSetUTMParams } from "./lib/hooks/useSetUTMParams";
