import { PERSUASION_TYPE, SIGNAL_STATE } from "../constants";
import { FreeCardOfferProps } from "../static/components/LandingCardFeature/Persuasions/ProductOffers";
import { CrossSellDataType } from "../store/forexListingReducer";

export type PersonaType = {
  user_type?: string;
  user_name?: string;
  upcoming_trip?: {
    country: string;
    country_code?: string;
  };
  multi_cx_card_cust: boolean;
  currency_cust: boolean;
  currency_order_active: boolean;
  multi_cx_card_order_active: boolean;
  currency_active_orders: number;
};

export type Coupon = {
  booking_id?: string;
  coupon_expiry?: number;
  coupon_cashback?: number;
  coupon_currency?: string;
  max_cashback?: number;
  coupon_id?: string;
  title?: string;
  period?: string;
  message?: string;
};

export type OFFER_TYPE =
  | "offer"
  | "cross_sell_hotel"
  | "sale_coupons"
  | "cross_sell_flight";
export type OfferPersuationsType = {
  type?: OFFER_TYPE | undefined;
  value?: {
    text?: string | undefined;
    details?: Coupon[] | null | undefined;
    tnc?: string[] | [] | undefined | null;
    min_purchase?: string | undefined;
    slab?: { range: string; cashback: string }[];
  };
};

export interface PersuationProps {
  [PERSUASION_TYPE.DELIVERY_TRACKING]: DeliveryTrackingPersuasionProps | null;
  [PERSUASION_TYPE.BACKEND]: BackendPersuasionProps[]; // Replace 'any' with the appropriate type for the backend persuasions.
  [PERSUASION_TYPE.DROP_OFF]: DropOffPersuasionProps | null;
  [PERSUASION_TYPE.MINI_DASHBOARD]: MiniDashboardPersuasionProps | null;
  [PERSUASION_TYPE.UPLOAD_DOCUMENTS]: UploadDocsPersuasionProps | null;
  [PERSUASION_TYPE.VIEW_ORDERS]: ViewOrdersPersuasaionProps | null;
  [PERSUASION_TYPE.ACTIVATION_IN_PROGRESS]: ActivationInProgressPersuasionProps | null;
  [PERSUASION_TYPE.START_ORDER]: StartOrderPersuasionProps | null;
  [PERSUASION_TYPE.UNDELIVERABLE]: UndeliverablePersuasionProps | null;
  [PERSUASION_TYPE.UPCOMING_TRIPS]: UpcomingTripsPersuasionProps | null;
  [PERSUASION_TYPE.CROSS_SELL]: CrossSellDataType | null;
  [PERSUASION_TYPE.FREE_CARD]: FreeCardOfferProps | null;
}

export interface ActivationInProgressPersuasionProps {
  label: string;
  type: PERSUASION_TYPE.ACTIVATION_IN_PROGRESS;
}

export interface ActivationInProgressPersuasionData {}

export interface UndeliverablePersuasionProps {
  linkText: {
    text: string;
  };
  normalText: string;
}

export interface UndeliverablePersuasionData {}

export interface UpcomingTripsPersuasionProps {
  label: string;
  type: PERSUASION_TYPE;
}

export interface UpcomingTripsPersuasionData {
  destination: string;
}

export interface ReloadTripPerusasionData {
  reload_discovery_order_details: {
    countries: string[];
  };
}

export interface StartOrderPersuasionProps {
  isPrimaryButton: boolean;
  linkBtnInMobile: { label: string };
  cta?: PersuasionCTA;
}

export interface StartOrderPersuasionData {
  is_primary_button: boolean;
  label: string;
}

export interface BackendPersuasionProps {
  priority?: number | undefined;
  theme: string | undefined;
  value: string | undefined;
}

export type DropOffPersuasionProps = {
  text: {
    blackBoldText: string;
  };
  linkBtn: {
    label: string;
    // onClick?: () => void;
  };
} & DropOffPersuasionData;

export type MiniDashboardPersuasionProps = {
  label: string | null | undefined;
  subLabel: string | null | undefined;
  linkBtnInMobile: {
    label: string | null | undefined;
  };
  isLoaded?: boolean;
  isLoadedIcon?: string;
  toAdd?: boolean;
  addText?: string;
  cta?: PersuasionCTA;
  isPrimaryButton?: boolean;
} & MiniDashboardPersuasionData;

export type UploadDocsPersuasionProps = {
  label: string;
  iconSrc: undefined;
  btnProps: {
    customTitle: string;
  };
} & UploadDocsPersuasaionData;

export type ReloadTripPersuasionProps = {
  label: string;
  btnProps: {
    customTitle: string;
  };
};

export type ViewOrdersPersuasaionProps = {
  label: string;
  iconSrc: undefined;
  btnProps?: {
    customTitle?: string;
  };
  linkBtnInMobile?: {
    label?: string | null | undefined;
  };
} & ViewOrdersPersuasaionData;
export interface IBackendPersuasion {
  persuasion_type:
    | PERSUASION_TYPE.DELIVERY_TRACKING
    | PERSUASION_TYPE.BACKEND
    | PERSUASION_TYPE.DROP_OFF
    | PERSUASION_TYPE.MINI_DASHBOARD
    | PERSUASION_TYPE.UPLOAD_DOCUMENTS;
  data: {
    theme: string;
    priority?: number;
    value: string;
  };
}

export type ForexRates = {
  [currency: string]: number;
};

export type DeliveryDetails = {
  deliverable?: boolean;
  eta_in_days?: number;
  city?: string;
  pincode?: number;
  latitude?: number;
  longitude?: number;
};

export type LocationFetchingProps = "FETCHING" | "SUCCESS" | "ERROR";
export type ProductType = "inr_card" | "multi_cx_card" | "currency";

export type DeliveryDetailsContent = {
  ctaText?: string;
  text?: string;
};

export type PersuasionType =
  | "backend"
  | "dropoff"
  | "mini_dashboard"
  | "delivery_tracking"
  | "upload_docs"
  | "view_orders"
  | "start_order"
  | "upcoming_trips"
  | "undeliverable"
  | "activation_in_progress"
  | "cross_sell"
  | "free_card"
  | "reload_discovery";

export type PersuasionData =
  | BackendPersuasionData
  | DropOffPersuasionData
  | MiniDashboardPersuasionData
  | DeliveryDetailsPersuasionData
  | UploadDocsPersuasaionData
  | ViewOrdersPersuasaionData
  | FreeCardPersuasionData;

export interface BackendPersuasionData {
  theme?: string;
  priority?: number;
  value?: string;
}

export interface DropOffPersuasionData {
  theme?: string;
  progress?: number;
  next_signal_state?: string;
  current_signal_state?: string;
  previous_signal_state?: string;
  priority?: number;
  iconSrc?: string;
}

export type MiniDashboardPersuasionData = {
  sub_label: string | null | undefined;
  action: string | null | undefined;
  title: string | null | undefined;
  show_add_btn: boolean;
  is_primary_button: boolean;
};

export interface DeliveryDetailsPersuasionData {
  eta_in_days?: number;
}

export interface UploadDocsPersuasaionData {
  next_signal_state?: string;
  current_signal_state?: string;
  previous_signal_state?: string;
  value?: string;
  order_id?: string;
  redirect_to?: string;
}

export interface ViewOrdersPersuasaionData {
  next_signal_state?: string;
  current_signal_state?: string;
  previous_signal_state?: string;
  value?: string;
}

export interface FreeCardPersuasionData {
  cashback?: string | number;
  is_primary_button?: boolean;
  offer_text?: string;
  show_add_btn?: boolean;
}

type SignalStateInterface = keyof typeof SIGNAL_STATE;

export interface PersuasionCTA {
  user_state: string;
  user_state_data: {
    current_signal_state: SignalStateInterface;
    next_signal_state: SignalStateInterface;
    previous_signal_state: SignalStateInterface;
    order_id: string;
  };
}

export interface PersuasionObject {
  type?: PersuasionType;
  data?: PersuasionData;
  cta?: PersuasionCTA;
}

type UserStateType = "ORDER_IN_PROGRESS" | "DASHBOARD";
interface CardCTA {
  user_state?: string;
  user_state_data?: {
    order_id?: string;
    next_signal_state: string;
    current_signal_state: string;
    previous_signal_state: string;
    signal_data?: {
      checkout_url: string;
    };
  };
}

interface ProductDeliveryDetails {
  deliverable?: boolean;
  eta_in_days?: number;
}

export interface InrCard {
  render_separately?: boolean;
  recommended?: boolean;
  cta?: CardCTA;
  delivery_details?: ProductDeliveryDetails;
  persuasions?: PersuasionObject[];
  cta_redirect?: number;
  version?: number;
  deep_login_allowed?: boolean;
  show_best_rate?: boolean;
}

export interface MultiCxCard {
  render_separately?: boolean;
  recommended?: boolean;
  cta?: CardCTA;
  delivery_details?: ProductDeliveryDetails;
  persuasions?: PersuasionObject[];
  cta_redirect?: number;
  version?: number;
  deep_login_allowed?: boolean;
  show_best_rate?: boolean;
}

export interface Currency {
  render_separately?: boolean;
  recommended?: boolean;
  cta?: CardCTA;
  delivery_details?: ProductDeliveryDetails;
  persuasions?: PersuasionObject[];
  cta_redirect?: number;
  version?: number;
  deep_login_allowed?: boolean;
  show_best_rate?: boolean;
}

export type ProductData = InrCard | MultiCxCard | Currency;

// export interface ForexProduct {
//     inr_card: InrCard;
//     multi_cx_card: MultiCxCard;
//     currency: Currency;
// }

export type ForexProduct =
  | { inr_card: InrCard }
  | { multi_cx_card: MultiCxCard }
  | { currency: Currency };

export type ForexProducts = Array<ForexProduct>;

export interface LocationUnavailable {
  normalText: string;
  linkText: {
    text: string;
    hrefLink: string;
  };
}
export interface CardType {
  version?: number;
  id: ProductType;
  logoSrc: string;
  logoDesc?: {
    boldText: string;
    normalText: string;
  };
  label: string;
  subLabel?: string;
  featuresArr: {
    id: string;
    label: string;
  }[];
  deliveryLabel: string | null;
  isleftOffBefore: DropOffPersuasionProps | null;
  isRecommended: DeliveryTrackingPersuasionProps | null;
  isUnavailableAtLoc: LocationUnavailable | null;
  loadCurrency: MiniDashboardPersuasionProps | null;
  docsPending: UploadDocsPersuasionProps | null;
  isRecommendNotification: boolean | null;
  backendPersuasion: BackendPersuasionProps[];
  viewOrdersPersuasion: ViewOrdersPersuasaionProps | null;
  startOrder: StartOrderPersuasionProps | null;

  next_signal_state?: string | undefined | null;
  current_signal_state?: string | undefined | null;
  previous_signal_state?: string | undefined | null;
  feature_flag?: { solve_reload?: boolean };
  showBestRate?: boolean;
}

export interface DeliveryTrackingPersuasionProps {
  text: {
    blackBoldText: string;
    greenBoldText: string;
    normalText: string;
  };
  linkBtn: {
    label: string;
  };
  type: PERSUASION_TYPE;
  label: string;
}
export type ERROR_CODE = "LOGIN_REQUIRED" | "DISPLAY_ERROR";
export type ERROR_TYPE = "INLINE";

export type ErrorType = {
  code?: ERROR_CODE;
  description?: string;
  type?: ERROR_TYPE;
  sub_field?: string;
};

export type ActionTypeOptions = {
  card_type: ProductType;
};

export interface PdtPageViewEventObject {
  persona: PersonaType | null | undefined;
  forex_rates: ForexRates | null | undefined;
  tm_user_id: number | null | undefined;
  products: ForexProducts | undefined;
  puid: number | null | undefined;
}

export interface PdtDataType {
  forex_context_item?: {
    context_key?: string | null;
    context_value?: string | null;
  };
  event_details?: {
    bottom_sheet_shown: {
      bottom_sheet_name: string | null;
    };
  };
  user_details?: {
    is_loggedin: string | null;
  };
  funnel_details: {
    delivery_details: {
      eta?: string | null;
      latitude?: string | null;
      longitude?: string | null;
      location_source?: string | null;
      pincode?: string | null;
      city?: string | null;
      state?: string | null;
      delivery_eligiblity?: string | null;
      other?: string | null;
      location_permission?: string | null;
    };
    offer_details?: {
      offer_type?: string;
      offer_amount?: string;
      offer_applicable?: string;
      offer_code?: string;
      other?: string;
    };
    pan_details?: {
      is_pan_verified?: string;
      pan_name?: string;
      other?: string;
    };
    forex_listing_details: {
      persuasions: {
        delivery_tracking: {
          is_visible?: string | null;
        };
        backend: {
          is_visible?: string | null;
        };
        drop_off: {
          is_visible?: string | null;
        };
        mini_dashboard: {
          is_visible?: string | null;
        };
        upload_docs: {
          is_visible?: string | null;
        };
      };
      unavailable_Location: {
        is_visible?: string | null;
      };
      default: {
        is_visible?: string | null;
      };
    };
    rate_ticker: {
      currencies: Array<string> | null;
      unavailable_currencies?: Array<string>;
      upcoming_currencies?: Array<string>;
    };
    upcoming_trip?: {
      intl_travel?: string;
      country?: Array<string>;
      start_date?: string;
      end_date?: string;
      no_of_travellers?: string;
    };
    currencies?: {
      code?: string;
      rate?: string;
    };
  };
}

export interface TmAuthResultObj {
  showEmailPopup: boolean;
  loginToken: {
    success: boolean;
    error: {
      code?: number | null;
      description?: string;
    };
  };
  appSocialToken: {
    success: boolean;
    error: {
      code?: number | null;
      description?: string;
    };
  };
}

export interface BannersData {
  orders?: string[];
  campaign?: {
    mobile?: {
      link?: string;
      cta?: string;
      partner_to_link?: {
        mmt?: string;
        gi?: string;
      };
      partner_to_cta?: {
        mmt?: string;
        gi?: string;
      };
    }[];
    desktop?: {
      link?: string;
      cta?: string;
      partner_to_link?: {
        mmt?: string;
        gi?: string;
      };
      partner_to_cta?: {
        mmt?: string;
        gi?: string;
      };
    }[];
  };
  marketing?: {
    mobile?: {
      link?: string;
      cta?: string;
      partner_to_link?: {
        mmt?: string;
        gi?: string;
      };
      partner_to_cta?: {
        mmt?: string;
        gi?: string;
      };
    }[];
    desktop?: {
      link?: string;
      cta?: string;
      partner_to_link?: {
        mmt?: string;
        gi?: string;
      };
      partner_to_cta?: {
        mmt?: string;
        gi?: string;
      };
    }[];
  };
}

export enum RateTickerType {
  UPCOMING_TRIP = "upcoming_trip",
  TOP_CURRENCY = "top_currency",
}
