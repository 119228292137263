import React from "react";

import { AnchorTagStyle } from "./TextStyles.style";

interface AnchorTagProps {
  // fontSize?: keyof typeof fontSize;
  // fontWeight?: keyof typeof fontWeight;
  fontSize?: string;
  fontWeight?: string;
  color?: string;
  children?: any;
  handleClick?: any;
  hrefProp?: any;
  textAlign?: any;
  margin?: string;
  onClick?: any;
  width?: string;
  id?: string;
  display?: string;
  textTransform?: "upperCase" | "lowerCase";
}

const AnchorTag: React.FC<AnchorTagProps> = ({
  children,
  fontSize = "sm",
  fontWeight = "regular",
  color,
  handleClick,
  hrefProp,
  textAlign,
  margin,
  id,
  width,
  display = "inline-block",
  textTransform,
  ...rest
}) => {
  return (
    <AnchorTagStyle
      id={id}
      textAlign={textAlign}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      onClick={handleClick}
      href={hrefProp}
      margin={margin}
      width={width}
      display={display}
      textTransform={textTransform}
      {...rest}
    >
      {children}
    </AnchorTagStyle>
  );
};

// export const AnchorTag = styled.a<AnchorTagProps>`
//   font-family: 'Open Sans';
//   font-size:${props=>fontSize[props.fontSize]}
//   font-weight:${props=>fontWeight[props.fontWeight]}
//   margin: ${p => p.margin && p.margin};
//   display: inline-block;
//   color: ${p => p.color && p.color};
//   text-align: ${p => p.textAlign && p.textAlign};
// `;

export default AnchorTag;
