import {
  setPdtCtaAndCommonPdtData,
  setPdtEventData,
  getPdtErrorData,
  getPdtOtherDataSchema,
  setPdtLocationDetectedSchema,
} from "../static/utils/commonPdtUtils";
import { sendPdtDataApi } from "../api/fetchApi";
import { useGetCommonPdtData } from "./useGetCommonPdtData";
import {
  CARD_TYPE,
  PDT_EVENTS,
  PERSUASION_TYPE,
  SESSION_STORAGE_KEYS,
  getSessionPiiDataOpts,
} from "../constants";
import {
  PdtDataType,
  PdtPageViewEventObject,
  ProductData,
  ProductType,
} from "../types";
import { getCardValue, getPokusExpVersion } from "../utils";
import { getSessionStorageItem } from "../utils/clientStorageUtils";
import { useGlobalConfig } from "./useGlobalConfig";
import { useGlobalSelector } from "./useGlobalSelector";

export const useListingPdtLogger = () => {
  const { user_details, device, context } = useGetCommonPdtData();
  const { version } = useGlobalSelector((state: any) => state);

  const otherData: PdtDataType = getPdtOtherDataSchema();
  const { partner } = useGlobalConfig();

  const getExperimentDetails = (override?: {
    partner?: string;
    version?: number;
  }) => {
    const exp_experiment_details_list = [
      {
        exp_experiment_variant_id: getPokusExpVersion({
          partner: override?.partner || partner,
        }),
        exp_experiment_version: override?.version || version,
      },
    ];
    return exp_experiment_details_list;
  };

  const getPdtCommonCtaData = (ctaData: any) => {
    const pdtData: any = setPdtCtaAndCommonPdtData(
      user_details,
      device,
      ctaData,
      context,
      version,
      partner
    );
    return pdtData;
  };

  const sendListingPdtLocationDetectedViewEvent = (
    locationSource: any,
    permissionDetails: any,
    version: number,
    tm_user_id?: any,
    puid?: number | null | undefined
  ) => {
    const exp_experiment_details_list = getExperimentDetails({ version });
    const userLocation = getSessionStorageItem(
      SESSION_STORAGE_KEYS.USER_LOCATION_SESSION_KEY,
      true,
      getSessionPiiDataOpts
    );
    otherData.funnel_details.delivery_details = setPdtLocationDetectedSchema(
      locationSource,
      permissionDetails,
      userLocation
    );
    user_details.zuid = tm_user_id ? `${tm_user_id}` : user_details.zuid;
    user_details.puid = puid ? `${puid}` : user_details.puid;
    user_details.is_loggedin =
      user_details?.zuid || user_details?.puid ? "true" : null;

    const listingLocationPdtData = setPdtEventData(
      user_details,
      device,
      otherData,
      context,
      PDT_EVENTS.LOC_DETECT_EVENT,
      exp_experiment_details_list
    );
    sendPdtDataApi(listingLocationPdtData);
  };

  const sendPdtListingPageViewEvent = (
    listingData?: PdtPageViewEventObject
  ) => {
    const { persona, forex_rates, tm_user_id, products, puid } =
      listingData || {};
    const { upcoming_trip } = persona || {};
    const loadedPageVersion = new Set();
    const currencyCardValue = getCardValue(
      products,
      CARD_TYPE.CURRENCY as ProductType
    );
    const currencyCtaRedirect = currencyCardValue?.cta_redirect;
    for (const product of products || []) {
      for (const [card, cardValue] of Object.entries<ProductData>(product)) {
        const { deliverable } = cardValue?.delivery_details || {};
        if (deliverable === false) {
          loadedPageVersion.add(PDT_EVENTS.ANOTHER_LOC);
        }
        const { persuasions = [] } = cardValue;
        for (const persuasionObj of persuasions) {
          const { type } = persuasionObj || {};
          loadedPageVersion.add(type);
        }
      }
    }

    const userLocation = getSessionStorageItem(
      SESSION_STORAGE_KEYS.USER_LOCATION_SESSION_KEY,
      true,
      getSessionPiiDataOpts
    );
    if (loadedPageVersion === null || loadedPageVersion.size == 0) {
      otherData.funnel_details.forex_listing_details.default.is_visible =
        "true";
    } else {
      const persuasionsData =
        otherData.funnel_details.forex_listing_details.persuasions;
      if (loadedPageVersion.has(PERSUASION_TYPE.DELIVERY_TRACKING)) {
        persuasionsData.delivery_tracking.is_visible = "true";
      }
      if (loadedPageVersion.has(PERSUASION_TYPE.BACKEND)) {
        persuasionsData.backend.is_visible = "true";
      }
      if (loadedPageVersion.has(PERSUASION_TYPE.DROP_OFF)) {
        persuasionsData.drop_off.is_visible = "true";
      }
      if (loadedPageVersion.has(PERSUASION_TYPE.MINI_DASHBOARD)) {
        persuasionsData.mini_dashboard.is_visible = "true";
      }
      if (loadedPageVersion.has(PERSUASION_TYPE.UPLOAD_DOCUMENTS)) {
        persuasionsData.upload_docs.is_visible = "true";
      }
    }
    if (loadedPageVersion && loadedPageVersion.has(PDT_EVENTS.ANOTHER_LOC)) {
      otherData.funnel_details.forex_listing_details.unavailable_Location.is_visible =
        "true";
    }
    if (upcoming_trip) {
      const destination: string = upcoming_trip?.country || "";
      otherData.forex_context_item = {
        context_key: PDT_EVENTS.UPCOMING_TRIP,
        context_value: destination,
      };
    }
    if (forex_rates) {
      otherData.funnel_details.rate_ticker.currencies = forex_rates
        ? Object.keys(forex_rates)
        : null;
    }
    if (userLocation) {
      otherData.funnel_details.delivery_details = setPdtLocationDetectedSchema(
        null,
        null,
        userLocation
      );
    }
    user_details.zuid = tm_user_id ? `${tm_user_id}` : user_details.zuid;
    user_details.puid = puid ? `${puid}` : user_details.puid;
    user_details.is_loggedin =
      user_details?.zuid || user_details?.puid ? "true" : null;

    const exp_experiment_details_list = [
      {
        exp_experiment_variant_id: getPokusExpVersion({
          partner,
          currencyCtaRedirect,
        }),
        exp_experiment_version: version,
      },
    ];
    const pdtEventData = setPdtEventData(
      user_details,
      device,
      otherData,
      context,
      PDT_EVENTS.PAGE_LOAD_EVENT,
      exp_experiment_details_list
    );
    sendPdtDataApi(pdtEventData);
  };

  const sendListingPdtEventOnLocationBarClick = () => {
    const ctaData = {
      label: PDT_EVENTS.LOCATION_BAR_CTA,
      primary_value: "",
      secondary_value: "",
      others: "",
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    sendPdtDataApi(pdtCtaCommonData);
  };

  const sendPdtLocationBottomSheetViewEvent = () => {
    const exp_experiment_details_list = getExperimentDetails();
    otherData.event_details = {
      bottom_sheet_shown: {
        bottom_sheet_name: PDT_EVENTS.LOC_BOTTOM_SHEET_VIEWED,
      },
    };
    const pdtEventData = setPdtEventData(
      user_details,
      device,
      otherData,
      context,
      PDT_EVENTS.LOC_BOTTOM_SHEET_VIEWED,
      exp_experiment_details_list
    );
    sendPdtDataApi(pdtEventData);
  };

  const sendPdtLocationDetectedOnBottomSheetClickEvent = () => {
    const ctaData = {
      label: PDT_EVENTS.DETECT_LOC_CTA,
      primary_value: "",
      secondary_value: "",
      others: "",
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    sendPdtDataApi(pdtCtaCommonData);
  };

  const sendPdtSubmitPincodeBottomSheetClickEvent = (
    isFallbackCity: boolean,
    city: string = "",
    pincode: string | number = ""
  ) => {
    const ctaData = {
      label: isFallbackCity
        ? PDT_EVENTS.FALLBACK_CITY_SUBMITTED
        : PDT_EVENTS.SUBMIT_PINCODE_CTA,
      primary_value: "",
      secondary_value: "",
      others: `${pincode}-${city?.toLocaleLowerCase()}`,
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    sendPdtDataApi(pdtCtaCommonData);
  };

  const sendPdtLoginClickEventListingPage = () => {
    const ctaData = {
      label: PDT_EVENTS.LISTING_PAGE_LOGIN_CLICK,
      primary_value: "",
      secondary_value: "",
      others: "",
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    sendPdtDataApi(pdtCtaCommonData);
  };

  const sendPdtLoginPopOpen = () => {
    const ctaData = {
      label: PDT_EVENTS.LOGIN_POP_UP_OPEN,
      primary_value: "",
      secondary_value: "",
      others: "",
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    sendPdtDataApi(pdtCtaCommonData);
  };

  const sendPdtOpenEmailPopUp = () => {
    const ctaData = {
      label: PDT_EVENTS.EMAIL_LOGIN_POP_UP_OPEN,
      primary_value: "",
      secondary_value: "",
      others: "",
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    sendPdtDataApi(pdtCtaCommonData);
  };

  const sendPdtEmailPopUpOkClick = () => {
    const ctaData = {
      label: PDT_EVENTS.EMAIL_LOGIN_POP_UP_OK_CLICK,
      primary_value: "",
      secondary_value: "",
      others: "",
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    sendPdtDataApi(pdtCtaCommonData);
  };

  const sendPdtOpenAppUpdatePopUp = () => {
    const ctaData = {
      label: PDT_EVENTS.UPDATE_APP_POP_UP_OPEN,
      primary_value: "",
      secondary_value: "",
      others: "",
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    sendPdtDataApi(pdtCtaCommonData);
  };

  const sendPdtOpenUpdateAppPopUpOkClick = () => {
    const ctaData = {
      label: PDT_EVENTS.UPDATE_APP_POP_UP_OK_CLICK,
      primary_value: "",
      secondary_value: "",
      others: "",
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    sendPdtDataApi(pdtCtaCommonData);
  };

  const sendPdtLoginErrorPopUpOpen = () => {
    const ctaData = {
      label: PDT_EVENTS.LOGIN_ERROR_POP_UP_OPEN,
      primary_value: "",
      secondary_value: "",
      others: "",
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    sendPdtDataApi(pdtCtaCommonData);
  };

  const sendPdtLoginErrorPopUpOkClick = () => {
    const ctaData = {
      label: PDT_EVENTS.LOGIN_ERROR_POP_UP_OK_CLICK,
      primary_value: "",
      secondary_value: "",
      others: "",
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    sendPdtDataApi(pdtCtaCommonData);
  };

  const sendPdtRateTickerViewEvent = (
    forex_rates: any,
    tm_user_id?: any,
    puid?: number | null | undefined
  ) => {
    const exp_experiment_details_list = getExperimentDetails();
    otherData.funnel_details.rate_ticker.currencies = forex_rates
      ? Object.keys(forex_rates)
      : null;
    user_details.zuid = tm_user_id ? `${tm_user_id}` : user_details.zuid;
    user_details.puid = puid ? `${puid}` : user_details.puid;
    user_details.is_loggedin =
      user_details?.zuid || user_details?.puid ? "true" : null;

    const pdtRateTickerData = setPdtEventData(
      user_details,
      device,
      otherData,
      context,
      PDT_EVENTS.RATE_TICKER_LOAD,
      exp_experiment_details_list
    );
    sendPdtDataApi(pdtRateTickerData);
  };

  const sendPdtProductTilesCtaClickEvent = async (productClicked: string) => {
    const ctaData = {
      label: PDT_EVENTS.PRODUCT_TILE_CTA,
      primary_value: "",
      secondary_value: "",
      others: productClicked,
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    sendPdtDataApi(pdtCtaCommonData);
  };

  const sendPdtKnowMoreCardCtaClickEvent = async (productClicked: string) => {
    const ctaData = {
      label: PDT_EVENTS.KNOW_MORE_CTA,
      primary_value: "",
      secondary_value: "",
      others: productClicked,
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    sendPdtDataApi(pdtCtaCommonData);
  };

  const sendPdtFaqClickEvent = (faqClicked: string) => {
    const ctaData = {
      label: PDT_EVENTS.FAQ_CTA,
      primary_value: "",
      secondary_value: "",
      others: faqClicked,
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    sendPdtDataApi(pdtCtaCommonData);
  };

  const sendPdtFaqTabClickEvent = (tabName: string) => {
    const ctaData = {
      label: PDT_EVENTS.FAQ_TAB_CTA,
      currency: "",
      others: tabName,
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    sendPdtDataApi(pdtCtaCommonData);
  };

  const sendPdtPersuasionLinkCtaClickEvent = async (
    ctaClicked: string,
    productClicked: string
  ) => {
    const ctaData = {
      label: ctaClicked,
      primary_value: "",
      secondary_value: "",
      others: productClicked,
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    sendPdtDataApi(pdtCtaCommonData);
  };

  const sendPdtFDOCtaClickEvent = (productClicked: string) => {
    const ctaData = {
      label: PDT_EVENTS.COMPLETE_ORDER_CTA,
      primary_value: "",
      secondary_value: "",
      others: productClicked,
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    sendPdtDataApi(pdtCtaCommonData);
  };

  const sendPdtUnavailableLocationCtaClickEvent = (productClicked: string) => {
    const ctaData = {
      label: PDT_EVENTS.UNAVAILABLE_LOC,
      primary_value: "",
      secondary_value: "",
      others: productClicked,
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    sendPdtDataApi(pdtCtaCommonData);
  };

  const sendPdtViewDashboardCtaClickEvent = async (productClicked: string) => {
    const ctaData = {
      label: PDT_EVENTS.VIEW_DASHBOARD,
      primary_value: "",
      secondary_value: "",
      others: productClicked,
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    await sendPdtDataApi(pdtCtaCommonData);
  };

  const sendPdtViewOrdersCtaClickEvent = async (productClicked: string) => {
    const ctaData = {
      label: PDT_EVENTS.VIEW_ORDERS,
      others: productClicked,
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    await sendPdtDataApi(pdtCtaCommonData);
  };

  const sendPdtMarketingBannerCtaClickEvent = async () => {
    const ctaData = {
      label: PDT_EVENTS.MARKETING_BANNER_CTA,
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    await sendPdtDataApi(pdtCtaCommonData);
  };

  const sendPdtMarketingBannerLoadEvent = async () => {
    const ctaData = {
      label: PDT_EVENTS.MARKETING_BANNER_LOAD,
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    await sendPdtDataApi(pdtCtaCommonData);
  };

  const sendPdtListingError = (error: any, description: string = "") => {
    const exp_experiment_details_list = getExperimentDetails();
    const pdtErrorData = getPdtErrorData(
      error,
      description,
      user_details,
      device,
      context,
      exp_experiment_details_list
    );
    sendPdtDataApi(pdtErrorData);
  };

  const sendPdtUserServiceApiCallEvent = (eventCta: string) => {
    const exp_experiment_details_list = getExperimentDetails();
    const pdtEventData = setPdtEventData(
      null,
      device,
      null,
      context,
      eventCta,
      exp_experiment_details_list
    );
    sendPdtDataApi(pdtEventData);
  };

  const sendPdtLoginTokenError = (error: any, description: string = "") => {
    const exp_experiment_details_list = getExperimentDetails();
    const pdtErrorData = getPdtErrorData(
      error,
      description,
      user_details,
      device,
      context,
      exp_experiment_details_list
    );
    sendPdtDataApi(pdtErrorData);
  };

  const sendPdtAppSocialError = (error: any, description: string = "") => {
    const exp_experiment_details_list = getExperimentDetails();
    const pdtErrorData = getPdtErrorData(
      error,
      description,
      user_details,
      device,
      context,
      exp_experiment_details_list
    );
    sendPdtDataApi(pdtErrorData);
  };

  const sendPdtFallbackCity = (eventName?: string, pincode: string = "") => {
    const ctaData = {
      label: eventName,
      currency: "",
      others: pincode,
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    sendPdtDataApi(pdtCtaCommonData);
  };

  const sendPdtCouponShown = async (
    eventName: string,
    couponShown: string,
    totalCoupons: number
  ) => {
    const ctaData = {
      label: eventName,
      primary_value: "",
      secondary_value: "",
      others: `${couponShown}--Total Coupons-${totalCoupons}`,
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    await sendPdtDataApi(pdtCtaCommonData);
  };

  const sendPdtViewCoupons = async (eventName: string) => {
    const ctaData = {
      label: eventName,
      primary_value: "",
      secondary_value: "",
      others: ``,
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    await sendPdtDataApi(pdtCtaCommonData);
  };

  const sendPdtCoachmarkView = () => {
    const ctaData = {
      label: PDT_EVENTS.COACHMARK_VIEWED,
      currency: "",
      others: "",
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    sendPdtDataApi(pdtCtaCommonData);
  };

  const sendPdtCoachmarkButtonClick = () => {
    const ctaData = {
      label: PDT_EVENTS.COACKMARK_BUTTON_CLICK,
      currency: "",
      others: "",
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    sendPdtDataApi(pdtCtaCommonData);
  };

  const sendCtaEvent = ({
    eventName,
    primaryValue,
    secondaryValue,
    others,
  }: {
    eventName: string;
    primaryValue?: string;
    secondaryValue?: string;
    others?: string;
  }) => {
    const ctaData = {
      label: eventName,
      primary_value: primaryValue,
      secondary_value: secondaryValue,
      others: others,
    };
    const pdtCtaCommonData = getPdtCommonCtaData(ctaData);
    sendPdtDataApi(pdtCtaCommonData);
  };

  return {
    sendListingPdtLocationDetectedViewEvent,
    sendPdtListingPageViewEvent,
    sendListingPdtEventOnLocationBarClick,
    sendPdtLocationBottomSheetViewEvent,
    sendPdtLocationDetectedOnBottomSheetClickEvent,
    sendPdtSubmitPincodeBottomSheetClickEvent,
    sendPdtRateTickerViewEvent,
    sendPdtProductTilesCtaClickEvent,
    sendPdtKnowMoreCardCtaClickEvent,
    sendPdtFaqClickEvent,
    sendPdtFaqTabClickEvent,
    sendPdtPersuasionLinkCtaClickEvent,
    sendPdtFDOCtaClickEvent,
    sendPdtViewDashboardCtaClickEvent,
    sendPdtViewOrdersCtaClickEvent,
    sendPdtMarketingBannerCtaClickEvent,
    sendPdtUnavailableLocationCtaClickEvent,
    sendPdtListingError,
    sendPdtUserServiceApiCallEvent,
    sendPdtMarketingBannerLoadEvent,

    sendPdtLoginClickEventListingPage,
    sendPdtLoginPopOpen,
    sendPdtOpenEmailPopUp,
    sendPdtEmailPopUpOkClick,
    sendPdtOpenAppUpdatePopUp,
    sendPdtOpenUpdateAppPopUpOkClick,
    sendPdtLoginErrorPopUpOpen,
    sendPdtLoginErrorPopUpOkClick,
    sendPdtAppSocialError,
    sendPdtLoginTokenError,
    sendPdtFallbackCity,
    sendPdtCouponShown,
    sendPdtViewCoupons,
    sendPdtCoachmarkView,
    sendPdtCoachmarkButtonClick,
    sendCtaEvent,
  };
};
