import Head from "next/head";
import { AppProps } from "next/app";

import { GlobalStyles } from "@tm/ui/ui-container";
import { PublicEnvProvider, envContext } from "@tm/ui/contexts";

interface CustomAppProps extends AppProps {
  publicEnv: envContext;
}

CustomApp.getInitialProps = (context) => {
  return {
    publicEnv: {
      proxyCmsPrefix: process.env.PUBLIC_CMS_CONTEXT_PATH,
      exchangeRateApi: process.env.PUBLIC_EXCHANGE_RATE_API,
    },
  };
};

function CustomApp({ Component, pageProps, publicEnv }: CustomAppProps) {
  return (
    <>
      <GlobalStyles />
      <Head>
        {pageProps?.isTablet ? (
          <meta name="viewport" content="width=1440" />
        ) : (
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        )}
      </Head>
      <main>
        <PublicEnvProvider value={publicEnv}>
          <Component {...pageProps} />
        </PublicEnvProvider>
      </main>
    </>
  );
}

export default CustomApp;
